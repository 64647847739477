import {
	type RolesResponse,
	type ResponseRole,
	Role,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import type { StoredObjectType } from '../types/object-type';
import type { StoredSchema } from '../types/schema';

export const isManager = (currentUserRole: Role): boolean => currentUserRole === Role.MANAGER;
export const isDeveloperOrGreater = (currentUserRole: Role): boolean =>
	currentUserRole === Role.DEVELOPER || isManager(currentUserRole);
export const isUserOrGreater = (currentUserRole: Role): boolean =>
	currentUserRole === Role.USER || isDeveloperOrGreater(currentUserRole);

export const storedObjectTypeRoleToResponseRole = (role: Role): ResponseRole => {
	if (role === Role.MANAGER) {
		return 'OBJECT_TYPE_MANAGER';
	}
	if (role === Role.DEVELOPER) {
		return 'OBJECT_TYPE_DEVELOPER';
	}
	return 'OBJECT_TYPE_USER';
};

export const storedSchemaRoleToResponseRole = (role: Role): ResponseRole => {
	if (role === Role.MANAGER) {
		return 'SCHEMA_MANAGER';
	}
	if (role === Role.DEVELOPER) {
		return 'SCHEMA_DEVELOPER';
	}
	return 'SCHEMA_USER';
};

export const objectTypesAndSchemaToRolesResponse = (
	objectTypes: StoredObjectType[],
	schemas: StoredSchema[],
): RolesResponse => ({
	objectSchemaRoles: schemas.map((schema) => ({
		resourceId: schema.id,
		role: storedSchemaRoleToResponseRole(schema.currentUserRole),
	})),
	objectTypeRoles: objectTypes.map((objectType) => ({
		resourceId: objectType.id,
		role: storedObjectTypeRoleToResponseRole(objectType.currentUserRole),
	})),
});
