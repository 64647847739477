import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	paginationErrorTitle: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-list.pagination-footer.pagination-error-title',
		defaultMessage: 'We were unable to load your objects',
		description: 'Error title when failing to load page of objects',
	},
	paginationErrorDescription: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-list.pagination-footer.pagination-error-description',
		defaultMessage: 'Please check your network and try again.',
		description: 'Error message when failing to load page of objects',
	},
});
