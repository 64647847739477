import React from 'react';
import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import { FormattedNumber, useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';

export type UncappedCountButtonProps = {
	cappedTotal: number;
	onClick: () => Promise<undefined>;
};

export const UncappedCountButton = ({ cappedTotal, onClick }: UncappedCountButtonProps) => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip content={formatMessage(messages.cappedCountTooltip)}>
			{(tooltipProps) => (
				<Button
					{...tooltipProps}
					appearance="link"
					spacing="none"
					onClick={onClick}
					aria-label={formatMessage(messages.cappedCountTooltip)}
					testId="servicedesk-insight-object-schema-page.common.ui.total-objects-pagination.uncapped-count-button.button"
				>
					<>
						<FormattedNumber value={cappedTotal} />+
					</>
				</Button>
			)}
		</Tooltip>
	);
};
