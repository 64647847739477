import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'servicedesk-insight-object-schema-page.page-content.error.error-title',
		defaultMessage: 'Failed to load object schema page',
		description:
			'Error message instructing user that the object schema page did not load successfully',
	},
	errorDescription: {
		id: 'servicedesk-insight-object-schema-page.page-content.error.error-description',
		defaultMessage: 'Please refresh the page and try again.',
		description:
			'Error description instructing user to refresh the page to try again after an error occurred.',
	},
});
