import React, { type ReactNode } from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { GenericEmptyStateImage } from '@atlassian/jira-servicedesk-insight-glyphs';
import {
	canCreateObjectType,
	useLoadedSchemaPageData,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import { messages } from './messages';

export const EmptyPageContent = () => {
	const { formatMessage } = useIntl();
	const [schemaPageData, { setObjectTypeModalState }] = useLoadedSchemaPageData();
	const onAdd = () => {
		setObjectTypeModalState({ type: 'creating', initialParent: null });
	};
	const canCreate = canCreateObjectType(schemaPageData, null);

	return (
		<Container>
			<EmptyState
				size="wide"
				imageUrl={GenericEmptyStateImage}
				header=""
				description={formatMessage(messages.emptyStateTitle)}
				primaryAction={
					canCreate ? (
						<Button onClick={onAdd} appearance="link">
							{formatMessage(messages.addObjectType)}
						</Button>
					) : (
						formatMessage(messages.emptyStateNonAdminAction)
					)
				}
			/>
		</Container>
	);
};

const Container = ({ children }: { children: ReactNode }) => (
	// Use 2 Boxes to achieve same marginTop used before Design Token pattern existed
	<Box
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		xcss={xcss({
			marginTop: 'space.1000',
		})}
	>
		<Box
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({
				marginTop: 'space.300',
			})}
		>
			{children}
		</Box>
	</Box>
);
