import {
	createContainer,
	createHook,
	createStore,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions';
import { fetchInitialTasks } from './handlers/fetch-initial-tasks';
import type { State, ContainerProps } from './types';

const initialState: State = {
	isPolling: false,
	tasks: [],
	bulkDelete: {
		isSubmitting: false,
	},
	bulkEdit: {
		isSubmitting: false,
	},
	bulkExport: {
		isSubmitting: false,
	},
	bulkQrCode: {
		isSubmitting: false,
	},
};

const store = createStore({
	initialState,
	actions,
});

export const CmdbAsyncActionsStoreContainer = createContainer<State, Actions, ContainerProps>(
	store,
	{
		onInit: fetchInitialTasks,
	},
);

export const useCmdbAsyncActionsStore = createHook(store);

export const useBulkObjectDelete = createHook(store, {
	selector: ({ bulkDelete }) => bulkDelete,
});

export const useBulkObjectEdit = createHook(store, {
	selector: ({ bulkEdit }) => bulkEdit,
});

export const useBulkObjectExport = createHook(store, {
	selector: ({ bulkExport }) => bulkExport,
});

export const useBulkObjectQrCode = createHook(store, {
	selector: ({ bulkQrCode }) => bulkQrCode,
});

export const useCmdbAsyncActions = createActionsHook(store);
