import React, { useCallback } from 'react';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { AsyncCmdbBulkEditModal } from '@atlassian/jira-servicedesk-cmdb-bulk-edit-modal';
import {
	type ObjectTypesById,
	useInsightObjectSchemaPageData,
	useLoadedSchemaPageData,
	useSchemaPageUiState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import { useObjectSearchStoreState } from '@atlassian/jira-servicedesk-insight-object-search-store';
import type {
	BulkEditSuccess,
	CmdbObjectTypeId,
} from '@atlassian/jira-servicedesk-insight-shared-types';

export const isObjectTypeInTree = (
	objectTypesById: ObjectTypesById,
	selectedObjectTypeId: CmdbObjectTypeId | null,
	searchObjectTypeId: CmdbObjectTypeId,
): boolean => {
	const currentObjectType = objectTypesById[searchObjectTypeId];
	if (!currentObjectType) return false;
	if (selectedObjectTypeId === currentObjectType.id) {
		return true;
	}
	// Only inherited child objects appear in parent object types
	if (currentObjectType.parentObjectTypeInherited && currentObjectType.parentObjectTypeId) {
		return isObjectTypeInTree(
			objectTypesById,
			selectedObjectTypeId,
			currentObjectType.parentObjectTypeId,
		);
	}
	return false;
};

export const ManageBulkEditModals = () => {
	const [, { refreshSearch }] = useObjectSearchStoreState();
	const [{ selectedObjectTypeId }] = useSchemaPageUiState();
	const [{ bulkEditModalsState }, { closeBulkEditModal, fetchSchemaObjectTypes }] =
		useInsightObjectSchemaPageData();
	const [{ objectTypesById }] = useLoadedSchemaPageData();

	const handleOnCancel = useCallback(
		(uuid: string) => {
			closeBulkEditModal(uuid);
		},
		[closeBulkEditModal],
	);

	const handleOnSuccess = useCallback(
		({ objectTypeId, uuid }: BulkEditSuccess) => {
			closeBulkEditModal(uuid);
			fetchSchemaObjectTypes();
			// We should only refresh the search if current object type id is in the same tree as updated
			if (isObjectTypeInTree(objectTypesById, selectedObjectTypeId, objectTypeId)) {
				refreshSearch();
			}
		},
		[
			closeBulkEditModal,
			fetchSchemaObjectTypes,
			objectTypesById,
			refreshSearch,
			selectedObjectTypeId,
		],
	);

	return (
		<ModalTransition>
			{bulkEditModalsState.map((bulkEditModal) => (
				<AsyncCmdbBulkEditModal
					key={bulkEditModal.uuid}
					{...bulkEditModal}
					onCancel={handleOnCancel}
					onSuccess={handleOnSuccess}
				/>
			))}
		</ModalTransition>
	);
};
