import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { Props } from './ui/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyAssetsSchemaGraphModal = lazy(() =>
	import(/* webpackChunkName: "assets-schema-graph-modal" */ './ui').then(
		(module) => module.SchemaGraphModal,
	),
);

export const AsyncAssetsSchemaGraphModal = (props: Props) => (
	<Placeholder name="assets-schema-graph-modal" fallback={null}>
		<LazyAssetsSchemaGraphModal {...props} />
	</Placeholder>
);
