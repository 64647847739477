import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fg } from '@atlassian/jira-feature-gating';
import { ValidationError } from '@atlassian/jira-fetch';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ValidatedIql } from '@atlassian/jira-servicedesk-insight-iql-validation';
import {
	type ActionApi,
	type AttributeFiltersByName,
	type ExecuteSearchProps,
	matchSearchState,
	type SearchRequest,
	type SearchState,
} from '../../../../common/types';
import type { ValueMatch } from '../../../../common/types/filters';
import { checkIsSupportedSearchScope } from '../../../../common/utils';
import {
	insightObjectSearchLocalStorage,
	OBJECT_TYPE,
	SELECTED_COLUMNS,
	setLocalLastSearchQuery,
	getLocalLastSearchQuery,
} from '../../../../common/utils/storage';
import { fetchSearchResults } from '../../../../services/rest/fetch-search-results';
import { ICON_COLUMN_TARGET } from '../../../../services/selectors';
import { initialState } from '../../index';
import { getInitialSelectedColumns, getLocalSelectedColumns } from './utils';

export const executeSearch =
	({
		request,
		isRefresh = false,
		// The uncapped (>1000) total count is fetched externally using the /aql/totalcount and not apart of /navlist
		// but use the same state store, we only want the previously stored uncappedNumObjects to reset in certain situations
		// e.g new AQL, filters or refresh - updating of pagination, ORDER BY should not reset.
		resetUncappedNumObjects = false,
	}: ExecuteSearchProps): ActionApi =>
	async ({ setState, getState, dispatch }, { workspaceId, createAnalyticsEvent, searchScope }) => {
		const isInitialRequest = matchSearchState(getState().lastSearch, {
			loading: () => true,
			error: () => false,
			success: () => false,
		});

		try {
			setState({ pendingSearch: { request, isRefresh } });

			const result = await fetchSearchResults({
				workspaceId,
				searchScope,
				searchRequest: request,
			});

			// Check that pendingSearch in state is identical. If not, another search has been run since this
			// one, so the current search should be discarded.
			const { pendingSearch, currentSearchScope, lastSearch: previousSearch } = getState();
			if (pendingSearch !== null && request !== pendingSearch.request) {
				return;
			}

			if (currentSearchScope === null) {
				return;
			}
			const { objectTypeId } = checkIsSupportedSearchScope(currentSearchScope);

			const previousUncappedNumObjects =
				previousSearch.type === 'success' && fg('assets_rearch_total_count_deprecated_for_ui')
					? previousSearch.value.result.uncappedNumObjects
					: undefined;

			const lastSearch: SearchState = {
				type: 'success',
				value: {
					request,
					result: {
						...result,
						uncappedNumObjects: resetUncappedNumObjects ? undefined : previousUncappedNumObjects,
					},
				},
			};
			const localObjectTypeRecord = insightObjectSearchLocalStorage.get(OBJECT_TYPE + objectTypeId);
			const localSelectedColumnRecord =
				localObjectTypeRecord === undefined ? undefined : localObjectTypeRecord[SELECTED_COLUMNS];

			if (isInitialRequest) {
				const selectedColumns =
					localSelectedColumnRecord !== undefined
						? getLocalSelectedColumns(localSelectedColumnRecord, result)
						: [ICON_COLUMN_TARGET, ...getInitialSelectedColumns(result)];
				setState({ lastSearch, pendingSearch: null, selectedColumns });
			} else {
				setLocalLastSearchQuery(objectTypeId, result.qlQuery);
				setState({ lastSearch, pendingSearch: null });
			}

			fireTrackAnalytics(createAnalyticsEvent({}), 'executeSearch succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// Check that pendingSearch in state is identical. If not, another search has been run since this
			// one, so the current search should be discarded.
			const { pendingSearch, currentSearchScope } = getState();
			if (pendingSearch !== null && request !== pendingSearch.request) {
				return;
			}

			/**
			 * When user set up the filter with custom object attributes, filter will be stored in both store and localStorage
			 * When the user deletes that attribute but we still have that attribute in the qlquery, backend will return 400 ValidationError
			 * In this case frontend will show an error state and the user is not able to access the table view
			 * In this situation, this piece of code will clear the qlQuery filter to aviod error state
			 */
			if (
				currentSearchScope?.type === 'schema-object-type' &&
				error instanceof ValidationError &&
				getLocalLastSearchQuery(currentSearchScope.objectTypeId) &&
				(isInitialRequest || isRefresh)
			) {
				const { page } = request;
				const newRequest: SearchRequest = {
					query: { type: 'qlQuery', qlQuery: '' },
					page,
				};

				setState({ ...initialState, currentSearchScope });
				setLocalLastSearchQuery(currentSearchScope.objectTypeId, '');
				await dispatch(executeSearch({ request: newRequest, resetUncappedNumObjects: true }));
				return;
			}

			// Only ever set `lastSearch` to error if this is the initial request. For updates, the error is thrown
			// allowing callers to indicate the failure (e.g. with a flag).
			if (isInitialRequest) {
				setState({ lastSearch: { type: 'error', error }, pendingSearch: null });
			} else {
				setState({ pendingSearch: null });
			}

			fireErrorAnalytics({
				meta: {
					id: 'executeSearch',
					packageName: 'jiraServicedeskInsightObjectSearchStore',
					teamName: 'falcons',
				},
				error,
			});

			throw error;
		}
	};

const throwForInvalidState = async () => {
	throw new Error('Cannot call search update in loading / error state');
};

/**
 * Used to update an existing QL search, retaining other existing state such as selected attributes
 */
export const updateSearchIql =
	(newIql: ValidatedIql): ActionApi =>
	async ({ getState, dispatch }) => {
		const { lastSearch } = getState();
		return matchSearchState<Promise<undefined>>(lastSearch, {
			loading: throwForInvalidState,
			error: throwForInvalidState,
			success: async () => {
				const newRequest: SearchRequest = {
					query: { type: 'qlQuery', qlQuery: newIql },
					page: { type: 'page-number', pageNumber: 1 },
				};

				await dispatch(executeSearch({ request: newRequest, resetUncappedNumObjects: true }));
			},
		});
	};

/**
 * Used to update an existing filter search, retaining other existing state such as selected attributes
 */
export const updateSearchFilter =
	(newFilters: AttributeFiltersByName, newObjectTypes: ValueMatch<string>[] = []): ActionApi =>
	async ({ getState, dispatch }) => {
		const { lastSearch } = getState();
		return matchSearchState<Promise<undefined>>(lastSearch, {
			loading: throwForInvalidState,
			error: throwForInvalidState,
			success: async ({ result }) => {
				const { orderBy } = result;
				const newRequest: SearchRequest = {
					query: {
						type: 'filter',
						// Retain existing order by
						orderBy,
						filters: newFilters,
						objectTypes: newObjectTypes,
					},
					page: { type: 'page-number', pageNumber: 1 },
				};

				await dispatch(executeSearch({ request: newRequest, resetUncappedNumObjects: true }));
			},
		});
	};
/**
 * Used to refresh the list of objects, e.g. after an object has been created / updated / deleted.
 */
export const refreshSearch =
	(): ActionApi =>
	async ({ getState, dispatch }) => {
		const { lastSearch } = getState();
		return matchSearchState<Promise<undefined>>(lastSearch, {
			// Ignore if this is called while still loading
			loading: () => Promise.resolve(undefined),
			error: () => Promise.resolve(undefined),
			success: async ({ request }) => {
				await dispatch(executeSearch({ request, isRefresh: true, resetUncappedNumObjects: true }));
			},
		});
	};
