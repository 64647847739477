import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { CreateObjectModalProps } from './ui';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCreateObjectModal = lazy(
	() => import(/* webpackChunkName: "insight-create-object-modal" */ './ui'),
);

export const AsyncCreateObjectModal = (props: CreateObjectModalProps) => (
	<Placeholder name="insight-create-object-modal" fallback={null}>
		<LazyCreateObjectModal {...props} />
	</Placeholder>
);
