import { performPostRequest } from '@atlassian/jira-fetch';
import { getCmdbUserHistoryViewedUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types';

export type EntityType = 'object' | 'object-type' | 'object-schema';

export const trackView = async ({
	workspaceId,
	entityId,
	entityType,
}: {
	workspaceId: WorkspaceId;
	entityId: string;
	entityType: EntityType;
}): Promise<void> => {
	await performPostRequest(getCmdbUserHistoryViewedUrl(workspaceId), {
		body: JSON.stringify({ entityId, entityType }),
	});
};
