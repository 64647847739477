import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	sidebarLabel: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.sidebar-label',
		defaultMessage: 'Object schema navigation sidebar',
		description:
			'Describes the navigation sidebar, for users viewing the page with a screen reader.',
	},
});
