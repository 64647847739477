import type { ActionApi, SearchMode } from '../../../../common/types';
import { checkIsSupportedSearchScope } from '../../../../common/utils';
import { setLocalSearchMode } from '../../../../common/utils/storage';

export const setSearchMode =
	(newSearchMode: SearchMode): ActionApi =>
	async ({ setState }, { searchScope }) => {
		const { objectTypeId } = checkIsSupportedSearchScope(searchScope);
		setState({
			searchMode: newSearchMode,
		});
		setLocalSearchMode(objectTypeId, newSearchMode);
	};
