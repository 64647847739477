import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	objectTypeSettings: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.content.object-types.object-type-link.badge-and-actions.object-type-settings',
		defaultMessage: 'Configure object type',
		description: 'Label for a link to the configuration for an object type',
	},
	copy: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.content.object-types.object-type-link.badge-and-actions.copy',
		defaultMessage: 'Copy object type',
		description: 'Label for a button that creates a copy of the object type',
	},
	more: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.content.object-types.object-type-link.badge-and-actions.more',
		defaultMessage: 'More',
		description: 'Label for a button that shows more actions when clicked',
	},
});
