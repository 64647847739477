import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ImportHistoryModalProps } from './ui/main';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyImportHistoryModal = lazy(() =>
	import(/* webpackChunkName: "assets-import-history-modal" */ './index').then(
		(module) => module.ImportHistoryModal,
	),
);

export const AsyncImportHistoryModal = (props: ImportHistoryModalProps) => (
	<Placeholder name="assets-import-history-modal" fallback={null}>
		<LazyImportHistoryModal {...props} />
	</Placeholder>
);
