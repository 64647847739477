import { updateObjectFilterExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import type { ActionApi } from '../../../../common/types';

/**
 *
 * This is used to reset the filtersUpdated back to false as well as trigger the
 * success of the object filter experience. This is used after the list of objects have been rendered
 * if the render was triggered by updating the filters. This allows us to measure the latency
 * from when the filters were updated to when the objects list have finished rendering
 */
export const triggerSuccessfulObjectFilterExperience =
	(): ActionApi =>
	async ({ setState }) => {
		updateObjectFilterExperience.success();

		setState({ filtersUpdated: false });

		return undefined;
	};

/**
 *
 * This is used to start the object filter experience
 * which starts when the filters are updated via basic/advanced filters. This also toggles
 * filtersUpdated to true to indicate that the filters have just been updated
 */
export const startUpdateObjectFilterExperience =
	(): ActionApi =>
	async ({ setState }) => {
		updateObjectFilterExperience.start();

		setState({ filtersUpdated: true });

		return undefined;
	};

/**
 *
 * Used when object filtering fails
 */
export const failUpdateObjectFilterExperience =
	(): ActionApi =>
	async ({ setState }) => {
		updateObjectFilterExperience.failure();
		setState({ filtersUpdated: false });

		return undefined;
	};
