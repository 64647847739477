import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ColumnPickerProps } from './common/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyColumnPicker = lazy(() => import(/* webpackChunkName: "insight-column-picker" */ './ui'));

export const AsyncColumnPicker = (props: ColumnPickerProps) => (
	<Placeholder name="insight-column-picker" fallback={null}>
		<LazyColumnPicker {...props} />
	</Placeholder>
);
