import type { ValidatedIql } from '@atlassian/jira-servicedesk-insight-iql-validation';
import type {
	CmdbObjectId,
	CmdbObjectTypeId,
	ObjectTypeAttributeId,
	SchemaId,
} from '@atlassian/jira-servicedesk-insight-shared-types';

export type BaseSearchRequestPayload = {
	objectSchemaId: SchemaId;
	objectTypeId: CmdbObjectTypeId;
	attributesToDisplay: {
		attributesToDisplayIds: ObjectTypeAttributeId[];
	} | null;
	page: number;
	// If specified, asks for the page containing the given object ID
	objectId?: CmdbObjectId;
	includeAttributes: true;
	resultsPerPage: number;
};

export type IqlSearchRequestPayload = BaseSearchRequestPayload & {
	qlQuery: ValidatedIql;
};

export type FilterRequestItem = {
	objectTypeAttributeId: ObjectTypeAttributeId;
	selectedValues: string[];
	filterByObjectType?: boolean;
};

export const REQUEST_DESCENDING = '0' as const;
export const REQUEST_ASCENDING = '1' as const;
export type FilterSearchRequestPayload = BaseSearchRequestPayload & {
	filters: FilterRequestItem[];
	// Optional order by attribute and direction
	orderByTypeAttrId?: ObjectTypeAttributeId;
	asc?: typeof REQUEST_DESCENDING | typeof REQUEST_ASCENDING;
};
