import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import {
	toAttributeId,
	toObjectTypeAttributeId,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import type {
	InsightObjectAttribute,
	DefaultAttribute,
	GroupAttribute,
	ObjectAttribute,
	ProjectAttribute,
	BitbucketRepoAttribute,
	StatusAttribute,
	UserAttribute,
	OpsgenieTeamAttribute,
} from '../../types/object-attribute';
import {
	type OpsgenieTeamAttributeType,
	type AttributeType,
	type ObjectAttributeTypeResponse,
	type InsightObjectAttributeType,
	type DefaultAttributeType,
	type ObjectAttributeType,
	type UserAttributeType,
	type GroupAttributeType,
	type StatusAttributeType,
	type ProjectAttributeType,
	type BitbucketRepoAttributeType,
	AttributeTypeNumbers,
} from '../../types/object-attribute-type';
import type {
	InsightObjectDetailsResponse,
	ObjectAttributeResponse,
	InsightObjectDetails,
} from '../../types/object-details';
import { matchAttributeType } from '../type-matchers';

export const toAttributeType = (attributeTypeAsNumber: AttributeTypeNumbers): AttributeType => {
	switch (attributeTypeAsNumber) {
		case AttributeTypeNumbers.default:
			return 'default';
		case AttributeTypeNumbers.object:
			return 'object';
		case AttributeTypeNumbers.user:
			return 'user';
		case AttributeTypeNumbers.group:
			return 'group';
		case AttributeTypeNumbers.project:
			return 'project';
		case AttributeTypeNumbers.status:
			return 'status';
		case AttributeTypeNumbers.bitbucketRepo:
			return 'bitbucketRepo';
		case AttributeTypeNumbers.opsgenieTeam:
			return 'opsgenieTeam';
		default: {
			const attributeTypeAsNumberExhaustive: never = attributeTypeAsNumber;
			const error = new Error(
				`Invalid attribute type provided from Insight service found: ${attributeTypeAsNumberExhaustive}`,
			);
			fireErrorAnalytics({
				meta: {
					id: 'invalid.attribute.type',
					packageName: 'jiraServicedeskInsightObjectTypes',
					teamName: 'falcons',
				},
				error,
			});
			throw error;
		}
	}
};

const assertDefined = <T,>(value: T): NonNullable<T> => {
	if (value != null) return value;

	fireErrorAnalytics({
		meta: {
			id: 'missing.property.from.insight.service',
			packageName: 'jiraServicedeskInsightObjectTypes',
		},
	});

	throw new Error('Expected value from Insight service is null or undefined');
};

export const normalizeAttributeTypeResponse = ({
	type,
	defaultType,
	suffix,
	regexValidation,
	referenceType,
	referenceObjectType,
	...attributeType
}: ObjectAttributeTypeResponse): InsightObjectAttributeType =>
	matchAttributeType<InsightObjectAttributeType>(toAttributeType(type), {
		default: (): DefaultAttributeType => ({
			...attributeType,
			defaultType: assertDefined(defaultType),
			suffix,
			regexValidation,
			type: 'default',
		}),
		object: (): ObjectAttributeType => ({
			...attributeType,
			referenceType: assertDefined(referenceType),
			referenceObjectType: assertDefined(referenceObjectType),
			type: 'object',
		}),
		user: (): UserAttributeType => ({
			...attributeType,
			type: 'user',
		}),
		group: (): GroupAttributeType => ({
			...attributeType,
			type: 'group',
		}),
		project: (): ProjectAttributeType => ({
			...attributeType,
			type: 'project',
		}),
		status: (): StatusAttributeType => ({
			...attributeType,
			type: 'status',
		}),
		bitbucketRepo: (): BitbucketRepoAttributeType => ({
			...attributeType,
			type: 'bitbucketRepo',
		}),
		opsgenieTeam: (): OpsgenieTeamAttributeType => ({
			...attributeType,
			type: 'opsgenieTeam',
		}),
	});
export const normalizeAttributeTypesResponse = (attributeTypes: ObjectAttributeTypeResponse[]) =>
	attributeTypes.map<InsightObjectAttributeType>((objectTypeAttributeResponse) =>
		normalizeAttributeTypeResponse(objectTypeAttributeResponse),
	);

export const normalizeObjectAttributeResponse = (
	attributeResponse: ObjectAttributeResponse,
): InsightObjectAttribute => {
	const { objectId, workspaceId, ...rest } = attributeResponse;
	const { id, objectTypeAttributeId, objectTypeAttribute, objectAttributeValues } = rest;

	const attributeCommon = {
		// Remove assets-rearch-deprecate-attribute-value-id_ykukg
		...(id && { id: toAttributeId(id) }),
		objectTypeAttributeId: toObjectTypeAttributeId(objectTypeAttributeId),
	};

	return matchAttributeType<InsightObjectAttribute>(
		toAttributeType(attributeResponse.objectTypeAttribute.type),
		{
			default: (): DefaultAttribute => {
				const { type, ...withoutNumberType } = objectTypeAttribute;
				return {
					...attributeCommon,
					type: 'default',
					objectTypeAttribute: {
						...withoutNumberType,
						type: 'default',
						defaultType: assertDefined(objectTypeAttribute.defaultType),
					},
					defaultTypeValues: objectAttributeValues.map((attributeValue) => {
						const { referencedObject, group, project, user, status, ...attrValue } = attributeValue;
						return {
							...attrValue,
							value: attrValue.value,
						};
					}),
				};
			},
			object: (): ObjectAttribute => {
				const { type, ...withoutNumberType } = objectTypeAttribute;
				return {
					...attributeCommon,
					type: 'object',
					objectTypeAttribute: {
						...withoutNumberType,
						type: 'object',
						referenceType: assertDefined(objectTypeAttribute.referenceType),
						referenceObjectType: assertDefined(objectTypeAttribute.referenceObjectType),
					},
					objectTypeValues: objectAttributeValues.map((attributeValue) => {
						const { bitbucketRepo, group, project, user, status, value, ...attrValue } =
							attributeValue;
						return {
							...attrValue,
							referencedObject: assertDefined(attrValue.referencedObject),
						};
					}),
				};
			},
			user: (): UserAttribute => {
				const { type, ...withoutNumberType } = objectTypeAttribute;
				return {
					...attributeCommon,
					type: 'user',
					objectTypeAttribute: { ...withoutNumberType, type: 'user' },
					userTypeValues: objectAttributeValues.map((attributeValue) => {
						const { bitbucketRepo, referencedObject, project, group, status, value, ...attrValue } =
							attributeValue;
						return {
							...attrValue,
							user: assertDefined(attrValue.user),
						};
					}),
				};
			},
			group: (): GroupAttribute => {
				const { type, ...withoutNumberType } = objectTypeAttribute;
				return {
					...attributeCommon,
					type: 'group',
					objectTypeAttribute: { ...withoutNumberType, type: 'group' },
					groupTypeValues: objectAttributeValues.map((attributeValue) => {
						const { bitbucketRepo, referencedObject, project, user, status, value, ...attrValue } =
							attributeValue;
						return {
							...attrValue,
							group: assertDefined(attrValue.group),
						};
					}),
				};
			},
			project: (): ProjectAttribute => {
				const { type, ...withoutNumberType } = objectTypeAttribute;
				return {
					...attributeCommon,
					type: 'project',
					objectTypeAttribute: { ...withoutNumberType, type: 'project' },
					projectTypeValues: objectAttributeValues.map((attributeValue) => {
						const { bitbucketRepo, referencedObject, user, group, status, value, ...attrValue } =
							attributeValue;
						return {
							...attrValue,
							project: assertDefined(attrValue.project),
						};
					}),
				};
			},
			status: (): StatusAttribute => {
				const { type, ...withoutNumberType } = objectTypeAttribute;
				return {
					...attributeCommon,
					type: 'status',
					objectTypeAttribute: { ...withoutNumberType, type: 'status' },
					statusTypeValues: objectAttributeValues.map((attributeValue) => {
						const { bitbucketRepo, referencedObject, user, group, project, value, ...attrValue } =
							attributeValue;
						return {
							...attrValue,
							status: assertDefined(attrValue.status),
						};
					}),
				};
			},
			bitbucketRepo: (): BitbucketRepoAttribute => {
				const { type, ...withoutNumberType } = objectTypeAttribute;
				return {
					...attributeCommon,
					type: 'bitbucketRepo',
					objectTypeAttribute: { ...withoutNumberType, type: 'bitbucketRepo' },
					bitbucketRepoTypeValues: objectAttributeValues.map((attributeValue) => {
						const { referencedObject, user, group, project, value, ...attrValue } = attributeValue;
						return {
							...attrValue,
							bitbucketRepo: assertDefined(attrValue.bitbucketRepo),
						};
					}),
				};
			},
			opsgenieTeam: (): OpsgenieTeamAttribute => {
				const { type, ...withoutNumberType } = objectTypeAttribute;
				return {
					...attributeCommon,
					type: 'opsgenieTeam',
					objectTypeAttribute: { ...withoutNumberType, type: 'opsgenieTeam' },
					opsgenieTeamTypeValues: objectAttributeValues.map((attributeValue) => {
						const { referencedObject, user, group, project, value, ...attrValue } = attributeValue;
						return {
							...attrValue,
							opsgenieTeam: assertDefined(attrValue.opsgenieTeam),
						};
					}),
				};
			},
		},
	);
};

export const normalizeObjectDetailsResponse = (
	objectDetails: InsightObjectDetailsResponse,
): InsightObjectDetails => {
	const normalisedAttributes = objectDetails.attributes.map((attributeResponse) =>
		normalizeObjectAttributeResponse(attributeResponse),
	);

	return {
		...objectDetails,
		attributes: normalisedAttributes,
	};
};
