import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { AttributesTableProps } from './common/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyAsyncAttributesTable = lazy(
	() => import(/* webpackChunkName: "insight-attributes-table" */ './ui'),
);

export const AsyncAttributesTable = (props: AttributesTableProps) => (
	<Placeholder name="insight-attributes-table" fallback={null}>
		<LazyAsyncAttributesTable {...props} />
	</Placeholder>
);
