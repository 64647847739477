import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { CreateObjectTypeModalProps } from './ui/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCreateObjectTypeModal = lazy(
	() => import(/* webpackChunkName: "insight-create-object-type-modal" */ './ui'),
);

export const AsyncCreateObjectTypeModal = (props: CreateObjectTypeModalProps) => (
	<Placeholder name="insight-create-object-type-modal" fallback={null}>
		<LazyCreateObjectTypeModal {...props} />
	</Placeholder>
);
