import { performPostRequest } from '@atlassian/jira-fetch';
import { getCmdbAsyncObjectExportUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object';
import type {
	BulkActionResponse,
	WorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import type { AsyncExportObjectParams } from './types';

// The following properties are required by the endpoint but don't actually
// control the response in any way. This is why their values are hardcoded.
const UNUSED_REQUIRED_PROPERTIES = {
	asc: '1',
	attributesToDisplay: { attributesToDisplayIds: [] },
	includeAttributes: false,
	page: 1,
	resultsPerPage: 25,
};

export const generateBody = (params: AsyncExportObjectParams) => {
	const body = { ...params };

	if (params.filter.qlQuerySearch && params.filter.qlQueryParams) {
		body.filter.qlQueryParams = {
			...params.filter.qlQueryParams,
			...UNUSED_REQUIRED_PROPERTIES,
		};
	} else if (!params.filter.qlQuerySearch && params.filter.filterParams) {
		body.filter.filterParams = {
			...params.filter.filterParams,
			...UNUSED_REQUIRED_PROPERTIES,
		};
	} else {
		throw new Error(
			`Invalid state, qlQuerySearch: ${params.filter.qlQuerySearch}, qlQueryParams: ${params.filter.qlQueryParams}, filterParams: ${params.filter.filterParams}`,
		);
	}
	return body;
};

export const createAsyncBulkExportTask = (
	workspaceId: WorkspaceId,
	params: AsyncExportObjectParams,
): Promise<BulkActionResponse> => {
	if (params.filter.qlQueryParams == null && params.filter.filterParams == null) {
		throw new Error('Must supply either `filters` or `iqlParams` to `createAsyncBulkExportTask`');
	}

	const body = generateBody(params);

	return performPostRequest(getCmdbAsyncObjectExportUrl(workspaceId), {
		body: JSON.stringify(body),
	});
};
