import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	assetsBreadcrumbsItem: {
		id: 'servicedesk-insight-object-schema-page.common.object-schema-page-layout.page-header.assets-breadcrumbs-item',
		defaultMessage: 'Assets',
		description: 'Assets breadcrumbs item on the top of the assets object schema page',
	},
	schemasBreadcrumbsItem: {
		id: 'servicedesk-insight-object-schema-page.common.object-schema-page-layout.page-header.schemas-breadcrumbs-item',
		defaultMessage: 'Schemas',
		description: 'Schemas breadcrumbs item on the top of the assets object schema page',
	},
	assetsTopHeaderBreadcrumbsA11Y: {
		id: 'servicedesk-insight-object-schema-page.common.object-schema-page-layout.page-header.assets-top-header-breadcrumbs-a-11-y',
		defaultMessage: 'Assets top header breadcrumbs',
		description:
			'Visually hidden description of the Assets top header breadcrumbs for screen reader',
	},
});
