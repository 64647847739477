import { addCreatedObjectType } from './add-created-object-type';
import { fetchSchemaObjectTypes } from './fetch-schema-object-types';
import { fetchSchemaPageData } from './fetch-schema-page-data';
import { closeBulkEditModal, openBulkEditModal } from './set-bulk-edit-modal-state';
import { setCurrentOTMeatballMenu } from './set-current-ot-meatball-menu';
import { setCreateAnotherChecked } from './set-is-create-another-checked';
import { openCreateObject, closeCreateObject } from './set-is-create-object-open';
import { openSchemaGraph, closeSchemaGraph } from './set-is-schema-graph-open';
import { setObjectTypeModalState } from './set-modal-state';
import { updateObjectType } from './update-object-type';
import { updateObjectTypePosition } from './update-object-type-position';

export const actions = {
	addCreatedObjectType,
	fetchSchemaObjectTypes,
	fetchSchemaPageData,
	setCurrentOTMeatballMenu,
	setObjectTypeModalState,
	updateObjectTypePosition,
	updateObjectType,
	openCreateObject,
	closeCreateObject,
	openSchemaGraph,
	closeSchemaGraph,
	openBulkEditModal,
	closeBulkEditModal,
	setCreateAnotherChecked,
} as const;

export type Actions = typeof actions;
