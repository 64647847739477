import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ListIcon from '@atlaskit/icon/glyph/list';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { AsyncBulkActions } from '@atlassian/jira-servicedesk-insight-bulk-actions';
import { AsyncObjectFilter } from '@atlassian/jira-servicedesk-insight-object-filter';
import {
	useUpdateQueryParams,
	SCHEMA_PAGE_QUERY_PARAMS,
} from '@atlassian/jira-servicedesk-insight-urls';
import { messages } from './messages';

export type ControlsProps = {
	isLoading?: boolean;
};

export const Controls = ({ isLoading }: ControlsProps) => {
	const { formatMessage } = useIntl();
	const [{ updateQueryParams, pushRouterAction }] = useUpdateQueryParams();

	const onClickListView = useCallback(() => {
		const updatedPath = updateQueryParams({
			[SCHEMA_PAGE_QUERY_PARAMS.objectId]: null,
			[SCHEMA_PAGE_QUERY_PARAMS.objectViewMode]: 'list',
		});

		pushRouterAction(updatedPath);
	}, [updateQueryParams, pushRouterAction]);

	return (
		<Container>
			{isLoading && (
				<ContainerColLeft>
					{formatMessage(messages.loading)}
					<Spinner size="small" />
				</ContainerColLeft>
			)}
			<ContainerColRight>
				<li>
					<AsyncBulkActions isCompact />
				</li>
				<li>
					<AsyncObjectFilter triggerDisplay="compact" popupPlacement="start" />
				</li>
				<li>
					<Tooltip content={formatMessage(messages.listView)}>
						<Button
							onClick={onClickListView}
							// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
							iconBefore={<ListIcon label="" />}
							appearance="subtle"
							testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-details.controls.button"
						/>
					</Tooltip>
				</li>
			</ContainerColRight>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	padding: `${token('space.050', '4px')} 0`,
});

const containerColStyles = {
	flex: 1,
	display: 'flex',
	alignContent: 'center',
	alignItems: 'center',
	justifyContent: 'flex-start',
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerColLeft = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...containerColStyles,
	flexFlow: 'row wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N200),
	paddingLeft: token('space.050', '4px'),
	gap: `0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerColRight = styled.ul({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...containerColStyles,
	justifyContent: 'flex-end',
	listStyleType: 'none',
	padding: 0,
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> li': {
		marginTop: 0,
	},
});
