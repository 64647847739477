export const ACTION_BULK_EXPORT = 'Export';
export type AsyncBulkAction = typeof ACTION_BULK_EXPORT;
export const OPERATION_TYPE_BULK_EDIT = 'BulkEdit';
export const OPERATION_TYPE_BULK_DELETE = 'BulkDelete';
export const OPERATION_TYPE_BULK_EXPORT = 'BulkExport';
export const OPERATION_TYPE_BULK_QR_CODE = 'QrCode';

export const STATUS_QUEUED = 'Queued';
export const STATUS_IN_PROGRESS = 'InProgress';
export const STATUS_COMPLETED = 'Completed';

export type TaskOperationType =
	| typeof OPERATION_TYPE_BULK_EDIT
	| typeof OPERATION_TYPE_BULK_DELETE
	| typeof OPERATION_TYPE_BULK_EXPORT
	| typeof OPERATION_TYPE_BULK_QR_CODE;

export type TaskStatus = typeof STATUS_QUEUED | typeof STATUS_IN_PROGRESS | typeof STATUS_COMPLETED;

export type TaskLink = {
	rel: string;
	href: string;
	type: string;
};

export type TaskId = string;

export const toTaskId = (id: string): TaskId => id;

export type Task = {
	identifier: TaskId;
	operationType: TaskOperationType;
	status: TaskStatus;
	itemRequestedCount?: number;
	itemSucceededCount?: number;
	hasError?: boolean;
	links?: TaskLink[];
};

export type ErroneousObject = {
	objectId: string;
	error: string;
};

export type BulkActionResponse = {
	taskId: TaskId;
	links: TaskLink[];
};
