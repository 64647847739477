import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	emptyStateTitle: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.empty.empty-state-title',
		defaultMessage: 'Add an object type to this schema to start tracking objects',
		description:
			'Tells a user that they need to add an object type to start working with the product.',
	},
	emptyStateNonAdminAction: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.empty.empty-state-non-admin-action',
		defaultMessage: 'Ask your admin to grant you create object type permission',
		description:
			'Tells the user that they need an administrator to give them permission to create object types',
	},
	addObjectType: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.empty.add-object-type',
		defaultMessage: 'Add an object type',
		description: 'Label for a button that adds an object type when clicked.',
	},
});
