import { useEffect } from 'react';
import { useSchemaPageUiState } from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import type { SearchResultObject } from '@atlassian/jira-servicedesk-insight-object-search-store';

/**
 * Ensure that the selected objectId is always one of the list of displayed objects
 */
export const ObjectIdQueryParamSync = ({ objects }: { objects: SearchResultObject[] }) => {
	const [{ objectId }, { setObjectId }] = useSchemaPageUiState();

	useEffect(() => {
		if (!objects.some(({ id }) => id === objectId)) {
			const [firstObject = null] = objects;
			setObjectId(firstObject?.id || null, 'replace');
		}
	}, [objectId, setObjectId, objects]);

	return null;
};
