import { performPostRequest } from '@atlassian/jira-fetch';
import { getCmdbObjectTypeAttributeFilterUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls';
import type { ObjectFilterEntry } from '@atlassian/jira-servicedesk-insight-object-types';
import type { SearchResultObjectType } from '../../../types';
import type { ValueMatch } from '../../../types/filters';
import type { FilterResponse, SearchObjectTypesResponse } from '../../../types/responses';
import { extractWildcard } from '../index';

export const getObjectTypesIfObjectIsInherited = async ({
	objectTypeIsInherited,
	workspaceId,
	objectTypeId,
}: {
	objectTypeIsInherited: boolean;
	workspaceId: string;
	objectTypeId: string;
}): Promise<SearchObjectTypesResponse | null> => {
	if (!objectTypeIsInherited) return null;
	const objectTypes: SearchObjectTypesResponse = await performPostRequest(
		getCmdbObjectTypeAttributeFilterUrl(workspaceId, objectTypeId),
		{
			body: JSON.stringify({
				// we don't use these params as the filtering is handled elsewhere
				// they are required for the endpoint call
				query: '',
				exceptionList: [],
			}),
		},
	);
	return objectTypes;
};

export const filterIsUsingObjectTypeSelection = (
	filters: FilterResponse[],
	entries: ObjectFilterEntry[],
): ValueMatch<string>[] => {
	const filterData: FilterResponse[] = filters.filter((item) =>
		item?.filterByObjectType ? item : null,
	);

	if (Array.isArray(filterData) && filterData.length > 0) {
		return filterData[0].selectedValues.map((item) => {
			// we need to check if the current selection values exist in the selectedValues column
			// this is because the backend alters the AQL statement for objectType queries to use IN rather than LIKE
			const matchedEntry = entries.map((entryItem) => entryItem.objectTypeName);
			const itemDoesNotExistInList = matchedEntry.indexOf(item) < 0;
			const wildCardValue = extractWildcard(item);
			const isWildCardEntry = wildCardValue.isWildcard || itemDoesNotExistInList;
			const type = isWildCardEntry ? 'wildcard' : 'exact-match';
			const value = isWildCardEntry ? wildCardValue.value : item;
			return {
				type,
				value,
			};
		});
	}

	return [];
};

export const formatSelectedObjectTypesForResponse = ({
	filters,
	objectTypesForFiltering = null,
}: {
	filters: FilterResponse[] | null;
	objectTypesForFiltering: SearchObjectTypesResponse | null;
}): SearchResultObjectType | null => {
	if (!objectTypesForFiltering) return null;

	if (!filters)
		return {
			...objectTypesForFiltering,
			selectedValues: [],
		};

	const selectedValues: ValueMatch<string>[] = filterIsUsingObjectTypeSelection(
		filters,
		objectTypesForFiltering.entries,
	);
	return {
		...objectTypesForFiltering,
		selectedValues,
	};
};
