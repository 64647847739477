import { changePagination } from './change-pagination';
import {
	executeSearch,
	refreshSearch,
	updateSearchFilter,
	updateSearchIql,
} from './execute-search';
import { fetchUncappedCount } from './fetch-uncapped-count';
import {
	failUpdateObjectFilterExperience,
	startUpdateObjectFilterExperience,
	triggerSuccessfulObjectFilterExperience,
} from './set-object-filter-experience';
import { setSearchMode } from './set-search-mode';
import { setSelectedColumns } from './set-selected-columns';
import { updateOrderBy } from './update-order-by';

export const actions = {
	executeSearch,
	updateSearchIql,
	updateSearchFilter,
	updateOrderBy,
	refreshSearch,
	setSelectedColumns,
	setSearchMode,
	changePagination,
	triggerSuccessfulObjectFilterExperience,
	startUpdateObjectFilterExperience,
	failUpdateObjectFilterExperience,
	fetchUncappedCount,
} as const;

export type Actions = typeof actions;
