import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	listView: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-details.controls.list-view',
		defaultMessage: 'List view',
		description: 'Label for a button that takes the user to the list view when clicked',
	},
	loading: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-details.controls.loading',
		defaultMessage: 'Loading',
		description: 'Label to display when object list or filter is change',
	},
});
