import { defineMessages } from '@atlassian/jira-intl';

export const commonMessages = defineMessages({
	addObjectType: {
		id: 'servicedesk-insight-object-schema-page.common.add-object-type',
		defaultMessage: 'Add object type',
		description: 'The label for a button, that when clicked will add an object type',
	},
	updateObjectTypeErrorTitle: {
		id: 'servicedesk-insight-object-schema-page.common.update-object-type-error-title',
		defaultMessage: "We couldn't update your object type",
		description: 'Error message shown when updating an object type fails',
	},
	updateObjectTypeErrorDescription: {
		id: 'servicedesk-insight-object-schema-page.common.update-object-type-error-description',
		defaultMessage: 'Please check your network and try again.',
		description: 'Error message shown when updating an object type fails',
	},
	disabledPaginationNextButton: {
		id: 'servicedesk-insight-object-schema-page.common.disabled-pagination-next-button',
		defaultMessage: 'We can’t load more pages. Refine your filters to see more objects.',
		description: 'Tooltip shown when last page is reached and the "Next page" button is disabled',
	},
});
