import React from 'react';
import { Section, SkeletonItem, SkeletonHeadingItem } from '@atlaskit/side-navigation';

export const LoadingSidebarHeader = () => (
	<>
		<SkeletonHeadingItem isShimmering />
		<Section>
			<SkeletonItem isShimmering hasIcon />
		</Section>
		<Section>
			<SkeletonItem isShimmering hasIcon />
		</Section>
	</>
);

const objectTypeSkeletonItem = (
	// @ts-expect-error - TS2322 - Type '() => string' is not assignable to type 'CSSFn<void>'.
	// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
	<SkeletonItem cssFn={() => 'margin: 8px 0 8px 24px;'} isShimmering hasIcon />
);

export const LoadingSidebarContent = () => (
	<Section>
		{objectTypeSkeletonItem}
		{objectTypeSkeletonItem}
		{objectTypeSkeletonItem}
		{objectTypeSkeletonItem}
		{objectTypeSkeletonItem}
		{objectTypeSkeletonItem}
		{objectTypeSkeletonItem}
	</Section>
);
