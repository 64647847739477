import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import type {
	InsightObjectAttribute,
	DefaultAttribute,
	ObjectAttribute,
	UserAttribute,
	GroupAttribute,
	StatusAttribute,
	ProjectAttribute,
	BitbucketRepoAttribute,
	OpsgenieTeamAttribute,
} from '../../types/object-attribute';
import type {
	InsightObjectAttributeType,
	DefaultAttributeType,
	ObjectAttributeType,
	UserAttributeType,
	GroupAttributeType,
	StatusAttributeType,
	ProjectAttributeType,
	BitbucketRepoAttributeType,
	AttributeType,
	OpsgenieTeamAttributeType,
} from '../../types/object-attribute-type';

export const defaultTypesById = {
	0: 'text',
	1: 'integer',
	2: 'boolean',
	3: 'float',
	4: 'date',
	6: 'datetime',
	7: 'url',
	8: 'email',
	9: 'textarea',
	10: 'select',
	11: 'ip address',
} as const;

export const matchAttributeType = <T,>(
	attributeType: AttributeType,
	handlers: {
		default: () => T;
		object: () => T;
		user: () => T;
		group: () => T;
		project: () => T;
		status: () => T;
		bitbucketRepo: () => T;
		opsgenieTeam: () => T;
	},
): T => {
	switch (attributeType) {
		case 'default':
			return handlers.default();
		case 'object':
			return handlers.object();
		case 'user':
			return handlers.user();
		case 'group':
			return handlers.group();
		case 'project':
			return handlers.project();
		case 'status':
			return handlers.status();
		case 'bitbucketRepo':
			return handlers.bitbucketRepo();
		case 'opsgenieTeam':
			return handlers.opsgenieTeam();
		default: {
			const error = new Error(`Unknown attribute type: ${attributeType}`);
			fireErrorAnalytics({
				meta: {
					id: 'type-matcher.invalid.attribute.type',
					packageName: 'jiraServicedeskInsightObjectTypes',
					teamName: 'falcons',
				},
				error,
			});
			throw error;
		}
	}
};

export const matchInsightObjectAttributeType = <T,>(
	attributeType: InsightObjectAttributeType,
	handlers: {
		default: (defaultAT: DefaultAttributeType) => T;
		object: (objectAT: ObjectAttributeType) => T;
		user: (userAT: UserAttributeType) => T;
		group: (groupAT: GroupAttributeType) => T;
		project: (groupAT: ProjectAttributeType) => T;
		status: (statusAT: StatusAttributeType) => T;
		bitbucketRepo: (bitbucketRepoAT: BitbucketRepoAttributeType) => T;
		opsgenieTeam: (opsgenieTeamAT: OpsgenieTeamAttributeType) => T;
	},
): T => {
	switch (attributeType.type) {
		case 'default':
			return handlers.default(attributeType);
		case 'object':
			return handlers.object(attributeType);
		case 'user':
			return handlers.user(attributeType);
		case 'group':
			return handlers.group(attributeType);
		case 'project':
			return handlers.project(attributeType);
		case 'status':
			return handlers.status(attributeType);
		case 'bitbucketRepo':
			return handlers.bitbucketRepo(attributeType);
		case 'opsgenieTeam':
			return handlers.opsgenieTeam(attributeType);
		default: {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			const error = new Error(`Unknown attribute type: ${(attributeType as any).type}`);
			fireErrorAnalytics({
				meta: {
					id: 'type-matcher.invalid.insight.object.attribute.type',
					packageName: 'jiraServicedeskInsightObjectTypes',
					teamName: 'falcons',
				},
				error,
			});
			throw error;
		}
	}
};

export const matchInsightObjectAttribute = <T,>(
	attribute: InsightObjectAttribute,
	handlers: {
		default: (defaultAttribute: DefaultAttribute) => T;
		object: (objectAttribute: ObjectAttribute) => T;
		user: (userAttribute: UserAttribute) => T;
		group: (groupAttribute: GroupAttribute) => T;
		project: (projectAttribute: ProjectAttribute) => T;
		status: (statusAttribute: StatusAttribute) => T;
		bitbucketRepo: (bitbucketRepoAttribute: BitbucketRepoAttribute) => T;
		opsgenieTeam: (opsgenieTeamAT: OpsgenieTeamAttribute) => T;
	},
): T => {
	switch (attribute.type) {
		case 'default':
			return handlers.default(attribute);
		case 'object':
			return handlers.object(attribute);
		case 'user':
			return handlers.user(attribute);
		case 'group':
			return handlers.group(attribute);
		case 'project':
			return handlers.project(attribute);
		case 'status':
			return handlers.status(attribute);
		case 'bitbucketRepo':
			return handlers.bitbucketRepo(attribute);
		case 'opsgenieTeam':
			return handlers.opsgenieTeam(attribute);
		default: {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			const error = new Error(`Unknown attribute type: ${(attribute as any).type}`);
			fireErrorAnalytics({
				meta: {
					id: 'type-matcher.invalid.insight.object.attribute',
					packageName: 'jiraServicedeskInsightObjectTypes',
					teamName: 'falcons',
				},
				error,
			});
			throw error;
		}
	}
};

export const matchDefaultAttributeSubType = <T,>(
	defaultType: DefaultAttributeType['defaultType'],
	handlers: {
		url: () => T;
		email: () => T;
		float: () => T;
		integer: () => T;
		ipAddress: () => T;
		text: () => T;
		textarea: () => T;
		select: () => T;
		boolean: () => T;
		date: () => T;
		datetime: () => T;
	},
): T => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const defaultSubType = defaultTypesById[defaultType.id as keyof typeof defaultTypesById];
	switch (defaultSubType) {
		case 'url':
			return handlers.url();
		case 'email':
			return handlers.email();
		case 'float':
			return handlers.float();
		case 'integer':
			return handlers.integer();
		case 'ip address':
			return handlers.ipAddress();
		case 'text':
			return handlers.text();
		case 'textarea':
			return handlers.textarea();
		case 'select':
			return handlers.select();
		case 'boolean':
			return handlers.boolean();
		case 'date':
			return handlers.date();
		case 'datetime':
			return handlers.datetime();
		default: {
			const error = new Error(`Unknown default attribute type id: ${defaultType.id}`);
			fireErrorAnalytics({
				meta: {
					id: 'type-matcher.invalid.default..attribute.subtype',
					packageName: 'jiraServicedeskInsightObjectTypes',
					teamName: 'falcons',
				},
				error,
			});
			throw error;
		}
	}
};

const firstItemOrNull = <T,>(values: T[]): T | null => {
	const [first = null] = values;
	return first;
};

const filterEmptyOrNullValues = <T,>(values: T[]): T[] =>
	values.filter((value) => value != null && value !== '');

export const matchDefaultAttribute = <T,>(
	defaultAttribute: DefaultAttribute,
	handlers: {
		url: (value: string[]) => T;
		email: (value: string[]) => T;
		float: (value: string | null) => T;
		integer: (value: string | null) => T;
		ipAddress: (value: string | null) => T;
		text: (value: string | null) => T;
		textarea: (value: string | null) => T;
		select: (value: string[]) => T;
		boolean: (value: boolean | null) => T;
		date: (value: string | null) => T;
		datetime: (value: string | null) => T;
	},
): T => {
	const values = defaultAttribute.defaultTypeValues.map(({ value }) => value);
	const nonNullValues = filterEmptyOrNullValues(values);
	const firstValue = firstItemOrNull(values);

	return matchDefaultAttributeSubType(defaultAttribute.objectTypeAttribute.defaultType, {
		url: () => handlers.url(nonNullValues),
		email: () => handlers.email(nonNullValues),
		float: () => handlers.float(firstValue),
		integer: () => handlers.integer(firstValue),
		ipAddress: () => handlers.ipAddress(firstValue),
		text: () => handlers.text(firstValue),
		textarea: () => handlers.textarea(firstValue),
		select: () => handlers.select(values),
		boolean: () => handlers.boolean(firstValue !== null ? firstValue === 'true' : null),
		date: () => handlers.date(firstValue),
		datetime: () => handlers.datetime(firstValue),
	});
};
