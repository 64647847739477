import React, { useEffect } from 'react';
import EmptyState from '@atlaskit/empty-state';
import { getStatusCodeGroup } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import { fg } from '@atlassian/jira-feature-gating';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations';
import ErrorIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/error';
import { useIntl } from '@atlassian/jira-intl';
import {
	loadSchemaObjectListExperience,
	objectDetailsViewExperience,
} from '@atlassian/jira-servicedesk-insight-experiences';
import { useSchemaPageUiState } from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import { isUnexpectedFetchError, isSignal } from '@atlassian/jira-servicedesk-insight-shared-utils';
import { GlobalPageLoadExperience } from '@atlassian/ufo';
import { messages } from './messages';

type Props = {
	error: Error;
};

const ErrorContent = () => {
	const { formatMessage } = useIntl();

	const renderImage = () =>
		getAkEmptyStateRenderImageFn(ErrorIllustration)({
			imageWidth: 164,
			maxImageWidth: 164,
			maxImageHeight: 212,
		});

	return (
		<EmptyState
			size="wide"
			renderImage={renderImage}
			header={formatMessage(messages.errorTitle)}
			description={formatMessage(messages.errorDescription)}
		/>
	);
};

export const LoadObjectsError = ({ error }: Props) => {
	const [{ schemaViewMode, objectViewMode }] = useSchemaPageUiState();
	const statusCodeGroup = getStatusCodeGroup(error);

	// Only fire experience failure for 5xx errors
	const is5xx = fg('assets_fire_experience_failure_only_on_5xx')
		? isUnexpectedFetchError(error, ['5xx'])
		: statusCodeGroup === '5xx';

	useEffect(() => {
		GlobalPageLoadExperience.failure({ force: true });
	});

	useEffect(() => {
		if (schemaViewMode === 'object' && objectViewMode === 'list') {
			if (fg('fly-2232_improve_ttd')) {
				isSignal(error, ['5xx'])
					? loadSchemaObjectListExperience.failure()
					: loadSchemaObjectListExperience.abort();
			} else {
				is5xx ? loadSchemaObjectListExperience.failure() : loadSchemaObjectListExperience.abort();
			}
		}

		if (schemaViewMode === 'object' && objectViewMode === 'details') {
			if (fg('fly-2232_improve_ttd')) {
				isSignal(error, ['5xx'])
					? objectDetailsViewExperience.failure()
					: objectDetailsViewExperience.abort();
			} else {
				is5xx ? objectDetailsViewExperience.failure() : objectDetailsViewExperience.abort();
			}
		}
	}, [schemaViewMode, objectViewMode, is5xx, error]);

	return <ErrorContent />;
};
