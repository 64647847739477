import { getStatusCodeGroup } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics';
import { FetchError } from '@atlassian/jira-fetch';

export type ErrorStatusCodeGroups = ReturnType<typeof getStatusCodeGroup>[];

export const EXCLUDED_SIGNAL_STATUS_CODES = [503];

export const isUnexpectedFetchError = (
	error: Error | undefined,
	errorStatusCodeGroups: ErrorStatusCodeGroups = ['5xx'],
	excludeStatusCodes?: number[],
) => {
	if (!error || !(error instanceof FetchError)) return false;
	// If excludeStatusCodes defined, check first before proceeding to check group
	if (excludeStatusCodes && excludeStatusCodes.length) {
		const { statusCode } = error;
		if (excludeStatusCodes.includes(statusCode)) return false;
	}
	const statusCodeGroup = getStatusCodeGroup(error);
	return errorStatusCodeGroups.includes(statusCodeGroup);
};

export const isSignal = (
	error: Error | undefined,
	errorStatusCodeGroups: ErrorStatusCodeGroups = ['4xx', '5xx'],
) => isUnexpectedFetchError(error, errorStatusCodeGroups, EXCLUDED_SIGNAL_STATUS_CODES);
