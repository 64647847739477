import { createContext, useCallback, useMemo, useContext } from 'react';
// @ts-expect-error - TS2305 - Module '"react-resource-router"' has no exported member 'HistoryUpdateType'.
import type { HistoryUpdateType } from '@atlassian/jira-router';
import type {
	CmdbObjectId,
	CmdbObjectTypeId,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import {
	type ObjectViewMode,
	type SchemaViewMode,
	useObjectIdQueryParam,
	useObjectTypeIdQueryParam,
	useObjectViewModeQueryParam,
	useSchemaViewModeQueryParam,
} from '@atlassian/jira-servicedesk-insight-urls';
import type { QueryParams, UrlParams } from '../../common/types';

const UrlParamContext = createContext<UrlParams | null>(null);

export const UrlParamContextProvider = UrlParamContext.Provider;
export const useUrlParamContext = () => {
	const contextValue = useContext(UrlParamContext);
	if (contextValue === null) {
		throw new Error('useUrlParamContext call without UrlParamContextProvider value provided');
	}
	return contextValue;
};

export const useInsightObjectSchemaQueryParams = (): QueryParams => {
	const [objectId, setObjectIdParam] = useObjectIdQueryParam();
	const [objectTypeId, setObjectTypeIdParam] = useObjectTypeIdQueryParam();
	const [schemaViewMode, setSchemaViewModeParam] = useSchemaViewModeQueryParam();
	const [objectViewMode, setObjectViewModeParam] = useObjectViewModeQueryParam();

	const setObjectId = useCallback(
		(newObjectId: CmdbObjectId | null, historyUpdateType?: HistoryUpdateType) =>
			setObjectIdParam(newObjectId, historyUpdateType),
		[setObjectIdParam],
	);

	const setObjectTypeId = useCallback(
		(newObjectTypeId: CmdbObjectTypeId | null, historyUpdateType?: HistoryUpdateType) =>
			setObjectTypeIdParam(newObjectTypeId, historyUpdateType),
		[setObjectTypeIdParam],
	);
	// Changes to schema and object view modes should always add a new history entry.
	const setSchemaViewMode = useCallback(
		(newSchemaViewMode: SchemaViewMode) => setSchemaViewModeParam(newSchemaViewMode, 'push'),
		[setSchemaViewModeParam],
	);
	const setObjectViewMode = useCallback(
		(newObjectViewMode: ObjectViewMode) => setObjectViewModeParam(newObjectViewMode, 'push'),
		[setObjectViewModeParam],
	);

	return useMemo<QueryParams>(
		() => ({
			objectId,
			setObjectId,
			objectTypeId,
			setObjectTypeId,
			schemaViewMode,
			setSchemaViewMode,
			objectViewMode,
			setObjectViewMode,
		}),
		[
			objectId,
			setObjectId,
			objectTypeId,
			setObjectTypeId,
			schemaViewMode,
			setSchemaViewMode,
			objectViewMode,
			setObjectViewMode,
		],
	);
};
