import React, { type ReactNode, useState, useEffect } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { JsmPageInteractiveEvent } from '@atlassian/jira-servicedesk-common';
import { useWorkspaceContext } from '@atlassian/jira-servicedesk-insight-common-workspace-context';
import { objectDetailsViewExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import { ObjectDetailsRenderingContext } from '@atlassian/jira-servicedesk-insight-object-details';
import { useSchemaPageUiState } from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import {
	type SearchResultObject,
	matchSearchState,
	type SearchStateValue,
	useObjectSearchStoreState,
	getPendingSearchType,
} from '@atlassian/jira-servicedesk-insight-object-search-store';
import { COLUMN_GUTTERS } from '@atlassian/jira-servicedesk-insight-object-view-layout/src/ui';
import {
	InsightObjectSchemaCardSkeleton,
	InsightObjectViewSkeleton,
} from '@atlassian/jira-skeletons';
import { ExperienceSuccess } from '@atlassian/ufo';
import { EmbeddedObjectView } from '../../../../../common/ui/embedded-object-view';
import { LoadObjectsError } from '../../../../../common/ui/load-objects-error';
import { NoFilterResults } from '../../../../../common/ui/no-filter-results';
import { ObjectDetailsSkeleton } from '../../../../../common/ui/object-details-skeleton';
import { Controls } from './controls';
import { ObjectCards } from './object-cards';
import { ObjectIdQueryParamSync } from './object-id-sync';
import { Pagination } from './pagination';

export const ObjectDetails = () => {
	const [{ objectId }] = useSchemaPageUiState();
	const [searchStoreState, { refreshSearch }] = useObjectSearchStoreState();
	const { lastSearch } = searchStoreState;
	const { workspaceId } = useWorkspaceContext();
	const [triggerSuccessEvent, setTriggerSuccessEvent] = useState<boolean>(false);
	const pendingSearchType = getPendingSearchType(searchStoreState);
	const isSearching = pendingSearchType !== null;

	useEffect(() => {
		if (!isSearching) {
			setTriggerSuccessEvent(true);
		}
	}, [isSearching]);

	if (!workspaceId) {
		return null;
	}

	const renderSidePanel = (objects: SearchResultObject[], isLoading: boolean) => {
		if (objects.length > 0) {
			return (
				<>
					{isLoading ? (
						<InsightObjectSchemaCardSkeleton />
					) : (
						<ObjectCardsContainer>
							<ExperienceSuccess experience={objectDetailsViewExperience} />
							<ObjectCards objects={objects} />
						</ObjectCardsContainer>
					)}
					<Pagination />
				</>
			);
		}

		return <NoFilterResults imageWidth="medium" showText />;
	};

	return matchSearchState(lastSearch, {
		loading: () => <ObjectDetailsSkeleton startExperience />,
		error: (error) => <LoadObjectsError error={error} />,
		success: ({ result: { objects } }: SearchStateValue) => {
			const selectedObjectView =
				objectId !== null ? (
					<EmbeddedObjectView
						context={ObjectDetailsRenderingContext.Component}
						objectId={objectId}
						workspaceId={workspaceId}
						onUpdate={refreshSearch}
						onDelete={refreshSearch}
						onClone={refreshSearch}
						redirectOnDelete={false}
						showFullBreadcrumbTrail={false}
					/>
				) : (
					<InsightObjectViewSkeleton />
				);

			return (
				<Container data-testid="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-details.object-view-content">
					<FireUiAnalytics
						actionSubject="page"
						action="viewed"
						actionSubjectId="insightObjectsPage"
					/>
					{triggerSuccessEvent && (
						<JsmPageInteractiveEvent
							extra={{ schemaViewMode: 'object', eventMonitorMode: 'detail' }}
						/>
					)}
					<ObjectIdQueryParamSync objects={objects} />

					<JSErrorBoundary
						id="ObjectDetails"
						packageName="JiraServiceDeskInsightObjectSchemaPage"
						fallback="flag"
					>
						<SidePanel>
							<Controls isLoading={pendingSearchType === 'refresh'} />
							{renderSidePanel(objects, pendingSearchType === 'search')}
						</SidePanel>
					</JSErrorBoundary>

					<ObjectViewContainer>
						{objects.length > 0 ? (
							selectedObjectView
						) : (
							<NoResultsContainer>
								<NoFilterResults imageWidth="large" showText={false} />
							</NoResultsContainer>
						)}
					</ObjectViewContainer>
				</Container>
			);
		},
	});
};

const NoResultsContainer = ({ children }: { children?: ReactNode }) => (
	<Flex
		justifyContent="center"
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		xcss={xcss({
			marginTop: 'space.400',
		})}
	>
		{children}
	</Flex>
);

const SIDE_PANEL_WIDTH = 240;

export const SidePanel = ({ children }: { children?: ReactNode }) => (
	<Flex direction="column" xcss={sidePanelStyles}>
		{children}
	</Flex>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ObjectViewContainer = styled2.div({
	flex: 1,
	overflowY: 'auto',
	/* The following margin/padding that cancel each other out allows for up to
    'gridSize' to bleed outside width of this container. Useful for things like
    box-shadows around components near the edge of this container. */
	marginTop: 0,
	marginBottom: 0,
	marginLeft: token('space.negative.100', '-8px'),
	marginRight: token('space.negative.100', '-8px'),
	padding: `0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	flex: 1,
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	gap: `${COLUMN_GUTTERS}px`,
	minHeight: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ObjectCardsContainer = styled2.div({
	flex: 1,
	overflowY: 'auto',
	margin: `${token('space.100', '8px')} 0 ${token('space.050', '4px')} 0`,
});

const sidePanelStyles = xcss({
	width: `${SIDE_PANEL_WIDTH}px`,
	backgroundColor: 'elevation.surface.sunken',

	borderRadius: 'border.radius',
	paddingTop: 'space.100',
	paddingRight: 'space.100',
	paddingBottom: 'space.050',
	paddingLeft: 'space.100',
	marginTop: 'space.0',
	marginRight: 'space.0',
	marginBottom: 'space.100',
	marginLeft: 'space.0',
});
