import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	emptyStateTitle: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.no-objects.empty-state-title',
		defaultMessage: 'There are no objects to display',
		description: 'Informs the user that there are no objects',
	},
	noAccessText: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.no-objects.no-access-text',
		defaultMessage:
			'To create objects your admin needs to grant you {linkStart}create objects permission{linkEnd}',
		description: 'Admin needs to grant create objects permission first',
	},
	abstractTypeTitle: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.no-objects.abstract-type-title',
		defaultMessage: 'This object type is abstract and cannot contain objects.',
		description: 'Header informing the user that the object type is set to abstract',
	},
	abstractTypeText: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.no-objects.abstract-type-text',
		defaultMessage: 'Learn more about the different kinds of object type.',
		description:
			'Message asking the user to learn about different object types by following the provided link',
	},
	createAnObject: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.no-objects.create-an-object',
		defaultMessage: 'Create an object',
		description: 'Label for a button to create a new object',
	},
});
