import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { CmdbObjectTypeId } from '@atlassian/jira-servicedesk-insight-shared-types';
import { EditableIcon } from './editable-icon';
import { EditableName } from './editable-name';

export const EditableObjectType = ({ objectTypeId }: { objectTypeId: CmdbObjectTypeId }) => (
	<Container>
		<EditableIcon objectTypeId={objectTypeId} />
		<EditableName objectTypeId={objectTypeId} />
	</Container>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100', '8px'),
	flexGrow: 1,
});
