import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { ObjectTableProps } from './common/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyObjectTable = lazy(() => import(/* webpackChunkName: "insight-object-table" */ './ui'));

export const AsyncObjectTable = (props: ObjectTableProps) => (
	<Placeholder name="insight-object-table" fallback={null}>
		<LazyObjectTable {...props} />
	</Placeholder>
);
