import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	showConfiguration: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.show-configuration',
		defaultMessage: 'Show configuration',
		description: 'Label for a button that shows configuration when clicked',
	},
	showObjects: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.show-objects',
		defaultMessage: 'Show objects',
		description: 'Label for a button that shows objects when clicked',
	},
	createObject: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.create-object',
		defaultMessage: 'Create object',
		description: 'Label for a button to create an object when clicked on',
	},
	more: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.more',
		defaultMessage: 'More',
		description: 'Label for a button that shows more actions when clicked',
	},
	objectTypeConfiguration: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.object-type-configuration',
		defaultMessage: 'Configure object type',
		description: 'Label for a link to an object type configuration',
	},
	copyObjectType: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.copy-object-type',
		defaultMessage: 'Copy object type',
		description: 'Label for a button that creates a copy of an object type',
	},
	viewImportLogs: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.view-import-logs',
		defaultMessage: 'View import logs',
		description: 'Label for a button to an object type import logs',
	},
	importHistory: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.import-history',
		defaultMessage: 'Import history',
		description: 'Header for import history modal',
	},
});
