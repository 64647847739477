import { stringify as qsStringify } from 'query-string';
import type { SchemaId, WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types';
import type { GlobalConfigObjectRequest } from '../types/global';
import { getCmdbRestBaseUrl } from './index';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/global
 */
const getCmdbGlobalUrl = (workspaceId: WorkspaceId) => `${getCmdbRestBaseUrl(workspaceId)}/global`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/global/config
 */
export const getCmdbGlobalConfigUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbGlobalUrl(workspaceId)}/config`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/global/config/object
 */
export const getCmdbGlobalConfigObjectUrl = (
	workspaceId: WorkspaceId,
	queryParams: GlobalConfigObjectRequest,
) => `${getCmdbGlobalUrl(workspaceId)}/config/object?${qsStringify(queryParams)}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/global/config/objectschema/${objectSchemaId}
 */
export const getCmdbGlobalConfigObjectSchemaByIdUrl = (
	workspaceId: WorkspaceId,
	objectSchemaId: SchemaId,
) => `${getCmdbGlobalUrl(workspaceId)}/config/objectschema/${objectSchemaId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/global/config/objectschema/${objectSchemaId}/property
 */
export const getCmdbGlobalConfigObjectSchemaByIdPropertyUrl = (
	workspaceId: WorkspaceId,
	objectSchemaId: SchemaId,
) => `${getCmdbGlobalUrl(workspaceId)}/config/objectschema/${objectSchemaId}/property`;
