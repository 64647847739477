import { showTaskFlags } from './show-task-flags';
import { startTaskPolling } from './start-task-polling';
import { submitAsyncObjectExportTask } from './submit-async-object-export-task';

export const actions = {
	showTaskFlags,
	startTaskPolling,
	submitAsyncObjectExportTask,
} as const;

export type Actions = typeof actions;
