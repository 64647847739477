import { NONE } from '../../../constants';
import { determineObjectUsageInfo } from '../../../index';
import type { ObjectUsageLocation, State, ObjectUsageInfo } from '../../../types';

export const getObjectUsageInfo = (
	state: State,
	location: ObjectUsageLocation,
): ObjectUsageInfo => {
	const { objectCount, objectLimit, limitState, userRole, billingPeriod, isDismissed } = state;

	if (limitState === 'disabled') {
		return NONE;
	}
	if (isDismissed && location !== 'import-data-modal') {
		return NONE;
	}

	return determineObjectUsageInfo({
		isJiraAdmin: userRole === 'admin',
		objectCount,
		objectLimit,
		billingPeriod,
		location,
		limitState,
	});
};
