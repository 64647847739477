import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type { CopyObjectTypeModalProps } from './ui/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCopyObjectTypeModal = lazy(
	() => import(/* webpackChunkName: "insight-copy-object-type-modal" */ './ui'),
);

export const AsyncCopyObjectTypeModal = (props: CopyObjectTypeModalProps) => (
	<Placeholder name="insight-copy-object-type-modal" fallback={null}>
		<LazyCopyObjectTypeModal {...props} />
	</Placeholder>
);
