import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ActionApi } from '../../common/types';

export const openCreateObject =
	(analyticsEvent?: UIAnalyticsEvent): ActionApi =>
	async ({ setState }) => {
		if (analyticsEvent) {
			fireUIAnalytics(analyticsEvent, 'openCreateInsightObjectDialog');
		}
		setState({
			isCreateObjectOpen: true,
		});
	};

export const closeCreateObject =
	(): ActionApi =>
	async ({ setState }) => {
		setState({
			isCreateObjectOpen: false,
		});
	};
