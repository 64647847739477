import type { Task } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';

export const mergeTasks = (existingTasks: Task[] | undefined, newTasks: Task[] | undefined) => {
	const taskMap = new Map<string, Task>();
	(existingTasks ?? []).forEach((item) => taskMap.set(item.identifier, item));
	(newTasks ?? []).forEach((item) =>
		taskMap.set(item.identifier, { ...taskMap.get(item.identifier), ...item }),
	);
	return Array.from(taskMap.values());
};
