import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { token } from '@atlaskit/tokens';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message';
import { useIntl } from '@atlassian/jira-intl';
import { useRouterActions } from '@atlassian/jira-router';
import { GenericEmptyStateImage } from '@atlassian/jira-servicedesk-insight-glyphs';
import {
	useInsightObjectSchemaPageData,
	canCreateObject,
	useLoadedSchemaPageData,
	isAbstractObjectType,
	useSchemaPageUiState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import { getCreateServiceUrl } from '@atlassian/jira-servicedesk-insight-urls';
import { ROLE_PERMISSION_LINK, WHAT_ARE_OBJECT_TYPES_LINK } from './constants';
import { messages } from './messages';

const Link = ({ children }: { children: ReactNode }) => (
	<NoWrapLink href={ROLE_PERMISSION_LINK} target="_blank">
		{children}
	</NoWrapLink>
);

export const NoObjects = () => {
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();
	const [, { openCreateObject }] = useInsightObjectSchemaPageData();
	const [{ selectedObjectTypeId }] = useSchemaPageUiState();
	const [schemaPageData] = useLoadedSchemaPageData();

	const canCreate =
		(selectedObjectTypeId !== null && canCreateObject(schemaPageData, selectedObjectTypeId)) ||
		schemaPageData.objectSchema.isRestricted;
	const isAbstract =
		selectedObjectTypeId !== null && isAbstractObjectType(schemaPageData, selectedObjectTypeId);

	const description = isAbstract ? (
		<>
			<DescriptionHeader>{formatMessage(messages.abstractTypeTitle)}</DescriptionHeader>
			<NoWrapLink href={WHAT_ARE_OBJECT_TYPES_LINK} target="_blank">
				{formatMessage(messages.abstractTypeText)}
			</NoWrapLink>
		</>
	) : (
		<>
			<DescriptionHeader>{formatMessage(messages.emptyStateTitle)}</DescriptionHeader>
			{!canCreate && (
				<FormattedI18nMessage
					message={formatMessage(messages.noAccessText, {
						linkStart: '{linkStart}',
						linkEnd: '{linkEnd}',
					})}
					componentsMapping={{
						link: Link,
					}}
				/>
			)}
		</>
	);

	return (
		<EmptyState
			width="wide"
			imageUrl={GenericEmptyStateImage}
			header=""
			description={description}
			primaryAction={
				canCreate && (
					<Button
						testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.no-objects.button"
						onClick={(e, analyticsEvent) => {
							if (!schemaPageData.objectSchema.isRestricted) {
								openCreateObject(analyticsEvent);
							} else {
								push(getCreateServiceUrl());
							}
						}}
						appearance="link"
					>
						{formatMessage(messages.createAnObject)}
					</Button>
				)
			}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoWrapLink = styled.a({
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionHeader = styled.span({
	display: 'block',
	marginBottom: token('space.100', '8px'),
});
