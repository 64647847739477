import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequest } from '@atlassian/jira-fetch';
import {
	getCmdbImportHistoryObjectTypeByIdUrl,
	getCmdbImportHistoryDataObjectTypeByIdUrl,
} from '@atlassian/jira-servicedesk-cmdb-rest-urls';
import type {
	WorkspaceId,
	CmdbObjectTypeId,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import type {
	SimpleImportResponse,
	ImportHistoryDetails,
	ImportHistoryData,
} from '../../common/types';

export const sortByEndDate = (imports: Array<ImportHistoryDetails>) =>
	imports.sort((a, b) => new Date(b.ended).getTime() - new Date(a.ended).getTime());

export const sortByStartDate = (imports: Array<ImportHistoryDetails>) =>
	imports.sort((a, b) => new Date(b.started).getTime() - new Date(a.started).getTime());

export const fetchImportResults = async (
	workspaceId: WorkspaceId | undefined,
	objectTypeId: CmdbObjectTypeId | null,
) => {
	try {
		if (workspaceId && objectTypeId) {
			const data: SimpleImportResponse = await performGetRequest(
				getCmdbImportHistoryObjectTypeByIdUrl(workspaceId, objectTypeId),
			);

			if (data.length === 0) {
				return {
					inFlight: [],
					finished: [],
				};
			}
			const importNameMap: { [id: string]: string } = {};
			data.forEach((d) => {
				importNameMap[d.id] = d.name;
			});

			const imports: ImportHistoryData = await performGetRequest(
				`${getCmdbImportHistoryDataObjectTypeByIdUrl(
					workspaceId,
					objectTypeId,
					data.map((d) => d.id),
				)}`,
			);

			const inFlight: ImportHistoryDetails[] =
				imports.inFlight?.map((i: ImportHistoryDetails) => ({
					...i,
					importName: importNameMap[i.importSourceId],
				})) ?? [];

			const finished: ImportHistoryDetails[] =
				imports.finished?.map((i: ImportHistoryDetails) => ({
					...i,
					importName: importNameMap[i.importSourceId],
				})) ?? [];

			return {
				inFlight: sortByStartDate(inFlight),
				finished: sortByEndDate(finished),
			};
		}
		return {
			inFlight: [],
			finished: [],
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'insightObjectImportLogModal',
				packageName: 'jiraServicedeskInsightObjectImportLogModal',
				teamName: 'falcons',
			},
			error,
		});
		throw error;
	}
};
