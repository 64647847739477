import React, { type ComponentType } from 'react';
import { NavigationContent, SideNavigation as AkSideNavigation } from '@atlaskit/side-navigation';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { useIntl } from '@atlassian/jira-intl';
import {
	matchPageDataState,
	useInsightObjectSchemaPageData,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import { ErrorSidebarContent, ErrorSidebarHeader } from './error';
import { SidebarContent, SidebarHeader } from './loaded';
import { LoadingSidebarContent, LoadingSidebarHeader } from './loading';
import { messages } from './messages';
import { ObjectTypeTreeStateProvider } from './services';

const Layout = ({
	HeaderComponent,
	ContentComponent,
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderComponent: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ContentComponent: ComponentType<Record<any, any>>;
}) => {
	const { formatMessage } = useIntl();
	return (
		<AkSideNavigation label={formatMessage(messages.sidebarLabel)}>
			<NavigationContent testId="servicedesk-insight-object-schema-page.common.ui.side-navigation.navigation-content">
				<HeaderComponent />
				<ContentComponent />
			</NavigationContent>
		</AkSideNavigation>
	);
};

const LayoutWithErrorBoundary = ({
	HeaderComponent,
	ContentComponent,
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderComponent: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ContentComponent: ComponentType<Record<any, any>>;
}) => (
	<JSErrorBoundary
		id="JsmCmdbSchemaSideNavigation"
		packageName="jiraServicedeskInsightObjectSchemaPage"
		teamName="falcons"
	>
		<Layout HeaderComponent={HeaderComponent} ContentComponent={ContentComponent} />
	</JSErrorBoundary>
);

export const ErrorSideNavigationWithErrorBoundary = () => (
	<LayoutWithErrorBoundary
		HeaderComponent={ErrorSidebarHeader}
		ContentComponent={ErrorSidebarContent}
	/>
);

export const ErrorSideNavigation = () => (
	<Layout HeaderComponent={ErrorSidebarHeader} ContentComponent={ErrorSidebarContent} />
);

export const LoadingSideNavigationWithErrorBoundary = () => (
	<LayoutWithErrorBoundary
		HeaderComponent={LoadingSidebarHeader}
		ContentComponent={LoadingSidebarContent}
	/>
);

export const LoadingSideNavigation = () => (
	<Layout HeaderComponent={LoadingSidebarHeader} ContentComponent={LoadingSidebarContent} />
);

export const LoadedSideNavigationWithErrorBoundary = () => (
	<ObjectTypeTreeStateProvider>
		<LayoutWithErrorBoundary HeaderComponent={SidebarHeader} ContentComponent={SidebarContent} />
	</ObjectTypeTreeStateProvider>
);

const LoadedSideNavigation = () => (
	<ObjectTypeTreeStateProvider>
		<Layout HeaderComponent={SidebarHeader} ContentComponent={SidebarContent} />
	</ObjectTypeTreeStateProvider>
);

export const SideNavigationWithErrorBoundary = () => {
	const [{ pageDataState }] = useInsightObjectSchemaPageData();
	return matchPageDataState(pageDataState, {
		loading: () => <LoadingSideNavigationWithErrorBoundary />,
		error: () => <ErrorSideNavigationWithErrorBoundary />,
		success: () => <LoadedSideNavigationWithErrorBoundary />,
	});
};

export const SideNavigation = () => {
	const [{ pageDataState }] = useInsightObjectSchemaPageData();

	return matchPageDataState(pageDataState, {
		loading: () => <LoadingSideNavigation />,
		error: () => <ErrorSideNavigation />,
		success: () => <LoadedSideNavigation />,
	});
};
