import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	cappedCountTooltip: {
		id: 'servicedesk-insight-object-schema-page.common.total-objects-pagination.uncapped-count-button.capped-count-tooltip',
		defaultMessage: 'See the total number of objects.',
		description:
			'Tooltip shown over capped object count indicating it can be clicked to reveal total object count',
	},
});
