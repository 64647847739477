import { performGetRequest } from '@atlassian/jira-fetch';
import { getCmdbConfigStatusTypeUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-common-workspace-context';
import type {
	SchemaId,
	CmdbNumericStatusCategory,
} from '@atlassian/jira-servicedesk-insight-shared-types';

export type StatusTypeResponse = {
	id: string;
	name: string;
	category: CmdbNumericStatusCategory;
	objectSchemaId?: SchemaId;
	description?: string;
};

export const fetchSchemaStatusTypes = async ({
	workspaceId,
	schemaId,
	controller,
}: {
	workspaceId: WorkspaceId;
	schemaId: SchemaId;
	controller?: AbortController;
}): Promise<StatusTypeResponse[]> => {
	const globalStatusesPromise: Promise<StatusTypeResponse[]> = performGetRequest(
		getCmdbConfigStatusTypeUrl(workspaceId),
		controller ? { signal: controller.signal } : undefined,
	);
	const schemaStatusesPromise: Promise<StatusTypeResponse[]> = performGetRequest(
		getCmdbConfigStatusTypeUrl(workspaceId, {
			objectSchemaId: schemaId,
		}),
		controller ? { signal: controller.signal } : undefined,
	);
	return [...(await globalStatusesPromise), ...(await schemaStatusesPromise)];
};
