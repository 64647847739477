import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sortErrorTitle: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-list.table.sort-error-title',
		defaultMessage: 'We were unable to sort your objects',
		description: 'Error informing the user that the system failed to sort their objects',
	},
	sortErrorDescription: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-list.table.sort-error-description',
		defaultMessage: 'Please check your network and try again',
		description: 'Error informing the user that the system failed to sort their objects',
	},
});
