import type {
	WorkspaceId,
	ObjectTypeAttributeId,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import { getCmdbRestBaseUrl } from './index';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectattributefilter
 */
const getCmdbObjectAttributeFilterUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/objectattributefilter`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objectattributefilter/filterlist
 */
export const getCmdbObjectAttributeFilterListUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbObjectAttributeFilterUrl(workspaceId)}/filterlist`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/objecttype/${objectTypeId}/filterlist
 */
export const getCmdbObjectTypeAttributeFilterUrl = (
	workspaceId: WorkspaceId,
	objectTypeId: ObjectTypeAttributeId,
) => `${getCmdbRestBaseUrl(workspaceId)}/objecttype/${objectTypeId}/filterlist`;
