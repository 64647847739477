import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { CmdbObjectTypeId } from '@atlassian/jira-servicedesk-insight-shared-types';
import type { ActionApi, ObjectTypesById } from '../../common/types';
import { performUpdateObjectType, type RequestProperties } from '../../services/update-object-type';

export const updateObjectType =
	({
		objectTypeId,
		updatedProperties,
	}: {
		objectTypeId: CmdbObjectTypeId;
		updatedProperties: RequestProperties;
	}): ActionApi =>
	async ({ getState, setState }, { workspaceId, createAnalyticsEvent }) => {
		try {
			const updatedObjectType = await performUpdateObjectType({
				workspaceId,
				objectTypeId,
				updatedProperties,
			});

			const { pageDataState } = getState();
			if (pageDataState.type !== 'success') {
				throw new Error(
					`pageDataState.type value: "${pageDataState.type}" did not match expected: "success"`,
				);
			}

			const updatedObjectTypesById: ObjectTypesById = {
				...pageDataState.objectTypesById,
				[String(updatedObjectType.id)]: updatedObjectType,
			};
			setState({
				pageDataState: {
					...pageDataState,
					objectTypesById: updatedObjectTypesById,
				},
			});
			fireTrackAnalytics(createAnalyticsEvent({}), 'updateObjectType succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'updateObjectType',
					packageName: 'jiraServicedeskInsightObjectSchemaPageStore',
					teamName: 'falcons',
				},
				error,
			});
			// Re-throw error so that consumers can know if the action succeeded
			throw error;
		}
	};
