import React, { useCallback, type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { useWorkspaceContext } from '@atlassian/jira-servicedesk-insight-common-workspace-context';
import { ObjectDetailsRenderingContext } from '@atlassian/jira-servicedesk-insight-object-details';
import { useObjectSearchStoreState } from '@atlassian/jira-servicedesk-insight-object-search-store';
import type { CmdbObjectId } from '@atlassian/jira-servicedesk-insight-shared-types';
import {
	useUpdateQueryParams,
	SCHEMA_PAGE_QUERY_PARAMS,
} from '@atlassian/jira-servicedesk-insight-urls';
import { EmbeddedObjectView } from '../../../../../common/ui/embedded-object-view';
import { messages } from './messages';

type ObjectViewProps = {
	objectId: CmdbObjectId;
};

export const ObjectView = ({ objectId }: ObjectViewProps) => {
	const { formatMessage } = useIntl();
	const { workspaceId } = useWorkspaceContext();
	const [, { refreshSearch }] = useObjectSearchStoreState();
	const [{ updateQueryParams }] = useUpdateQueryParams();

	const refreshOnObjectChange = useCallback(() => {
		refreshSearch();
	}, [refreshSearch]);

	if (!workspaceId) {
		return null;
	}

	const backButton = {
		label: formatMessage(messages.backButtonLabel),
		to: updateQueryParams({ [SCHEMA_PAGE_QUERY_PARAMS.objectId]: null }),
	};
	return (
		<ObjectViewContainer headerShownAboveEmbeddedView>
			<EmbeddedObjectView
				context={ObjectDetailsRenderingContext.Component}
				objectId={objectId}
				workspaceId={workspaceId}
				onUpdate={refreshOnObjectChange}
				onDelete={refreshOnObjectChange}
				onClone={refreshOnObjectChange}
				backButton={backButton}
				redirectOnDelete={false}
			/>
		</ObjectViewContainer>
	);
};

const ObjectViewContainer = ({
	headerShownAboveEmbeddedView,
	children,
}: {
	headerShownAboveEmbeddedView: boolean;
	children?: ReactNode;
}) => (
	<Box
		xcss={[
			objectViewContainerStyles,
			headerShownAboveEmbeddedView ? headerShownStyles : defaultHeaderStyles,
		]}
	>
		{children}
	</Box>
);

const objectViewContainerStyles = xcss({
	flex: 1,
	overflowY: 'auto',
	marginBottom: 'space.200',
	/* The following horizontal margin/padding that cancel each other out allows
    for up to 'gridSize' to bleed outside width of this container. Useful for
    things like box-shadows around components near the edge of this container */
	marginLeft: 'space.negative.100',
	marginRight: 'space.negative.100',
	paddingBottom: 'space.0',
	paddingLeft: 'space.0',
	paddingRight: 'space.100',
	paddingTop: 'space.0',
});

const headerShownStyles = xcss({
	marginTop: 'space.100',
});

const defaultHeaderStyles = xcss({
	marginTop: 'space.400',
});
