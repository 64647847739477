import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import DetailViewIcon from '@atlaskit/icon/glyph/detail-view';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { AsyncBulkActions } from '@atlassian/jira-servicedesk-insight-bulk-actions';
import { AsyncColumnPicker } from '@atlassian/jira-servicedesk-insight-column-picker';
import { AsyncObjectFilter } from '@atlassian/jira-servicedesk-insight-object-filter';
import { useSchemaPageUiState } from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import {
	type Column,
	useObjectSearchStoreState,
	getColumns,
} from '@atlassian/jira-servicedesk-insight-object-search-store';
import { messages } from './messages';
import { setLocalSelectedColumns } from './utils';

export const Controls = () => {
	const { formatMessage } = useIntl();
	const [, { setObjectViewMode }] = useSchemaPageUiState();
	const [searchStoreState, { setSelectedColumns }] = useObjectSearchStoreState();
	const { currentSearchScope } = searchStoreState;

	const onClickAction = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'insightObjectDetailsViewMode');
			setObjectViewMode('details');
		},
		[setObjectViewMode],
	);

	if (!currentSearchScope || currentSearchScope.type === 'global') {
		return null;
	}
	const { objectTypeId } = currentSearchScope;
	const columns = getColumns(searchStoreState);

	const onChangeColumns = (newColumns: Column[]) => {
		setSelectedColumns(
			newColumns.filter(({ isSelected }) => isSelected).map(({ target }) => target),
		);
		setLocalSelectedColumns(objectTypeId, newColumns);
	};

	return (
		<Container>
			<AsyncBulkActions />
			<AsyncColumnPicker columns={columns} onChange={onChangeColumns} />
			<AsyncObjectFilter triggerDisplay="wide" popupPlacement="end" />
			<Tooltip content={formatMessage(messages.detailView)}>
				<Button
					onClick={(_, analyticsEvent) => onClickAction(analyticsEvent)}
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					iconBefore={<DetailViewIcon label="" />}
					appearance="subtle"
					testId="servicedesk-insight-object-schema-page.ui.page-content.loaded.page-content.object-list.controls.details-view"
				/>
			</Tooltip>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	gap: token('space.100', '8px'),
});
