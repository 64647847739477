import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	moveErrorTitle: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.content.object-types.move-error-title',
		defaultMessage: "We couldn't move your object type",
		description: 'Title of an error message shown when the user fails to move an object type',
	},
	moveErrorDescription: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.content.object-types.move-error-description',
		defaultMessage: 'Please check your network connection and try again',
		description: 'Description of an error message shown when the user fails to move an object type',
	},
	moreLabel: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.content.object-types.more-label',
		defaultMessage: 'More',
		description: 'Label for a button that when clicked on shows "more" actions',
	},
	notMovableToInheritedTreeErrorMessage: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.content.object-types.not-movable-to-inherited-tree-error-message',
		defaultMessage: 'Not possible to move to inherited object type tree.',
		description:
			'This text will be displayed when user tries to move object type into the inherited object type tree',
	},
	inheritedObjectTypeNotMovableErrorMessage: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.content.object-types.inherited-object-type-not-movable-error-message',
		defaultMessage: 'Not possible to move if inheritance is enabled.',
		description: 'This text will be displayed when user tries to move the inherited object type',
	},
});
