import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	minValidation: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.editable-object-type.editable-name.min-validation',
		defaultMessage:
			'Name must contain at least {min, plural, one {# character} other {# characters}}',
		description: 'Field validation error when the input is too short',
	},
	maxValidation: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-header.editable-object-type.editable-name.max-validation',
		defaultMessage:
			'Name must contain less than {max, plural, one {# character} other {# characters}}',
		description: 'Field validation error when the input is too long',
	},
});
