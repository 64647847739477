import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	logoAltText: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.header.logo-alt-text',
		defaultMessage: 'Object schema icon',
		description: 'Describes the object schema icon for accessibility.',
	},
	schemaGraphDisabledTooltip: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.header.schema-graph-disabled-tooltip',
		defaultMessage: 'Create an object type to enable the schema graph',
		description:
			'Tooltip explaining that object types need to exist before being able to access the Assets schema graph',
	},
	schemaGraph: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.header.schema-graph',
		defaultMessage: 'Schema graph',
		description: 'The label for a button to view the object schema graph.',
	},
	schemaSettings: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.header.schema-settings',
		defaultMessage: 'Schema configuration',
		description: 'The label for a link to the object schema configuration page.',
	},
	schemaTree: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.header.schema-tree',
		defaultMessage: 'Schema Tree',
		description: 'The heading displayed the above the object types of the schema',
	},
	expandAllTooltip: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.header.expand-all-tooltip',
		defaultMessage: 'Expand all branches',
		description: 'Tooltip explaining the action of the expand all icon button when hovered',
	},
	collapseAllTooltip: {
		id: 'servicedesk-insight-object-schema-page.common.side-navigation.loaded.header.collapse-all-tooltip',
		defaultMessage: 'Collapse all branches',
		description: 'Tooltip explaining the action of the collapse all icon button when hovered',
	},
});
