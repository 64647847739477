import React from 'react';
import { styled as styled2 } from '@compiled/react';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import MoreIcon from '@atlaskit/icon/glyph/more';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { LinkItem, ButtonItem, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { type CSSToken, token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/jira-router';
import {
	type ObjectType,
	useLoadedSchemaPageData,
	canCreateObjectType,
	canCloneObjectType,
	canViewObjectTypeSettings,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import { createObjectTypeSettingsUrl } from '@atlassian/jira-servicedesk-insight-urls';
import { commonMessages } from '../../../../../../../messages';
import { messages } from './messages';

const COLOUR_BACKGROUND_MAP = {
	BACKGROUND_GREY_UNSELECTED: token('elevation.surface', colors.N10),
	BACKGROUND_GREY_HOVERED: token('color.background.neutral.subtle.hovered', colors.N30),
	BACKGROUND_BLUE_IS_SELECTED: token('color.background.selected', colors.DN900),
};

export type Props = {
	isHovering: boolean;
	isFocussed: boolean;
	isMenuOpen: boolean;
	isSelected: boolean;
	objectType: ObjectType;
	onChangeMenuOpen: (newIsOpen: boolean) => void;
};
export const BadgeAndActions = ({
	objectType,
	isHovering,
	isFocussed,
	isSelected,
	isMenuOpen,
	onChangeMenuOpen,
}: Props) => {
	const { formatMessage } = useIntl();
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [schemaPageData, { setObjectTypeModalState }] = useLoadedSchemaPageData();
	const { id, objectCount } = objectType;
	const onAddObjectType = () => {
		setObjectTypeModalState({
			type: 'creating',
			initialParent: id,
		});
	};
	const onCopyObjectType = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'insightObjectTypeCopy');
		setObjectTypeModalState({ type: 'copying', objectTypeIdToCopy: id });
		onChangeMenuOpen(false);
	};
	const onConfigureObjectType = () => {
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'insightObjectTypeConfigure');
		push(createObjectTypeSettingsUrl(id));
	};

	const canCreate = canCreateObjectType(schemaPageData, id);
	const canClone = canCloneObjectType(schemaPageData, id);
	const canViewSettings = canViewObjectTypeSettings(schemaPageData, id);
	const hasMoreActions = canClone || canViewSettings;
	const hasAnyActions = canCreate || canClone || canViewSettings;

	const renderMoreActions = () => {
		if (!hasMoreActions) {
			return null;
		}

		return (
			<Popup
				isOpen={isMenuOpen}
				onClose={() => onChangeMenuOpen(false)}
				content={() => (
					<Section>
						{canViewSettings && (
							<LinkItem
								href={createObjectTypeSettingsUrl(id)}
								onClick={() => onConfigureObjectType()}
								data-testid="servicedesk-insight-object-schema-page.common.ui.side-navigation.loaded.content.object-types.object-type-link.badge-and-actions.configure"
								iconBefore={<SettingsIcon label={formatMessage(messages.objectTypeSettings)} />}
								UNSAFE_shouldDisableRouterLink
							>
								{formatMessage(messages.objectTypeSettings)}
							</LinkItem>
						)}
						{canClone && (
							<ButtonItem
								onClick={onCopyObjectType}
								iconBefore={<CopyIcon label={formatMessage(messages.copy)} />}
							>
								{formatMessage(messages.copy)}
							</ButtonItem>
						)}
					</Section>
				)}
				trigger={(triggerProps) => (
					<Button
						{...triggerProps}
						isSelected={isMenuOpen}
						onClick={() => onChangeMenuOpen(!isMenuOpen)}
						iconBefore={<MoreIcon label={formatMessage(messages.more)} />}
						testId={`servicedesk-insight-object-schema-page.common.ui.side-navigation.loaded.content.object-types.object-type-link.badge-and-actions.more_${objectType.id}`}
					/>
				)}
				placement="bottom-start"
			/>
		);
	};

	const getWrappingActionContainerBgColour = () => {
		if (isSelected && !isHovering && isFocussed)
			return COLOUR_BACKGROUND_MAP.BACKGROUND_BLUE_IS_SELECTED;
		if (isHovering) return COLOUR_BACKGROUND_MAP.BACKGROUND_GREY_HOVERED;
		return COLOUR_BACKGROUND_MAP.BACKGROUND_GREY_UNSELECTED;
	};

	const isActive = isHovering || isFocussed;

	return (
		<BadgeActionsSwitcher>
			<BadgeContainer isActive={hasAnyActions && isActive}>
				<Badge max={Number.MAX_SAFE_INTEGER}>{objectCount}</Badge>
			</BadgeContainer>

			<ActionContainer
				isActive={hasAnyActions && isActive}
				onClick={(event) => {
					// Prevent clicks on actions from bubbling up to the containing link
					event.preventDefault();
					event.stopPropagation();
				}}
				// There may be only clone shown (in the meatball menu) if the user has manager permission on the parent object type but not this one
				numButtons={Number(hasMoreActions) + Number(canCreate)}
				showGeneralMaskColour={getWrappingActionContainerBgColour()}
			>
				{renderMoreActions()}
				{canCreate && (
					<Button
						onClick={onAddObjectType}
						iconBefore={<EditorAddIcon label={formatMessage(commonMessages.addObjectType)} />}
					/>
				)}
			</ActionContainer>
		</BadgeActionsSwitcher>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionContainer = styled2.div<{
	numButtons: number;
	showGeneralMaskColour: CSSToken;
	isActive?: boolean;
}>({
	position: 'absolute',
	right: 0,
	top: '50%',
	transform: 'translateY(-50%)',
	display: 'flex',
	gap: token('space.100', '8px'),
	borderRadius: token('border.radius', '3px'),
	// Explicitly set the width: 32px wide for each button, with 8px gap
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ numButtons }) => (numButtons === 2 ? '72px' : '32px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ showGeneralMaskColour }) => showGeneralMaskColour,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isActive }) => (isActive ? '1' : '0'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeContainer = styled2.div<{ isActive: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isActive }) => (isActive ? '0' : '1'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeActionsSwitcher = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 4}px`,
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	minWidth: 0,
});
