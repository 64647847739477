import { useState, useEffect, useRef } from 'react';
import { useResource } from '@atlassian/jira-router';
import { isCMDBEnabledInEnvironment } from '@atlassian/jira-servicedesk-cmdb-fedramp';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { workspaceResource } from '../../services/resources';
import { isLicensedPremiumUser } from '../../services/resources/get-data';
import type { WorkspaceContext } from './types';

export const WORKSPACE_QUERY_URL = '/rest/servicedesk/cmdb/latest/workspace';

// Polling interval set to 30 seconds. From testing, it seems provisioning takes around 120 seconds.
// This should give us a bit of lenience with responding realtively quickly once provisioning completes without needing to check constantly.
export const WORKSPACE_ID_POLL_INTERVAL_MS = 30000;

export const useWorkspaceContext = (): WorkspaceContext => {
	const { data, loading, error, refresh: fetchWorkspaceId } = useResource(workspaceResource);
	const workspaceId = data ?? undefined;
	return {
		loading,
		// @ts-expect-error - TS2322 - Type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error) | null' is not assignable to type 'Error | null | undefined'.
		error,
		workspaceId,
		fetchWorkspaceId,
	};
};

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
const useInterval = (callback: () => void, delay: number | null) => {
	const savedCallback = useRef<() => void>(callback);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		const tick = () => {
			savedCallback.current();
		};
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
		return undefined;
	}, [delay]);
};

/**
 * Hook that will return true if there is a JSM Premium licence present AND the
 * Insight service has finished provisioning and CMDB is enabled in the environment.
 * Polling is implemented to check the provisioning status of the Insight Service when
 * there is a Premium licence.
 */
export const useInsightVisibility = (): boolean => {
	const tenantContext = useTenantContext();
	const { workspaceId, fetchWorkspaceId, loading } = useWorkspaceContext();
	// Optimistically set this true if user is premium and CMDB is enabled in environment - which will prevent a flash for the majority of users
	const [isVisible, setIsVisible] = useState(
		isCMDBEnabledInEnvironment() && isLicensedPremiumUser(tenantContext),
	);
	const [shouldPollForWorkspaceId, setShouldPollForWorkspaceId] = useState(false);
	const [hasFetchedWorkspaceId, setHasFetchedWorkspaceId] = useState(false);

	// Can't test this component without a rerender on each poll — so am just increment a counter with each poll
	const [, setPollCount] = useState(0);

	// Run an initial fetch of the workspaceId if the resource wasn't already fetched as
	// part of the route definition (like for the Insight menu in the global nav)
	useEffect(() => {
		if (!workspaceId && isLicensedPremiumUser(tenantContext) && isCMDBEnabledInEnvironment()) {
			fetchWorkspaceId();
			setHasFetchedWorkspaceId(true);
		}
	}, [fetchWorkspaceId, tenantContext, workspaceId]);

	// Starts/stops the polling of workspaceId
	useInterval(
		() => {
			fetchWorkspaceId();
			setPollCount((a) => a + 1);
		},
		shouldPollForWorkspaceId ? WORKSPACE_ID_POLL_INTERVAL_MS : null,
	);

	// Main check to see if insight should be visible
	// If user has premium subscription but no workspace ID, we begin polling for the
	// workspace ID as it means their Insight instance hasn’t completed provisioning yet
	useEffect(() => {
		if (hasFetchedWorkspaceId && !loading) {
			if (isLicensedPremiumUser(tenantContext)) {
				if (workspaceId !== undefined) {
					setIsVisible(true);
					setShouldPollForWorkspaceId(false);
				} else {
					setIsVisible(false);
					setShouldPollForWorkspaceId(true);
				}
			} else {
				setIsVisible(false);
				setShouldPollForWorkspaceId(false);
			}
		}
	}, [tenantContext, workspaceId, loading, hasFetchedWorkspaceId]);

	return isVisible;
};
