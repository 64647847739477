import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { ActionApi, ObjectTypesById } from '../../common/types';
import { fetchObjectTypes } from '../../services/fetch-object-types';

export const fetchSchemaObjectTypes =
	(): ActionApi =>
	async ({ getState, setState }, { workspaceId, objectSchemaId, createAnalyticsEvent }) => {
		try {
			const objectTypesById: ObjectTypesById = await fetchObjectTypes(workspaceId, objectSchemaId);

			const { pageDataState } = getState();

			if (pageDataState.type !== 'success') {
				throw new Error(
					`pageDataState.type value: "${pageDataState.type}" did not match expected: "success"`,
				);
			}

			setState({
				pageDataState: {
					...pageDataState,
					objectTypesById,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchInsightSchemaObjectTypes succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				pageDataState: { type: 'error', error },
			});

			fireErrorAnalytics({
				meta: {
					id: 'fetchInsightSchemaObjectTypes',
					packageName: 'jiraServicedeskInsightObjectSchemaPageStore',
					teamName: 'falcons',
				},
				error,
			});
		}
	};
