import React from 'react';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import { ButtonItem, Section } from '@atlaskit/side-navigation';
import { useIntl } from '@atlassian/jira-intl';
import {
	useLoadedSchemaPageData,
	canCreateObjectType,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import { messages } from './messages';
import { ObjectTypeNavItems } from './object-types';

export const SidebarContent = () => {
	const [schemaPageData, { setObjectTypeModalState }] = useLoadedSchemaPageData();
	const { objectTypesById } = schemaPageData;
	const { formatMessage } = useIntl();
	const showAddObjectTypeButton =
		Object.keys(objectTypesById).length === 0 && canCreateObjectType(schemaPageData, null);

	const onAddObjectType = () => setObjectTypeModalState({ type: 'creating', initialParent: null });

	return (
		<>
			<Section>
				<ObjectTypeNavItems />
				{showAddObjectTypeButton && (
					<ButtonItem
						iconBefore={<EditorAddIcon label={formatMessage(messages.addObjectType)} />}
						onClick={onAddObjectType}
						testId="servicedesk-insight-object-schema-page.common.ui.side-navigation.loaded.content.add-object-type"
					>
						{formatMessage(messages.addObjectType)}
					</ButtonItem>
				)}
			</Section>
		</>
	);
};
