import { useQueryParam } from '@atlassian/jira-router';

export const useObjectTypeIdQueryParam = () => useQueryParam('typeId');

export const useObjectIdQueryParam = () => useQueryParam('objectId');

export const createQueryStringParams = (typeId?: string, objectId?: string) => {
	if (typeId && !objectId) return `?typeId=${typeId}`;
	if (typeId && objectId) return `?typeId=${typeId}&objectId=${objectId}`;
	return '';
};
