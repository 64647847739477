import React, { type ReactNode, useMemo } from 'react';
import type { StoreProps, UrlParams } from '../common/types';
import {
	UrlParamContextProvider,
	StoreContainer,
	useInsightObjectSchemaQueryParams,
} from '../services';

export const InsightObjectSchemaPageContainer = ({
	children,
	...storeProps
}: {
	children: ReactNode;
} & StoreProps) => {
	const queryParams = useInsightObjectSchemaQueryParams();
	const { objectSchemaId } = storeProps;
	const urlParams = useMemo<UrlParams>(
		() => ({ objectSchemaId, ...queryParams }),
		[objectSchemaId, queryParams],
	);

	const { isGlobal = true, ...otherStoreProps } = storeProps;
	return (
		<UrlParamContextProvider value={urlParams}>
			<StoreContainer
				{...otherStoreProps}
				{...queryParams}
				scope={isGlobal ? storeProps.objectSchemaId : undefined}
			>
				{children}
			</StoreContainer>
		</UrlParamContextProvider>
	);
};
