import React from 'react';
import { AsyncAttributesTable } from '@atlassian/jira-servicedesk-cmdb-attributes-table';
import { loadAttributesConfigurationExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import type { CmdbObjectTypeId, SchemaId } from '@atlassian/jira-servicedesk-insight-shared-types';

export const AttributesView = ({
	schemaId,
	objectTypeId,
	objectTypeName,
	hasInheritedParentAttributes,
	isObjectTypeInherited,
	hasParent,
	attributesPerObjectTypeLimit,
	attributeCardinalityLimit,
}: {
	schemaId: SchemaId;
	objectTypeId: CmdbObjectTypeId;
	objectTypeName: string;
	hasInheritedParentAttributes: boolean;
	isObjectTypeInherited: boolean;
	hasParent: boolean;
	attributesPerObjectTypeLimit: number;
	attributeCardinalityLimit: number;
}) => {
	loadAttributesConfigurationExperience.start();

	return (
		<AsyncAttributesTable
			schemaId={schemaId}
			objectTypeId={objectTypeId}
			objectTypeName={objectTypeName}
			hasInheritedParentAttributes={hasInheritedParentAttributes}
			isObjectTypeInherited={isObjectTypeInherited}
			hasParent={hasParent}
			attributesPerObjectTypeLimit={attributesPerObjectTypeLimit}
			attributeCardinalityLimit={attributeCardinalityLimit}
		/>
	);
};
