import { toValidatedIql } from '@atlassian/jira-servicedesk-insight-iql-validation/src/common/types';
import type { ActionApi, SearchQuery, OrderBy } from '../../../../common/types';
import { extractOrderBy } from '../../../../common/utils';
import { executeSearch } from '../execute-search';

export const updateOrderBy =
	(newOrderBy: OrderBy): ActionApi =>
	async ({ getState, dispatch }) => {
		const { lastSearch } = getState();
		if (lastSearch.type !== 'success') {
			return;
		}

		// Take the request that was made for the current displayed search and update with the new order
		const originalRequestQuery = lastSearch.value.request.query;
		let query: SearchQuery;
		if (originalRequestQuery.type === 'qlQuery') {
			const { iqlWithoutOrderBy } = extractOrderBy(originalRequestQuery.qlQuery);
			const { attributeName, direction } = newOrderBy;
			const updatedQlQuery = toValidatedIql(
				`${String(iqlWithoutOrderBy)} ORDER BY "${attributeName}" ${direction}`,
			);
			query = {
				...originalRequestQuery,
				qlQuery: updatedQlQuery,
			};
		} else {
			query = {
				...originalRequestQuery,
				orderBy: newOrderBy,
			};
		}

		await dispatch(
			executeSearch({
				request: {
					query,
					// Always go to page 1 after changing order
					page: {
						type: 'page-number',
						pageNumber: 1,
					},
				},
				isRefresh: true,
			}),
		);
	};
