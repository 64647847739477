import React, { forwardRef, type ReactNode } from 'react';
import ErrorIcon from '@atlaskit/icon/core/error';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TriggerProps } from '@atlaskit/tooltip';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { useIntl } from '@atlassian/jira-intl';
import { useObjectSearchPagination } from '@atlassian/jira-servicedesk-insight-object-search-store';
import type { Pagination } from '@atlassian/jira-servicedesk-insight-object-search-store/src/common/types';
import type { ObjectsPaginationLabelProps } from '../../types';
import { ObjectsPaginationLabel } from '../objects-pagination-label';
import { messages } from './messages';
import { UncappedCountButton, type UncappedCountButtonProps } from './uncapped-count-button';

const RenderPaginationWithFinalCount = ({
	type,
	numObjects,
	startIndex,
	toIndex,
}: ObjectsPaginationLabelProps & Pick<Pagination, 'numObjects' | 'startIndex' | 'toIndex'>) => {
	const { formatMessage } = useIntl();
	return (
		<>
			{type === 'simplified'
				? formatMessage(messages.paginationSimplified, {
						startIndex,
						toIndex,
						numObjects,
					})
				: formatMessage(messages.pagination, {
						startIndex,
						toIndex,
						numObjects,
					})}
		</>
	);
};

const RenderPaginationWithLoading = ({
	type,
	numObjects,
	startIndex,
	toIndex,
}: ObjectsPaginationLabelProps & Pick<Pagination, 'numObjects' | 'startIndex' | 'toIndex'>) => {
	const { formatMessage } = useIntl();
	return (
		<span>
			{type === 'simplified'
				? formatMessage(messages.cappedPaginationSimplified, {
						startIndex,
						toIndex,
						cappedNumbObjects: (
							<SpinnerContainer>
								<Spinner
									size="small"
									testId="servicedesk-insight-object-schema-page.common.ui.total-objects-pagination.spinner"
								/>
							</SpinnerContainer>
						),
					})
				: formatMessage(messages.cappedPagination, {
						startIndex,
						toIndex,
						cappedNumbObjects: (
							<SpinnerContainer>
								<Spinner
									size="small"
									testId="servicedesk-insight-object-schema-page.common.ui.total-objects-pagination.spinner"
								/>
							</SpinnerContainer>
						),
						numObjects,
					})}
		</span>
	);
};

const RenderPaginationWithError = ({
	type,
	numObjects,
	startIndex,
	toIndex,
	onClick,
}: ObjectsPaginationLabelProps &
	Pick<UncappedCountButtonProps, 'onClick'> &
	Pick<Pagination, 'numObjects' | 'startIndex' | 'toIndex'>) => {
	const { formatMessage } = useIntl();
	return (
		<span>
			{type === 'simplified'
				? formatMessage(messages.cappedPaginationSimplified, {
						startIndex,
						toIndex,
						cappedNumbObjects: (
							<>
								<UncappedCountButton cappedTotal={numObjects} onClick={onClick} />{' '}
								<Tooltip
									position="top"
									content={formatMessage(messages.errorTooltipText)}
									tag="span"
								>
									{(tooltipProps) => (
										<ErrorIconContainer
											{...tooltipProps}
											data-testid="servicedesk-insight-object-schema-page.common.ui.total-objects-pagination.error-icon"
										>
											<ErrorIcon
												label={formatMessage(messages.errorIconLabel)}
												color={token('color.icon.danger')}
											/>
										</ErrorIconContainer>
									)}
								</Tooltip>
							</>
						),
					})
				: formatMessage(messages.cappedPagination, {
						startIndex,
						toIndex,
						cappedNumbObjects: (
							<>
								<UncappedCountButton cappedTotal={numObjects} onClick={onClick} />{' '}
								<Tooltip
									position="top"
									content={formatMessage(messages.errorTooltipText)}
									tag="span"
								>
									{(tooltipProps) => (
										<ErrorIconContainer {...tooltipProps}>
											<ErrorIcon
												label={formatMessage(messages.errorIconLabel)}
												color={token('color.icon.danger')}
											/>
										</ErrorIconContainer>
									)}
								</Tooltip>
							</>
						),
						numObjects,
					})}
		</span>
	);
};

const RenderPaginationWithFetch = ({
	type,
	numObjects,
	startIndex,
	toIndex,
	onClick,
}: ObjectsPaginationLabelProps &
	Pick<UncappedCountButtonProps, 'onClick'> &
	Pick<Pagination, 'numObjects' | 'startIndex' | 'toIndex'>) => {
	const { formatMessage } = useIntl();
	return (
		<span>
			{type === 'simplified'
				? formatMessage(messages.cappedPaginationSimplified, {
						startIndex,
						toIndex,
						cappedNumbObjects: <UncappedCountButton cappedTotal={numObjects} onClick={onClick} />,
					})
				: formatMessage(messages.cappedPagination, {
						startIndex,
						toIndex,
						cappedNumbObjects: <UncappedCountButton cappedTotal={numObjects} onClick={onClick} />,
						numObjects,
					})}
		</span>
	);
};

const RenderSinglePage = ({ numObjects }: Pick<Pagination, 'numObjects'>) => {
	const { formatMessage } = useIntl();
	return (
		<>
			{formatMessage(messages.objectsCount, {
				numObjects,
			})}
		</>
	);
};

export const TotalObjectsPaginationWithoutErrorBoundary = ({
	type = 'default',
}: ObjectsPaginationLabelProps) => {
	const [
		{ numObjects, numPages, startIndex, toIndex, hasMoreResults, uncappedNumObjects },
		{ fetchUncappedCount },
	] = useObjectSearchPagination();

	if (numPages <= 1) {
		return <RenderSinglePage numObjects={numObjects} />;
	}

	if (hasMoreResults) {
		if (uncappedNumObjects?.loading) {
			return (
				<RenderPaginationWithLoading
					type={type}
					startIndex={startIndex}
					toIndex={toIndex}
					numObjects={numObjects}
				/>
			);
		}
		if (uncappedNumObjects?.error) {
			return (
				<RenderPaginationWithError
					type={type}
					startIndex={startIndex}
					toIndex={toIndex}
					numObjects={numObjects}
					onClick={fetchUncappedCount}
				/>
			);
		}
		if (uncappedNumObjects?.totalCount) {
			return (
				<RenderPaginationWithFinalCount
					type={type}
					startIndex={startIndex}
					toIndex={toIndex}
					numObjects={uncappedNumObjects.totalCount}
				/>
			);
		}
		return (
			<RenderPaginationWithFetch
				type={type}
				startIndex={startIndex}
				toIndex={toIndex}
				numObjects={numObjects}
				onClick={fetchUncappedCount}
			/>
		);
	}

	return (
		<RenderPaginationWithFinalCount
			type={type}
			startIndex={startIndex}
			toIndex={toIndex}
			numObjects={numObjects}
		/>
	);
};

const ErrorIconContainer = forwardRef<HTMLDivElement, TriggerProps & { children?: ReactNode }>(
	({ children, ...props }, ref) => (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Box {...props} ref={ref} as="span" xcss={errorIconStyles}>
			{children}
		</Box>
	),
);

const SpinnerContainer = ({ children }: { children?: ReactNode }) => (
	<Box as="span" xcss={spinnerStyles}>
		{children}
	</Box>
);

/**
 * Display the total number of objects from a search with the ability to toggle from capped to uncapped values.
 *
 * Capped vs Uncapped
 *  - the **capped count** is returned from the executeSearch query. It will be capped at 1000 if
 *    there are more than 1000 results. This is the default count that is displayed
 *
 *  - the **uncapped count** is returned from the fetchUncappedCount query. It is the true number of results
 *    for a search and does not have a cap. This value must be requested by the user via button click.
 */
export const TotalObjectsPagination = (props: ObjectsPaginationLabelProps) => (
	<JSErrorBoundary
		id="JsmCmdbTotalObjectsPagination"
		packageName="jiraServicedeskInsightObjectSchemaPage"
		teamName="falcons"
		// If an error occurs we fallback to the original pagination
		render={() => <ObjectsPaginationLabel {...props} />}
	>
		<TotalObjectsPaginationWithoutErrorBoundary {...props} />
	</JSErrorBoundary>
);

const spinnerStyles = xcss({
	display: 'inline-block',
	marginBottom: 'space.025',
	marginInline: 'space.050',
});

const errorIconStyles = xcss({
	display: 'inline-block',
	height: 'space.300',
	color: 'color.text.danger',
	marginInline: 'space.050',
});
