import { performGetRequest, performPostRequest } from '@atlassian/jira-fetch';
import {
	getCmdbDescribeRolesUrl,
	getCmdbObjectSchemaByIdObjectTypesFlatUrl,
} from '@atlassian/jira-servicedesk-cmdb-rest-urls';
import type {
	ObjectType as ObjectTypeResponse,
	SchemaId,
	WorkspaceId,
	RolesResponse,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import type { ObjectTypesById } from '../../common/types';
import { transformObjectTypeResponse } from '../../common/utils';

export const fetchObjectTypes = async (
	workspaceId: WorkspaceId,
	objectSchemaId: SchemaId,
): Promise<ObjectTypesById> => {
	const [objectTypeResponses, schemaRolesResponse]: [ObjectTypeResponse[], RolesResponse] =
		await Promise.all([
			performGetRequest(
				getCmdbObjectSchemaByIdObjectTypesFlatUrl(workspaceId, objectSchemaId, {
					includeObjectCounts: true,
				}),
			),
			performPostRequest(getCmdbDescribeRolesUrl(workspaceId), {
				body: JSON.stringify({
					objectSchemaRequests: [
						{
							resourceId: objectSchemaId,
						},
					],
				}),
			}),
		]);

	return objectTypeResponses.reduce<ObjectTypesById>((acc, objectTypeResponse) => {
		const role = schemaRolesResponse?.objectTypeRoles?.find(
			({ resourceId }) => resourceId === objectTypeResponse.id,
		)?.role;
		const objectType = transformObjectTypeResponse(objectTypeResponse, role || 'OBJECT_TYPE_USER');
		acc[objectType.id] = objectType;
		return acc;
	}, {});
};
