import { fg } from '@atlassian/jira-feature-gating';
import type { CmdbLimitState } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/global-config.tsx';
import { NONE } from '../constants';
import type { ObjectUsageLocation, ObjectUsageInfo, UserRole, BillingPeriod } from '../types';

export const determineThreshold = ({
	objectCount,
	objectLimit,
}: {
	objectCount: number;
	objectLimit: number;
}): ObjectUsageInfo['type'] => {
	if (objectCount >= objectLimit) {
		return 'limit-reached';
	}
	if (objectCount >= objectLimit * 0.9) {
		return '90-percent';
	}
	if (objectCount >= objectLimit * 0.8) {
		return '80-percent';
	}
	return 'none';
};

/**
 * Common logic for determining what messaging to show to users related to object limits
 */
export const determineObjectUsageInfo = ({
	isJiraAdmin,
	objectCount,
	objectLimit,
	billingPeriod,
	location,
	limitState,
}: {
	isJiraAdmin: boolean;
	objectCount: number;
	objectLimit: number;
	billingPeriod: BillingPeriod;
	location: ObjectUsageLocation;
	limitState?: CmdbLimitState;
}): ObjectUsageInfo => {
	if (!fg('jsm_assets_cbp_limits_and_notifications')) {
		return NONE;
	}

	const userRole: UserRole = isJiraAdmin ? 'admin' : 'agent';
	const objectsRemaining = Math.max(objectLimit - objectCount, 0);
	switch (determineThreshold({ objectCount, objectLimit })) {
		case 'limit-reached':
			if (location === 'create-object' && limitState === 'notify-only') return NONE;
			return { type: 'limit-reached', userRole, billingPeriod, objectsRemaining };
		case '90-percent':
			return { type: '90-percent', userRole, billingPeriod, objectsRemaining };
		case '80-percent': {
			switch (location) {
				case 'assets-feature-usage-dashboard':
				case 'limits-banner':
				case 'create-object':
					if (userRole === 'agent') {
						// 80% not shown for agents in object create + limits-banner
						return NONE;
					}
					return { type: '80-percent', userRole, billingPeriod, objectsRemaining };

				case 'import-data-modal':
					// 80% not shown for any userRole in import data modal
					return NONE;

				default:
					return NONE;
			}
		}
		case 'none':
			return NONE;
		default:
			return NONE;
	}
};
