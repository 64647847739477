import type {
	AttributesTarget,
	SelectedColumns,
	TransformedSearchResponse,
} from '../../../../common/types';
import { ICON_COLUMN_TARGET } from '../../../../services/selectors';

export const getInitialSelectedColumns = (
	searchResult: TransformedSearchResponse,
): SelectedColumns =>
	searchResult.attributes.reduce((acc, attribute): SelectedColumns => {
		const { id, name, isSystem, isLabel } = attribute;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const column = {
			id,
			name,
			type: 'attribute',
			isLabel,
		} as AttributesTarget;
		if (isSystem || isLabel) {
			acc.push(column);
		}
		return acc;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, [] as SelectedColumns);

export const getLocalSelectedColumns = (
	localRecordList: string[],
	searchResult: TransformedSearchResponse,
): SelectedColumns =>
	localRecordList.reduce((acc, columnId): SelectedColumns => {
		const { attributes } = searchResult;
		attributes.forEach((attribute) => {
			const { id, name, isLabel } = attribute;
			if (id === columnId) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const column = {
					id,
					name,
					type: 'attribute',
					isLabel,
				} as AttributesTarget;
				acc.push(column);
			}
		});
		if (columnId === 'icon') {
			acc.push(ICON_COLUMN_TARGET);
		}
		return acc;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, [] as SelectedColumns);
