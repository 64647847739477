import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { type UIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import EditorCollapseIcon from '@atlaskit/icon/glyph/editor/collapse';
import EditorExpandIcon from '@atlaskit/icon/glyph/editor/expand';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { xcss } from '@atlaskit/primitives';
import Box from '@atlaskit/primitives/box';
import { ButtonItem, Section } from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ObjectGraphIcon, SchemaIcon } from '@atlassian/jira-servicedesk-insight-glyphs';
import {
	useLoadedSchemaPageData,
	canCreateObjectType,
	canViewSchemaSettings,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import { createObjectSchemaConfigUrl } from '@atlassian/jira-servicedesk-insight-urls';
import { commonMessages } from '../../../../messages';
import { SidebarCustomLinkItem } from '../../../sidebar-link-item';
import { useObjectTypeTree } from '../../services';
import { messages } from './messages';

export const SidebarHeader = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [schemaPageData, { setObjectTypeModalState, openSchemaGraph }] = useLoadedSchemaPageData();
	const { expandedDetails, setAllIsExpanded } = useObjectTypeTree();
	const { objectSchema, objectTypesById } = schemaPageData;
	// Note: add button is rendered as a sidebar item instead of here if there are no object types
	const showAddObjectTypeButton =
		canCreateObjectType(schemaPageData, null) && Object.keys(objectTypesById).length > 0;
	const schemaGraphButtonDisabled = Object.keys(objectTypesById).length === 0;
	const showExpandCollapseButton = expandedDetails.hasChildNodes;

	const onObjectTypeCreate = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'openCreateInsightObjectTypeDialog');
			setObjectTypeModalState({ type: 'creating', initialParent: null });
		},
		[setObjectTypeModalState],
	);

	/**
	 * Notice that setObjectTypeModalState is called to set state in the store, which is then used to render the create
	 * object type modal within the page content, rather than the sidebar.
	 * This is to work-around an Atlaskit bug - https://product-fabric.atlassian.net/browse/DSP-1821.
	 */
	const renderSchemaGraphButton = useCallback(() => {
		const button = (
			<ButtonItem
				onClick={() => {
					fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'openInsightSchemaGraph');
					openSchemaGraph();
				}}
				iconBefore={<ObjectGraphIcon label="" />}
				testId="servicedesk-insight-object-schema-page.common.ui.side-navigation.loaded.header.schema-graph"
				isDisabled={schemaGraphButtonDisabled}
			>
				{formatMessage(messages.schemaGraph)}
			</ButtonItem>
		);

		if (schemaGraphButtonDisabled) {
			return (
				<Tooltip content={formatMessage(messages.schemaGraphDisabledTooltip)}>{button}</Tooltip>
			);
		}

		return button;
	}, [formatMessage, openSchemaGraph, schemaGraphButtonDisabled, createAnalyticsEvent]);

	const renderExpandCollapseButton = useCallback(() => {
		if (expandedDetails.hasExpanded) {
			return (
				<Tooltip content={formatMessage(messages.collapseAllTooltip)}>
					<Button
						appearance="subtle"
						iconBefore={
							<ExpandCollapseIconWrapper>
								<EditorCollapseIcon label="" />
							</ExpandCollapseIconWrapper>
						}
						onClick={() => {
							fireUIAnalytics(
								createAnalyticsEvent({}),
								'button clicked',
								'collapseInsightSchemaTree',
							);
							setAllIsExpanded(false);
						}}
						testId="servicedesk-insight-object-schema-page.common.ui.side-navigation.loaded.header.collapse-schema-tree"
					/>
				</Tooltip>
			);
		}
		return (
			<Tooltip content={formatMessage(messages.expandAllTooltip)}>
				<Button
					appearance="subtle"
					iconBefore={
						<ExpandCollapseIconWrapper>
							<EditorExpandIcon label="" />
						</ExpandCollapseIconWrapper>
					}
					onClick={() => {
						fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'expandInsightSchemaTree');
						setAllIsExpanded(true);
					}}
					testId="servicedesk-insight-object-schema-page.common.ui.side-navigation.loaded.header.expand-schema-tree"
				/>
			</Tooltip>
		);
	}, [expandedDetails.hasExpanded, formatMessage, createAnalyticsEvent, setAllIsExpanded]);

	return (
		<>
			{!getWillShowNav4() && (
				<SchemaNameContainer>
					<SchemaIcon label={formatMessage(messages.logoAltText)} size="medium" />
					<Heading size="small" as="h3">
						<Box
							as="span"
							testId="servicedesk-insight-object-schema-page.common.ui.side-navigation.loaded.header.schema-name"
							xcss={schemaNameStyles}
						>
							{objectSchema.name}
						</Box>
					</Heading>
				</SchemaNameContainer>
			)}
			<Section>{renderSchemaGraphButton()}</Section>
			<Section>
				{canViewSchemaSettings(schemaPageData) && (
					<SidebarCustomLinkItem
						iconBefore={<SettingsIcon label="" />}
						to={createObjectSchemaConfigUrl(objectSchema.id)}
					>
						{formatMessage(messages.schemaSettings)}
					</SidebarCustomLinkItem>
				)}
			</Section>
			<SchemaTreeHeaderContainer showExpandCollapseButton={showExpandCollapseButton}>
				<Heading level="h100" as={getWillShowNav4() ? 'h3' : 'h4'}>
					<Box as="span" xcss={schemaTreeTextStyles}>
						{formatMessage(messages.schemaTree)}
					</Box>
				</Heading>
				{showExpandCollapseButton && renderExpandCollapseButton()}
				{showAddObjectTypeButton && (
					<Button
						appearance="subtle"
						iconBefore={<EditorAddIcon label={formatMessage(commonMessages.addObjectType)} />}
						onClick={(_, analyticsEvent) => onObjectTypeCreate(analyticsEvent)}
						testId="servicedesk-insight-object-schema-page.common.ui.side-navigation.loaded.header.add-object-type"
					/>
				)}
			</SchemaTreeHeaderContainer>
		</>
	);
};

const schemaNameStyles = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	width: '100%',
});

const schemaTreeTextStyles = xcss({
	textTransform: 'uppercase',
	color: 'color.text', // token('color.background.neutral.bold', '#44546F'),
	paddingInline: 'space.100', // token('space.100', `8px`),
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SchemaNameContainer = styled.div({
	// Same left and right padding of ButtonItem needs to be applied here so it lines up with the actions below
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingTop: token('space.300', `${gridSize * 4}px`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingBottom: token('space.200', `${gridSize * 2}px`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	paddingInline: token('space.100', `${gridSize}px`),
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	alignItems: 'center',
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& h3': {
		overflow: 'hidden',
		width: '100%',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SchemaTreeHeaderContainer = styled.div<{ showExpandCollapseButton: boolean }>({
	display: 'grid',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridTemplateColumns: (props) => (props.showExpandCollapseButton ? '1fr auto auto' : '1fr auto'),
	alignItems: 'center',
	paddingBottom: token('space.100', '8px'),
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& h4': {
		overflow: 'hidden',
		width: '100%',
	},
});

// Rotates the SVG icon 90 degrees
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpandCollapseIconWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		transform: 'rotate(90deg)',
	},
});
