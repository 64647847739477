import {
	type ResponseRole,
	type CmdbIcon,
	type ObjectType as ObjectTypeResponse,
	Role,
	toCmdbObjectTypeId,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import type { ObjectTypesById, ObjectType } from '../types';

export type OptionType = {
	value: string;
	label: string;
	icon: CmdbIcon;
};

export const indexObjectTypesById = (objectTypes: ObjectType[]) => {
	const result: ObjectTypesById = {};
	objectTypes.forEach((objectType) => {
		result[objectType.id] = objectType;
	});
	return result;
};

export const sortObjectTypesById = (objectTypesById: ObjectTypesById) =>
	Object.keys(objectTypesById).reduce<ObjectType[]>((entries, key: string) => {
		const objectType: ObjectType = objectTypesById[key];

		if (objectType.parentObjectTypeId == null) {
			entries.push(objectType);
			return entries;
		}

		const newEntries: Array<ObjectType> = [];
		const index = entries.findIndex(({ id }) => id === objectType.parentObjectTypeId);

		entries.slice(0, index + 1).forEach((entry) => {
			newEntries.push(entry);
		});
		newEntries.push(objectType);
		entries.slice(index + 1).forEach((entry) => {
			newEntries.push(entry);
		});

		return newEntries;
	}, []);

export const transformResponseRoleToCurrentUserRole = (role: ResponseRole): Role => {
	if (role === 'SCHEMA_MANAGER' || role === 'OBJECT_TYPE_MANAGER') {
		return Role.MANAGER;
	}
	if (role === 'SCHEMA_DEVELOPER' || role === 'OBJECT_TYPE_DEVELOPER') {
		return Role.DEVELOPER;
	}
	return Role.USER;
};

/**
 * Extracts the required properties from objectTypeResponse and adds improved typing.
 */
export const transformObjectTypeResponse = (
	// Explicitly extract the attributes that are used, to avoid adding in unused attributes
	{
		id,
		name,
		icon,
		position,
		objectCount,
		// @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'string'.
		parentObjectTypeId = null,
		abstractObjectType,
		parentObjectTypeInherited,
		inherited,
	}: ObjectTypeResponse,
	role: ResponseRole,
): ObjectType => ({
	id: toCmdbObjectTypeId(id),
	name,
	icon,
	position,
	objectCount,
	parentObjectTypeId:
		parentObjectTypeId !== null ? toCmdbObjectTypeId(parentObjectTypeId) : parentObjectTypeId,
	abstractObjectType,
	parentObjectTypeInherited,
	inherited,
	currentUserRole: transformResponseRoleToCurrentUserRole(role),
});
