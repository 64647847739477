export type ValidatedIql = string;
export const toValidatedIql = (value: string): ValidatedIql => value;

type LoadingResult = {
	type: 'loading';
};
export type ValidResult = {
	type: 'valid';
	validatedIql: ValidatedIql;
};
export type InvalidResult = {
	type: 'invalid';
	error: string;
};
export type IqlValidationResult = LoadingResult | ValidResult | InvalidResult;

export type IqlValidationResponse = {
	isValid: boolean;
	errorMessages: string[];
};
