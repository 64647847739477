import { ff } from '@atlassian/jira-feature-flagging';
import type { ObjectFilterEntry } from '@atlassian/jira-servicedesk-insight-object-types';
import { DefaultAttributeSubtypeMap } from '@atlassian/jira-servicedesk-insight-shared-types';
import {
	type AvatarColumnTarget,
	matchSearchState,
	type State,
	type Column,
	type AttributeFiltersByName,
	type OrderBy,
	type SearchResultObjectTypeAttribute,
	type SearchRequest,
	type SearchState,
	type SearchStateValue,
	type Pagination,
} from '../../common/types';
import type { ValueMatch } from '../../common/types/filters';
import { isSameColumn, extractOrderBy } from '../../common/utils';

export const ICON_COLUMN_TARGET: AvatarColumnTarget = {
	type: 'avatar',
};

/**
 * Provides the list of ordered columns.
 */
export const getColumns = ({ selectedColumns, lastSearch }: State): Column[] =>
	matchSearchState(lastSearch, {
		loading: () => [],
		error: () => [],
		success: ({ result }) => {
			const attributes = result.attributes.map((attr) => ({
				...attr,
				type: 'attribute' as const,
			}));

			const columns = [ICON_COLUMN_TARGET, ...attributes];

			const unselectedColumns = columns.filter((column) => {
				const unselectedColumn = selectedColumns.find((selectedColumn) =>
					isSameColumn(selectedColumn, column),
				);
				return !unselectedColumn;
			});
			const selectedColumnList = selectedColumns
				.map((selectedColumn) =>
					columns.filter((columnItem) => isSameColumn(selectedColumn, columnItem)),
				)
				.flat();

			const updatedColumnList = [...selectedColumnList, ...unselectedColumns];

			return updatedColumnList.map((column) => {
				const isSelected = !!selectedColumns.find((selectedColumn) =>
					isSameColumn(selectedColumn, column),
				);
				const isIcon = column.type === 'avatar';
				const target = isIcon
					? ICON_COLUMN_TARGET
					: {
							id: column.id,
							name: column.name,
							isLabel: column.isLabel,
							type: column.type,
							isSummable: column.isSummable,
							attributeType: column.attributeType,
							defaultType: column.defaultType,
							maximumCardinality: column.maximumCardinality,
						};

				return {
					isSelected,
					target,
				};
			});
		},
	});

export type AttributeFilters = {
	allAttributeNames: string[];
	filters: AttributeFiltersByName;
	qlQuery: string;
};

const EMPTY_ATTRIBUTE_FILTERS: AttributeFilters = {
	allAttributeNames: [],
	filters: {},
	qlQuery: '',
};
export const getAttributeFilters = ({ lastSearch }: State): AttributeFilters =>
	matchSearchState(lastSearch, {
		loading: () => EMPTY_ATTRIBUTE_FILTERS,
		error: () => EMPTY_ATTRIBUTE_FILTERS,
		success: ({ result }) => {
			const { attributes, filters, qlQuery } = result;
			const allAttributeNames = ff(
				'assets-rearch-filter-out-textarea-attributes-for-aql-searches_gf709',
			)
				? attributes
						.filter(
							(attr) => attr.defaultType?.id?.toString() !== DefaultAttributeSubtypeMap.Textarea,
						)
						.map(({ name }) => name)
				: attributes.map(({ name }) => name);

			return {
				allAttributeNames,
				filters,
				qlQuery,
			};
		},
	});

export const getObjectTypeFilters = ({ lastSearch }: State): ValueMatch<string>[] =>
	matchSearchState(lastSearch, {
		loading: () => [],
		error: () => [],
		success: ({ result }) => {
			const { objectTypes } = result;
			if (!objectTypes) return [];
			return objectTypes.selectedValues;
		},
	});

export const getNumFilteredAttributes = (state: State) => {
	const { searchMode } = state;
	const { filters, qlQuery } = getAttributeFilters(state);

	if (searchMode === 'basic') {
		const selectedObjectValues = getObjectTypeFilters(state);
		// TODO - the hasSelectedValues variable is currently filtering out wildcards for object type searches because
		// the backend doesn't support this at the moment. Once the backend supports objecttype wildcard/"like" searches,
		// this code can stop filtering out wildcards
		// https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/83963/overview
		const hasSelectedValues =
			selectedObjectValues.length &&
			selectedObjectValues.filter((item) => item.type === 'wildcard').length !==
				selectedObjectValues.length
				? 1
				: 0;
		return Object.keys(filters).length + hasSelectedValues;
	}

	// if there's a qlQuery applied, count it as 1 filter
	return qlQuery !== '' ? 1 : 0;
};

export type SearchType = 'search' | 'refresh';
/**
 * Indicates if the current search is a regular search, a refresh, or null = not currently searching.
 */
export const getPendingSearchType = ({ lastSearch, pendingSearch }: State): SearchType | null =>
	matchSearchState(lastSearch, {
		loading: () => 'search',
		error: () => null,
		success: () => {
			if (pendingSearch !== null) {
				return pendingSearch.isRefresh ? 'refresh' : 'search';
			}
			return null;
		},
	});

export const getCanConvertQlQueryToFilter = ({ lastSearch }: State): boolean =>
	matchSearchState(lastSearch, {
		loading: () => false,
		error: () => false,
		success: ({ result }) => result.canConvertQlQueryToFilter,
	});

export const userCanFilterByObjectType = ({ lastSearch }: State): boolean =>
	matchSearchState(lastSearch, {
		loading: () => false,
		error: () => false,
		success: ({ result }) => result.objectTypeIsInherited,
	});

export const getOrderBy = ({ lastSearch, pendingSearch }: State): OrderBy | null =>
	matchSearchState(lastSearch, {
		loading: () => null,
		error: () => null,
		success: ({ result }) => {
			if (pendingSearch === null) {
				return result.orderBy;
			}

			const pendingSearchRequest = pendingSearch.request;
			// Use pendingSearchRequest to optimistically show the new sort order for the pending request
			if (pendingSearchRequest.query.type === 'qlQuery') {
				const { qlQuery } = pendingSearchRequest.query;
				const { orderBy } = extractOrderBy(qlQuery);
				if (orderBy === null) {
					return null;
				}

				const orderByAttribute = result.attributes.find(
					({ name }) => name === orderBy.attributeName,
				);
				if (!orderByAttribute) {
					return result.orderBy;
				}
				return {
					...orderBy,
					objectTypeAttributeId: orderByAttribute.id,
				};
			}
			return pendingSearchRequest.query.orderBy;
		},
	});

export const getObjectTypeAttribute = (
	{ lastSearch }: State,
	attributeName: string,
): SearchResultObjectTypeAttribute | null =>
	matchSearchState(lastSearch, {
		loading: () => null,
		error: () => null,
		success: ({ result }) => {
			const { attributes } = result;
			return attributes.find(({ name }) => name === attributeName) || null;
		},
	});

export const getObjectTypeEntries = ({ lastSearch }: State): ObjectFilterEntry[] =>
	matchSearchState(lastSearch, {
		loading: () => [],
		error: () => [],
		success: ({ result }) => {
			const { objectTypes } = result;
			if (objectTypes === null) return [];
			return objectTypes.entries;
		},
	});

const isRequestForAllObjects = ({ query }: SearchRequest): boolean => {
	switch (query.type) {
		case 'qlQuery':
			return String(query.qlQuery).trim().length === 0;
		case 'filter':
			return Object.keys(query.filters).length === 0 && query.objectTypes.length === 0;
		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(query as never);
			return false;
	}
};

export const objectTypeHasNoObjects = (searchState: SearchState): boolean =>
	matchSearchState(searchState, {
		loading: () => false,
		error: () => false,
		success: ({ result, request }: SearchStateValue) =>
			result.objects.length === 0 && isRequestForAllObjects(request),
	});

const EMPTY_PAGINATION: Pagination = {
	hasNextPage: false,
	hasPrevPage: false,
	numObjects: 0,
	numPages: 1,
	hasMoreResults: false,
	uncappedNumObjects: undefined,
	pageNumber: 1,
	startIndex: 1,
	toIndex: 1,
};
export const getPagination = ({ lastSearch }: State): Pagination =>
	matchSearchState(lastSearch, {
		loading: () => EMPTY_PAGINATION,
		error: () => EMPTY_PAGINATION,
		success: ({ result }) => {
			const {
				pageNumber,
				numObjects,
				numPages,
				startIndex,
				toIndex,
				hasMoreResults,
				uncappedNumObjects,
			} = result;

			return {
				hasPrevPage: pageNumber > 1,
				hasNextPage: pageNumber < numPages,
				numObjects,
				numPages,
				hasMoreResults,
				uncappedNumObjects,
				pageNumber,
				startIndex,
				toIndex,
			};
		},
	});

export const getLastSearch = (state: State): SearchStateValue | undefined =>
	matchSearchState(state.lastSearch, {
		error: () => undefined,
		loading: () => undefined,
		success: (search) => search,
	});
