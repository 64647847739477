/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import Tree, {
	type RenderItemParams,
	type TreeDestinationPosition,
	type TreeSourcePosition,
} from '@atlaskit/tree';
import { isValidationError } from '@atlassian/jira-fetch';
import { useFlagsService } from '@atlassian/jira-flags';
import { createObjectTypeExperience } from '@atlassian/jira-servicedesk-insight-experiences';
import {
	useInsightObjectSchemaPageData,
	useLoadedSchemaPageData,
	useSchemaPageUiState,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import { ExperienceSuccess } from '@atlassian/ufo';
import { useObjectTypeTree, MoveObjectTypeError } from '../../../services';
import { messages } from './messages';
import { ObjectTypeLink } from './object-type-link';

export const ObjectTypeNavItems = () => {
	const [{ lastCreatedObjectTypeId }] = useInsightObjectSchemaPageData();
	const [{ objectTypesById, objectSchema, schemaGlobalConfig }] = useLoadedSchemaPageData();
	const [{ selectedObjectTypeId }] = useSchemaPageUiState();
	const { tree, setIsExpanded, moveObjectType } = useObjectTypeTree();
	const { showFlag } = useFlagsService();

	const renderItem = ({ item, onExpand, onCollapse, provided }: RenderItemParams) => {
		const { isExpanded = true } = item;
		const objectType = objectTypesById[item.id];
		const { id } = objectType;
		const { draggableProps, dragHandleProps } = provided;

		// @ts-expect-error - Property 'paddingLeft' does not exist on type 'DraggingStyle'
		const paddingLeftCopy = draggableProps.style.paddingLeft;

		// @ts-expect-error - Property 'paddingLeft' does not exist on type 'DraggingStyle'
		draggableProps.style.paddingLeft = 0;

		return (
			<div
				ref={provided.innerRef}
				{...draggableProps}
				{...dragHandleProps}
				// we set the tabindex to -1 to prevent double item selection that occurs during tabbing
				// this override is needed due to how we want to style the sidebar.
				// if removing, ensure you can tab between items (without double tabbing) + no additional borders appear
				tabIndex={-1}
				css={styles}
			>
				<ObjectTypeLink
					objectType={objectType}
					isSelected={id === selectedObjectTypeId}
					isExpandable={item.children.length > 0}
					isExpanded={isExpanded}
					onExpand={() => onExpand(id)}
					onCollapse={() => onCollapse(id)}
					leftIndentation={paddingLeftCopy}
				/>
				{id === lastCreatedObjectTypeId && (
					<ExperienceSuccess experience={createObjectTypeExperience} />
				)}
			</div>
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getErrorDescription = (err: any) => {
		if (!(err && isValidationError(err))) {
			return messages.moveErrorDescription;
		}
		if (err.message === MoveObjectTypeError.NOT_MOVABLE_TO_INHERITED_TREE) {
			return messages.notMovableToInheritedTreeErrorMessage;
		}
		if (err.message === MoveObjectTypeError.NOT_MOVABLE_WHEN_INHERITED) {
			return messages.inheritedObjectTypeNotMovableErrorMessage;
		}
		return err.message;
	};

	const onDragEnd = (
		sourcePosition: TreeSourcePosition,
		destinationPosition: TreeDestinationPosition | null = null,
	) => {
		if (destinationPosition === null) {
			return;
		}

		moveObjectType({
			fromParentItemId: sourcePosition.parentId,
			fromIndex: sourcePosition.index,
			toParentItemId: destinationPosition.parentId,
			toIndex: destinationPosition.index ?? 0,
		}).catch((error) => {
			showFlag({
				type: 'error',
				title: messages.moveErrorTitle,
				description: getErrorDescription(error),
				isAutoDismiss: true,
			});
		});
	};

	const isDraggable = !objectSchema.isRestricted && schemaGlobalConfig.insightManager;

	return (
		// @ts-expect-error - TS2769 - No overload matches this call.
		<Tree
			tree={tree}
			renderItem={renderItem}
			onDragEnd={onDragEnd}
			onExpand={(objectTypeId: string) => setIsExpanded(objectTypeId, true)}
			onCollapse={(objectTypeId: string) => setIsExpanded(objectTypeId, false)}
			isDragEnabled={isDraggable}
			isNestingEnabled
			// setting a defined offset, this will allow us to vertically centre the start
			// of each item to the parent item's icon
			offsetPerLevel={31}
		/>
	);
};
const styles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	'&:focus-visible': { outline: '0 !important', boxShadow: 'none !important' },
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	'&:focus': { outline: '0 !important', boxShadow: 'none !important' },
});
