import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import { InsightObjectViewSkeleton } from '@atlassian/jira-skeletons';
import type { ObjectViewProps } from './common/types';

const LazyObjectView = lazyForPaint(
	() => import(/* webpackChunkName: "insight-object-view" */ './main'),
);

export const AsyncObjectView = (props: ObjectViewProps) => (
	<Placeholder name="insight-object-view" fallback={<InsightObjectViewSkeleton />}>
		<LazyObjectView {...props} />
	</Placeholder>
);
