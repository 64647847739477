import type { AsyncBulkAction } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import messages from '../../../common/messages';
import type { CmdbAsyncActionStoreAction } from '../../types';

type Props = {
	action: AsyncBulkAction;
};

export const showErrorFlag =
	({ action }: Props): CmdbAsyncActionStoreAction =>
	async (_, { showFlag, formatMessage }) => {
		showFlag({
			type: 'error',
			title: formatMessage(messages.submitErrorFlagTitle, {
				action,
			}),
			description: formatMessage(messages.submitErrorFlagDescription),
		});
	};
