import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	pagination: {
		id: 'servicedesk-insight-object-schema-page.common.objects-pagination-label.pagination',
		defaultMessage:
			'{startIndex, number}-{toIndex, number} of {numObjects, plural, one {# Object} other {# Objects}}',
		description:
			'Label indicating the objects pagination current range and their its total numbers',
	},
	paginationSimplified: {
		id: 'servicedesk-insight-object-schema-page.common.objects-pagination-label.pagination-simplified',
		defaultMessage: '{startIndex, number}-{toIndex, number} of {numObjects, number}',
		description:
			'Label indicating the objects pagination current range and their its total numbers',
	},
	objectsCount: {
		id: 'servicedesk-insight-object-schema-page.common.objects-pagination-label.objects-count',
		defaultMessage: '{numObjects, plural, =0 {No Objects} one {# Object} other {# Objects}}',
		description: 'Label showing the number of objects listed, e.g. "1 Object" or "25 Objects"',
	},
});
