import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noObjectsToDisplay: {
		id: 'servicedesk-insight-object-schema-page.common.no-filter-results.no-objects-to-display',
		defaultMessage: 'There are no objects to display from this filter',
		description:
			'Message displayed to the user when there are no objects matching their search filter',
	},
});
