import { fg } from '@atlassian/jira-feature-gating';
import type { CmdbObjectTypeId } from '@atlassian/jira-servicedesk-insight-shared-types';
import { getCmdbAnalyticAttributesFromSchema } from '@atlassian/jira-servicedesk-insight-shared-utils';
import { isDeveloperOrGreater, isManager } from '@atlassian/jira-servicedesk-insight-test-utils';
import type {
	ErrorState,
	LoadingState,
	ObjectType,
	ObjectTypesById,
	PageDataState,
	SchemaPageData,
	State,
	SuccessState,
} from '../../common/types';

export const matchPageDataState = <T,>(
	pageDataState: PageDataState,
	handlers: {
		loading: (loading: LoadingState) => T;
		error: (error: ErrorState) => T;
		success: (success: SuccessState) => T;
	},
): T => {
	switch (pageDataState.type) {
		case 'loading':
			return handlers.loading(pageDataState);
		case 'error':
			return handlers.error(pageDataState);
		case 'success':
			return handlers.success(pageDataState);
		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(pageDataState as never);
			// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'.
			throw new Error(`Unexpected state type: ${pageDataState.type}`);
	}
};

export const getSchemaPageDataOrThrowError = ({ pageDataState }: State) =>
	matchPageDataState<SchemaPageData>(pageDataState, {
		loading: () => {
			throw new Error(
				'Unable to load page data, useLoadedSchemaPageData called while in loading state.',
			);
		},
		error: () => {
			throw new Error(
				'Unable to load page data, useLoadedSchemaPageData called while in error state.',
			);
		},
		success: ({ type, ...rest }) => rest,
	});

export const objectTypesToArray = (objectTypesById: ObjectTypesById): ObjectType[] =>
	Object.keys(objectTypesById).map((key) => objectTypesById[key]);

export const getOrderedChildObjectTypes = ({
	parentObjectTypeId,
	objectTypesById,
}: {
	parentObjectTypeId: CmdbObjectTypeId | null;
	objectTypesById: ObjectTypesById;
}): ObjectType[] =>
	objectTypesToArray(objectTypesById)
		.filter((objectType) => objectType.parentObjectTypeId === parentObjectTypeId)
		.sort((first, second) => first.position - second.position);

export const canCreateObjectType = (
	{ objectTypesById, objectSchema, schemaGlobalConfig }: SchemaPageData,
	// Null object type means creating at the top (schema) level
	parentObjectTypeId: CmdbObjectTypeId | null,
): boolean => {
	if (objectSchema.isRestricted) {
		return false;
	}
	if (parentObjectTypeId === null) {
		return schemaGlobalConfig.insightManager;
	}
	if (!objectTypesById[parentObjectTypeId]) {
		return false;
	}
	return isManager(objectTypesById[parentObjectTypeId].currentUserRole);
};

export const canCloneObjectType = (
	{ objectTypesById, objectSchema, schemaGlobalConfig }: SchemaPageData,
	objectTypeIdToClone: CmdbObjectTypeId,
): boolean => {
	if (objectSchema.isRestricted) {
		return false;
	}
	// Clone requires manager role at 2 levels:
	// - The object type being cloned
	// - The parent of the object type being cloned (the object type or the schema if the object type is a root-level one)
	const objectTypeToClone = objectTypesById[objectTypeIdToClone];
	if (!isManager(objectTypeToClone.currentUserRole)) {
		return false;
	}

	if (objectTypeToClone.parentObjectTypeId === null) {
		return schemaGlobalConfig.insightManager;
	}

	// If the object type has a parent but parent object type is not accessible
	if (!(objectTypeToClone.parentObjectTypeId in objectTypesById)) {
		return false;
	}
	return isManager(objectTypesById[objectTypeToClone.parentObjectTypeId].currentUserRole);
};

export const canManageObjectType = (
	{ objectTypesById, objectSchema }: SchemaPageData,
	objectTypeId: CmdbObjectTypeId,
): boolean => {
	const objectType = objectTypesById[objectTypeId];
	if (fg('fix_sentry_undefined_currentuserrole_error')) {
		return !!objectType && isManager(objectType.currentUserRole) && !objectSchema.isRestricted;
	}
	return isManager(objectType.currentUserRole) && !objectSchema.isRestricted;
};

export const isAbstractObjectType = (
	{ objectTypesById }: SchemaPageData,
	selectedObjectTypeId: CmdbObjectTypeId,
): boolean => {
	const { abstractObjectType } = objectTypesById[selectedObjectTypeId];

	return abstractObjectType;
};

export const isParentObjectTypeInherited = (
	{ objectTypesById }: SchemaPageData,
	selectedObjectTypeId: CmdbObjectTypeId,
): boolean => {
	const { parentObjectTypeInherited } = objectTypesById[selectedObjectTypeId];

	return parentObjectTypeInherited;
};

export const isObjectTypeInherited = (
	{ objectTypesById }: SchemaPageData,
	selectedObjectTypeId: CmdbObjectTypeId,
): boolean => {
	const { inherited } = objectTypesById[selectedObjectTypeId];

	return inherited;
};

export const hasParentObjectType = (
	{ objectTypesById }: SchemaPageData,
	selectedObjectTypeId: CmdbObjectTypeId,
): boolean => {
	const { parentObjectTypeId } = objectTypesById[selectedObjectTypeId];

	return parentObjectTypeId !== null;
};

export const canUpdateObjectType = canManageObjectType;
export const canDeleteObjectType = canManageObjectType;
export const canViewObjectTypeSettings = canManageObjectType;

export const canViewSchemaSettings = ({
	objectSchema,
	schemaGlobalConfig,
}: SchemaPageData): boolean => schemaGlobalConfig.insightManager && !objectSchema.isRestricted;

export const canCreateObject = (
	{ objectSchema, objectTypesById }: SchemaPageData,
	selectedObjectTypeId: CmdbObjectTypeId,
): boolean => {
	const { currentUserRole, abstractObjectType } = objectTypesById[selectedObjectTypeId];

	return isDeveloperOrGreater(currentUserRole) && !objectSchema.isRestricted && !abstractObjectType;
};

export const canDeleteObject = (
	{ objectSchema, objectTypesById }: SchemaPageData,
	selectedObjectTypeId: CmdbObjectTypeId,
): boolean => {
	const { currentUserRole } = objectTypesById[selectedObjectTypeId];

	return isManager(currentUserRole) && !objectSchema.isRestricted;
};
export const canEditObject = canCreateObject;

export const canAccessAttributes = (
	{ objectSchema, objectTypesById }: SchemaPageData,
	selectedObjectTypeId: CmdbObjectTypeId,
): boolean => {
	const { currentUserRole } = objectTypesById[selectedObjectTypeId];

	return isManager(currentUserRole) && !objectSchema.isRestricted;
};

export const getCmdbAnalyticAttributes = ({ objectSchema }: SchemaPageData) =>
	getCmdbAnalyticAttributesFromSchema(objectSchema);
