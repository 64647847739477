import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types';
import { getCmdbRestBaseUrl } from './index';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/describe
 */
const getCmdbDescribeUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/describe`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/describe/roles
 */
export const getCmdbDescribeRolesUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbDescribeUrl(workspaceId)}/roles`;
