export const createTypesafeErrorForAnalytics = (error: unknown) => {
	let isSafeForAnalytics = false;
	const typesafeError = error instanceof Error ? error : new Error('Unknown error');
	try {
		JSON.parse(typesafeError.message);
		isSafeForAnalytics = true;
	} catch (e) {
		isSafeForAnalytics = false;
	}
	return {
		error: typesafeError,
		isSafeForAnalytics,
	};
};
