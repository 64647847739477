import React, {
	type ReactNode,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from 'react';
import type { ItemId, TreeData, TreeItem } from '@atlaskit/tree';
import { ValidationError } from '@atlassian/jira-fetch';
import {
	type ObjectTypesById,
	objectTypesToArray,
	useLoadedSchemaPageData,
} from '@atlassian/jira-servicedesk-insight-object-schema-page-store';
import {
	type CmdbObjectTypeId,
	toCmdbObjectTypeId,
} from '@atlassian/jira-servicedesk-insight-shared-types';

export const MoveObjectTypeError = {
	NOT_MOVABLE_TO_INHERITED_TREE: 'Not possible to move to inherited object type tree.',
	NOT_MOVABLE_WHEN_INHERITED: 'Not possible to move if inheritance is enabled.',
} as const;

export type MoveObjectTypeError = (typeof MoveObjectTypeError)[keyof typeof MoveObjectTypeError];

type Update = {
	objectTypeId: CmdbObjectTypeId;
	newParentObjectTypeId: CmdbObjectTypeId | null;
	newPosition: number;
};
type LocalUpdates = {
	[key: string]: Update[];
};
type LocalUpdateActions = {
	addUpdateGroup: (newUpdates: Update[]) => string;
	deleteUpdateGroup: (idToDelete: string) => void;
};
type ExpandStates = { [key: string]: boolean };

type TreeDataItemsById = {
	[key: string]: TreeItem;
};

export type ObjectTypeTree = {
	tree: TreeData;
	expandedDetails: { hasExpanded: boolean; hasChildNodes: boolean };
	setIsExpanded: (objectTypeId: string, isExpanded: boolean) => void;
	setAllIsExpanded: (isExpanded: boolean) => void;
	moveObjectType: (params: {
		// May be '__root__' rather than an object type ID
		fromParentItemId: ItemId;
		fromIndex: number;
		// May be '__root__' rather than an object type ID
		toParentItemId: ItemId;
		toIndex: number;
	}) => Promise<void>;
};

const useLocalUpdates = (): [LocalUpdates, LocalUpdateActions] => {
	// State for optimistic local updates of positions while they are still being persisted. Keyed by update ID.
	const [localUpdates, setLocalUpdates] = useState<LocalUpdates>({});
	const nextUpdateId = useRef<number>(0);
	const addUpdateGroup = (newUpdates: Update[]) => {
		const currentUpdateId = String(nextUpdateId.current);
		nextUpdateId.current += 1;
		setLocalUpdates((prevLocalUpdates) => ({
			...prevLocalUpdates,
			[currentUpdateId]: newUpdates,
		}));
		return currentUpdateId;
	};

	const deleteUpdateGroup = (idToDelete: string) => {
		setLocalUpdates((prevLocalUpdates) => {
			const result = { ...prevLocalUpdates };
			delete result[idToDelete];
			return result;
		});
	};

	return [localUpdates, { addUpdateGroup, deleteUpdateGroup }];
};

export const getExpandedDetails = (tree: TreeData) => {
	let hasExpanded = false;
	let hasChildNodes = false;
	if (!tree.items[tree.rootId].hasChildren) {
		return { hasExpanded, hasChildNodes };
	}
	// We only need to look at the root level children
	const rootChildrenIds = tree.items[tree.rootId].children;
	rootChildrenIds.forEach((itemId) => {
		const childNode = tree.items[itemId];
		// isExpanded is true for nodes without children so need to check hasChildren as well
		if (childNode.hasChildren && childNode.isExpanded) {
			hasExpanded = true;
		}
		if (childNode.hasChildren) {
			hasChildNodes = true;
		}
	});
	return { hasExpanded, hasChildNodes };
};

// Atlaskit tree needs a virtual root element that doesn't actually get rendered
export const ROOT_ID = '__root__';

const DEFAULT_EXPAND_STATE = true;

export const ObjectTypeTreeContext = createContext<ObjectTypeTree>({
	tree: {
		rootId: ROOT_ID,
		items: {},
	},
	expandedDetails: { hasExpanded: false, hasChildNodes: false },
	setIsExpanded: () => undefined,
	setAllIsExpanded: () => undefined,
	moveObjectType: () => Promise.resolve(undefined),
});

export const ObjectTypeTreeStateProvider = ({ children }: { children: ReactNode }) => {
	const [{ objectTypesById }, { updateObjectTypePosition }] = useLoadedSchemaPageData();
	const [localUpdates, { addUpdateGroup, deleteUpdateGroup }] = useLocalUpdates();

	const [expandStates, setExpandStates] = useState<ExpandStates>({});
	const getIsExpanded = useCallback(
		(objectTypeId: string): boolean => {
			const isExpanded = expandStates[objectTypeId];
			return isExpanded !== undefined ? isExpanded : DEFAULT_EXPAND_STATE;
		},
		[expandStates],
	);
	const setIsExpanded = useCallback(
		(objectTypeId: string, isExpanded: boolean) =>
			setExpandStates((prevState) => ({
				...prevState,
				[objectTypeId]: isExpanded,
			})),
		[setExpandStates],
	);
	const setAllIsExpanded = useCallback(
		(isExpanded: boolean) => {
			const objectTypeIds = Object.keys(objectTypesById);
			const newExpandState = objectTypeIds.reduce<ExpandStates>(
				(expandStatesAccum, objectTypeId) => {
					// eslint-disable-next-line no-param-reassign
					expandStatesAccum[objectTypeId] = isExpanded;
					return expandStatesAccum;
				},
				{},
			);
			setExpandStates(newExpandState);
		},
		[setExpandStates, objectTypesById],
	);
	const objectTypesWithLocalUpdates = useMemo<ObjectTypesById>(() => {
		const allUpdates: Update[] = Object.keys(localUpdates).reduce<Update[]>(
			(localUpdatesAcc, localUpdateId) => [
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...localUpdatesAcc,
				...localUpdates[localUpdateId],
			],
			[],
		);
		const result = allUpdates.reduce<ObjectTypesById>((objectTypesAcc, update) => {
			const { objectTypeId, newParentObjectTypeId, newPosition } = update;
			const objectTypeToUpdate = objectTypesAcc[objectTypeId];
			return {
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...objectTypesAcc,
				[String(objectTypeId)]: {
					...objectTypeToUpdate,
					position: newPosition,
					parentObjectTypeId: newParentObjectTypeId,
				},
			};
		}, objectTypesById);

		return result;
	}, [objectTypesById, localUpdates]);

	const getOrderedChildren = ({
		parentObjectTypeId,
	}: {
		parentObjectTypeId: CmdbObjectTypeId | null;
	}): CmdbObjectTypeId[] =>
		objectTypesToArray(objectTypesWithLocalUpdates)
			.filter((objectType) => objectType.parentObjectTypeId === parentObjectTypeId)
			.sort((first, second) => first.position - second.position)
			.map(({ id }) => id);

	// @ts-expect-error - TS7031 - Binding element 'fromParentItemId' implicitly has an 'any' type. | TS7031 - Binding element 'fromIndex' implicitly has an 'any' type. | TS7031 - Binding element 'toParentItemId' implicitly has an 'any' type. | TS7031 - Binding element 'toIndex' implicitly has an 'any' type.
	const moveObjectType = async ({ fromParentItemId, fromIndex, toParentItemId, toIndex }) => {
		const fromParentObjectTypeId =
			fromParentItemId === ROOT_ID ? null : toCmdbObjectTypeId(fromParentItemId);
		const toParentObjectTypeId =
			toParentItemId === ROOT_ID ? null : toCmdbObjectTypeId(toParentItemId);
		const movedObjectTypeId = getOrderedChildren({
			parentObjectTypeId: fromParentObjectTypeId,
		})[fromIndex];
		if (
			objectTypesById[toParentItemId]?.inherited &&
			// allow siblings to change order
			fromParentItemId !== toParentItemId
		) {
			throw new ValidationError(MoveObjectTypeError.NOT_MOVABLE_TO_INHERITED_TREE);
		} else if (
			objectTypesById[movedObjectTypeId].inherited &&
			fromParentItemId !== toParentItemId &&
			// Only throw error If the movedObjectType is inherited and not the root of the inheritance tree
			objectTypesById[fromParentItemId]?.inherited
		) {
			throw new ValidationError(MoveObjectTypeError.NOT_MOVABLE_WHEN_INHERITED);
		}
		const adjacentChildren = getOrderedChildren({
			parentObjectTypeId: toParentObjectTypeId,
		}).filter((id) => id !== movedObjectTypeId);
		const newUpdates = [
			...adjacentChildren.slice(0, toIndex),
			movedObjectTypeId,
			...adjacentChildren.slice(toIndex),
		].map<Update>((objectTypeId, index) => ({
			objectTypeId,
			// Changes the parent for the moved object; does nothing for adjacent children
			newParentObjectTypeId: toParentObjectTypeId,
			newPosition: index,
		}));

		const updateId = addUpdateGroup(newUpdates);
		try {
			await updateObjectTypePosition({
				movedObjectTypeId: toCmdbObjectTypeId(movedObjectTypeId),
				newPosition: toIndex,
				newParentObjectTypeId: toParentObjectTypeId,
			});
		} finally {
			deleteUpdateGroup(updateId);
		}
	};

	const createTreeItems = (): TreeDataItemsById => {
		const objectTypeTreeItems: TreeDataItemsById = objectTypesToArray(objectTypesById).reduce<
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			Record<string, any>
		>((acc: TreeDataItemsById, objectType) => {
			const objectTypeId = objectType.id;
			const treeChildren = getOrderedChildren({ parentObjectTypeId: objectTypeId });

			const treeItemForObjectType: TreeItem = {
				id: objectTypeId,
				children: treeChildren.map<string>((child) => child),
				hasChildren: treeChildren.length > 0,
				isExpanded: getIsExpanded(objectTypeId),
			};
			return {
				// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
				...acc,
				[treeItemForObjectType.id]: treeItemForObjectType,
			};
		}, {});

		const rootChildren = getOrderedChildren({ parentObjectTypeId: null });
		const rootItem: TreeItem = {
			id: ROOT_ID,
			children: rootChildren.map<string>((child) => child),
			hasChildren: rootChildren.length > 0,
			isExpanded: true,
		};
		return {
			[rootItem.id]: rootItem,
			...objectTypeTreeItems,
		};
	};

	const tree: TreeData = {
		rootId: ROOT_ID,
		items: createTreeItems(),
	};

	return (
		<ObjectTypeTreeContext.Provider
			value={{
				tree,
				expandedDetails: getExpandedDetails(tree),
				setIsExpanded,
				setAllIsExpanded,
				moveObjectType,
			}}
		>
			{children}
		</ObjectTypeTreeContext.Provider>
	);
};

export const useObjectTypeTree = () => useContext(ObjectTypeTreeContext);
