import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequest } from '@atlassian/jira-fetch';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getCmdbGlobalConfigUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls';
import type { GlobalConfig } from '@atlassian/jira-servicedesk-insight-global-configuration-store';
import type { ActionApi } from '../../../types';

export const refreshObjectCount =
	({ createAnalyticsEvent }: { createAnalyticsEvent?: CreateUIAnalyticsEvent }): ActionApi =>
	async ({ setState, getState }) => {
		try {
			const currentState = getState();
			const { workspaceId } = currentState;
			const response: GlobalConfig = await performGetRequest(getCmdbGlobalConfigUrl(workspaceId));
			const { limitInfo } = response;

			const { limitState = 'disabled', objectCount, objectLimit } = limitInfo;
			setState({
				...currentState,
				limitState,
				objectCount,
				objectLimit,
			});

			if (createAnalyticsEvent) {
				fireTrackAnalytics(createAnalyticsEvent({}), 'refreshObjectCount succeeded');
			}
		} catch (untypedError) {
			const error: Error | undefined = untypedError instanceof Error ? untypedError : undefined;
			fireErrorAnalytics({
				meta: {
					id: 'refreshObjectCount',
					packageName: 'jiraServicedeskCmdbObjectUsageMessaging',
					teamName: 'krispy-krew',
				},
				error,
			});
		}
	};
