import { startTaskPolling } from '../../actions/start-task-polling';
import type { CmdbAsyncActionStoreAction } from '../../types';

// This is used purely to allow other more critical async tasks to be prioritized before checking for tasks on first container mount
export const INITIAL_FETCH_DELAY = 5000;

export const fetchInitialTasks =
	(): CmdbAsyncActionStoreAction =>
	async ({ getState, dispatch }) => {
		if (!getState().isPolling && !getState().tasks) {
			setTimeout(() => {
				dispatch(startTaskPolling());
			}, INITIAL_FETCH_DELAY);
		}
	};
