import React, { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { useWorkspaceContext } from '@atlassian/jira-servicedesk-insight-common-workspace-context';
import type {
	CmdbObjectId,
	CmdbObjectTypeId,
	SchemaId,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import { type EntityType, trackView } from '../services';

const TrackView = ({ id, entityType }: { id: string; entityType: EntityType }) => {
	di(clearTimeout, setTimeout);

	const { workspaceId } = useWorkspaceContext();
	useEffect(() => {
		if (!workspaceId) {
			return () => undefined;
		}

		// Delay the track request to avoid contention with other requests for initial loading
		const trackTimeoutId = setTimeout(() => {
			trackView({ workspaceId, entityId: id, entityType }).catch((error) => {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'TrackView',
						packageName: 'jiraServicedeskInsightUserHistoryTracker',
						teamName: 'falcons',
					},
					attributes: {
						entityId: id,
						entityType,
					},
				});
			});
		}, 1000);

		return () => clearTimeout(trackTimeoutId);
	}, [workspaceId, id, entityType]);

	return null;
};

export const TrackObjectView = ({ id }: { id: CmdbObjectId }) => (
	<TrackView id={id} entityType="object" />
);
export const TrackObjectTypeView = ({ id }: { id: CmdbObjectTypeId }) => (
	<TrackView id={id} entityType="object-type" />
);
export const TrackObjectSchemaView = ({ id }: { id: SchemaId }) => (
	<TrackView id={id} entityType="object-schema" />
);
