export const ObjectDetailsRenderingContext = {
	Page: 'Page',
	Component: 'Component',
} as const;

export type ObjectDetailsRenderingContext =
	(typeof ObjectDetailsRenderingContext)[keyof typeof ObjectDetailsRenderingContext];

export type Props = {
	context: ObjectDetailsRenderingContext;
	isMultiColumn?: boolean;
};
