import React, { type ReactNode } from 'react';
import Banner from '@atlaskit/banner';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Link from '@atlaskit/link';
import { Box, xcss, media, Text } from '@atlaskit/primitives';
import { N0, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	useObjectUsageDismiss,
	useObjectUsageInfo,
} from '@atlassian/jira-servicedesk-cmdb-object-usage-messaging/src/utils/index.tsx';
import { getFeatureUsagePageUrl } from '@atlassian/jira-servicedesk-insight-urls';
import { messages } from './messages';

type LimitsBannerProps = {
	displayWithOffset?: boolean;
	isObjectView?: boolean;
};

type BannerAppearance = 'warning' | 'error' | 'announcement';

export const LimitsBanner = ({ displayWithOffset, isObjectView = false }: LimitsBannerProps) => {
	const { formatMessage } = useIntl();
	const onDismiss = useObjectUsageDismiss();
	const objectUsageInfo = useObjectUsageInfo('limits-banner');

	if (objectUsageInfo.type === 'none') return null;

	let appearance: BannerAppearance = 'announcement';
	const { userRole, billingPeriod, type } = objectUsageInfo;
	const isAdmin = userRole === 'admin';

	let content: React.JSX.Element | null = null;

	const getFilteredMessageContent = (
		contentToFormat: {
			id: string;
			defaultMessage: string;
			description: string;
		},
		url: string,
	) => (
		<FormattedMessage
			{...contentToFormat}
			values={{
				p: (msg: ReactNode[]) => <p>{msg}</p>,
				a: (msg: ReactNode[]) => (
					<Link appearance="subtle" href={url}>
						{msg}
					</Link>
				),
				b: (msg: ReactNode[]) => <Text weight="bold">{msg}</Text>,
			}}
		/>
	);

	const increaseUsageUrl = getFeatureUsagePageUrl({ showChangeLimitModal: true });
	const getMonthlyContent = () => {
		switch (type) {
			case '80-percent':
				content = isAdmin
					? getFilteredMessageContent(messages.admin80PercentMonthly, increaseUsageUrl)
					: null;
				break;

			case '90-percent':
				appearance = 'warning';
				content = isAdmin
					? getFilteredMessageContent(messages.admin90PercentMonthly, increaseUsageUrl)
					: getFilteredMessageContent(messages.agent90PercentMonthly, '');
				break;

			case 'limit-reached':
				appearance = 'error';
				content = isAdmin
					? getFilteredMessageContent(messages.adminLimitReachedMonthly, increaseUsageUrl)
					: getFilteredMessageContent(messages.agentLimitReachedMonthly, '');
				break;

			default:
				return null;
		}
	};

	const getYearlyContent = () => {
		switch (type) {
			case '80-percent':
				content = isAdmin
					? getFilteredMessageContent(messages.admin80PercentYearly, increaseUsageUrl)
					: null;
				break;

			case '90-percent':
				appearance = 'warning';
				content = isAdmin
					? getFilteredMessageContent(messages.admin90PercentYearly, increaseUsageUrl)
					: getFilteredMessageContent(messages.agent90PercentYearly, '');
				break;

			case 'limit-reached':
				appearance = 'error';
				content = isAdmin
					? getFilteredMessageContent(messages.adminLimitReachedYearly, increaseUsageUrl)
					: getFilteredMessageContent(messages.agentLimitReachedYearly, '');
				break;

			default:
				return null;
		}
	};

	const getBannerIcon = () => {
		if (type === '90-percent')
			return <WarningIcon label={formatMessage(messages.warningIcon)} secondaryColor="inherit" />;

		if (type === 'limit-reached')
			return <ErrorIcon label={formatMessage(messages.errorIcon)} secondaryColor="inherit" />;

		return undefined;
	};

	switch (billingPeriod) {
		case 'monthly':
			getMonthlyContent();
			break;

		case 'annual':
			getYearlyContent();
			break;

		default:
			return null;
	}

	const getBannerContent = () => (
		<Box xcss={outerBannerWrapper}>
			<Banner
				appearance={appearance}
				icon={getBannerIcon()}
				testId="servicedesk-cmdb-object-usage-messaging.ui.limits-banner"
			>
				<Box xcss={bannerContentWrapper}>{content}</Box>
				<Box xcss={bannerCloseWrapper}>
					<IconButton
						appearance="subtle"
						onClick={onDismiss}
						spacing="compact"
						icon={(iconProps) => (
							// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
							<CrossIcon
								{...iconProps}
								size="medium"
								primaryColor={
									type === '90-percent'
										? token('color.text.warning.inverse', N800)
										: token('color.text.inverse', N0)
								}
							/>
						)}
						label={formatMessage(messages.dismissBanner)}
					/>
				</Box>
			</Banner>
		</Box>
	);

	if (isObjectView) {
		return (
			<Box
				xcss={objectViewOffsetWrapper}
				testId="servicedesk-cmdb-object-usage-messaging.ui.limits-banner.limits-banner-object-view-offset-wrapper"
			>
				<Box xcss={layoutOffsetInner}>{getBannerContent()}</Box>
			</Box>
		);
	}

	return displayWithOffset ? (
		<Box
			xcss={layoutOffsetWrapper}
			testId="servicedesk-cmdb-object-usage-messaging.ui.limits-banner.limits-banner-layout-offset-wrapper"
		>
			<Box xcss={layoutOffsetInner}>{getBannerContent()}</Box>
		</Box>
	) : (
		getBannerContent()
	);
};

const bannerCloseWrapper = xcss({
	position: 'absolute',
	right: 'space.150',
	top: '50%',
	transform: 'translateY(-50%)',
});

const outerBannerWrapper = xcss({
	position: 'relative',
});

const bannerContentWrapper = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	paddingRight: 'space.400',
	[media.above.sm]: {
		paddingRight: 'space.500',
	},
});

// nested wrappers due to no space.negative.500, maximum 400.
const layoutOffsetWrapper = xcss({
	marginBottom: 'space.0',
	marginLeft: 'space.negative.400',
	marginRight: 'space.negative.400',
});

// nested wrappers due to no space.negative.500, maximum 400.
const objectViewOffsetWrapper = xcss({
	marginTop: 'space.negative.200',
	marginLeft: 'space.negative.400',
	marginRight: 'space.negative.400',
	marginBottom: 'space.300',
});

const layoutOffsetInner = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.negative.100',
});
