import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	pagination: {
		id: 'servicedesk-insight-object-schema-page.common.total-objects-pagination.pagination',
		defaultMessage:
			'{startIndex, number}-{toIndex, number} of {numObjects, plural, one {# Object} other {# Objects}}',
		description:
			'Label indicating the objects pagination current range of the total number of objects with plural suffix',
	},
	paginationSimplified: {
		id: 'servicedesk-insight-object-schema-page.common.total-objects-pagination.pagination-simplified',
		defaultMessage: '{startIndex, number}-{toIndex, number} of {numObjects, number}',
		description:
			'Label indicating the objects pagination current range of the total number of objects',
	},
	cappedPagination: {
		id: 'servicedesk-insight-object-schema-page.common.total-objects-pagination.capped-pagination',
		defaultMessage:
			'{startIndex, number}-{toIndex, number} of {cappedNumbObjects} {numObjects, plural, one {Object} other {Objects}}',
		description:
			'Label indicating the objects pagination current range of the total number of capped objects (e.g 1000+) with plural suffix',
	},
	cappedPaginationSimplified: {
		id: 'servicedesk-insight-object-schema-page.common.total-objects-pagination.capped-pagination-simplified',
		defaultMessage: '{startIndex, number}-{toIndex, number} of {cappedNumbObjects}',
		description:
			'Label indicating the objects pagination current range of the total number of capped objects (e.g 1000+)',
	},
	objectsCount: {
		id: 'servicedesk-insight-object-schema-page.common.total-objects-pagination.objects-count',
		defaultMessage: '{numObjects, plural, =0 {No Objects} one {# Object} other {# Objects}}',
		description: 'Label showing the number of objects listed, e.g. "1 Object" or "25 Objects"',
	},
	errorTooltipText: {
		id: 'servicedesk-insight-object-schema-page.common.total-objects-pagination.error-tooltip-text',
		defaultMessage:
			'We couldn’t load the total number of objects. Please check your network and try again.',
		description:
			'Inform a user that there was a problem when trying to load the total object count for their search and ask that they try again',
	},
	errorIconLabel: {
		id: 'servicedesk-insight-object-schema-page.common.total-objects-pagination.error-icon-label',
		defaultMessage: 'Error loading total object count',
		description:
			'Inform users of an error when trying to load the total object count, when they are using a screen reader',
	},
});
