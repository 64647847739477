import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import type { CmdbIcon } from '@atlassian/jira-servicedesk-insight-shared-types';

type GetUniqueIconUrlOptions = {
	size: '16' | '48';
};

/**
 * Gets the URL for the passed in icon. Includes functionality to try and
 * prevent browsers displaying out of date icons.
 *
 * Context: When updating the icon for an object type, the updated icon will
 * have the same URL as the previous icon. This means that browsers may cache
 * this asset, leaving the user seeing an out of date icon until the browser
 * cache invalidates.
 */
export const getUniqueIconUrl = (
	icon: CmdbIcon,
	options: GetUniqueIconUrlOptions = { size: '48' },
): string => {
	const sizeUrl = options.size === '48' ? icon.url48 : icon.url16;

	try {
		const url = new URL(sizeUrl);

		// Give each icon a unique URL by appending the iconId query param
		url.searchParams.append('iconId', icon.id);

		return url.toString();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'getUniqueIconUrl',
				packageName: 'jiraServicedeskInsightIcon',
				teamName: 'falcons',
			},
		});

		return sizeUrl;
	}
};
