import React from 'react';
import { CustomItem, type CustomItemProps } from '@atlaskit/side-navigation';
import { Link } from '@atlassian/jira-router';

// We can't use LinkItem from @atlaskit/side-navigation because that renders a regular link, resulting in a full
// page load when we should be getting an SPA transition with react-resource-router.
export const SidebarCustomLinkItem = ({
	children,
	...props
}: {
	to: string;
} & CustomItemProps) => (
	<CustomItem {...props} component={Link}>
		{children}
	</CustomItem>
);
