import { useRef, useEffect } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers';
import { useQueryParam } from '@atlassian/jira-router';
import {
	type ObjectViewMode,
	SCHEMA_PAGE_QUERY_PARAMS,
} from '@atlassian/jira-servicedesk-insight-urls';

export const cmdbSchemaLocalStorage = createLocalStorageProvider('jsm-cmdb-schema');
export const storeLocalObjectViewMode = (newObjectViewMode: ObjectViewMode) => {
	cmdbSchemaLocalStorage.set('viewMode', newObjectViewMode);
};
export const readLocalObjectViewMode = (): ObjectViewMode =>
	cmdbSchemaLocalStorage.get('viewMode') === 'list' ? 'list' : 'details';

export const useViewModeLocalStorageSync = () => {
	const [viewModeParam, setViewModeParam] = useQueryParam(SCHEMA_PAGE_QUERY_PARAMS.objectViewMode);

	const viewModeInitialised = useRef(false);
	useEffect(() => {
		const effectiveViewMode: ObjectViewMode = viewModeParam === 'list' ? 'list' : 'details';

		if (!viewModeInitialised.current) {
			viewModeInitialised.current = true;
			const localObjectViewMode = readLocalObjectViewMode();

			// If the view query param is set, e.g. ?view=list, then that takes priority.
			// If not set, then we read the preference from local storage, which falls back to 'details' if nothing is set.
			if (!viewModeParam && effectiveViewMode !== localObjectViewMode) {
				// NB: must use 'replace' to retain proper history
				setViewModeParam(localObjectViewMode, 'replace');
			}
		} else {
			// Sync objectViewMode with local storage on changes.
			storeLocalObjectViewMode(effectiveViewMode);
		}
	}, [viewModeParam, setViewModeParam]);
};
