import { fg } from '@atlassian/jira-feature-gating';
import type { ActionApi } from '../../common/types';
import { fetchSchemaObjectTypes } from '../fetch-schema-object-types';

export const openSchemaGraph =
	(): ActionApi =>
	async ({ setState }) => {
		if (fg('fix_sentry_undefined_currentuserrole_error')) {
			fetchSchemaObjectTypes();
		}
		setState({ isSchemaGraphOpen: true });
	};

export const closeSchemaGraph =
	(): ActionApi =>
	async ({ setState }) => {
		setState({ isSchemaGraphOpen: false });
	};
