import React, { type ReactNode } from 'react';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { ServiceDeskAnalyticsContext } from '@atlassian/jira-servicedesk-analytics/src';
import {
	toPackageName,
	type AppName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors';
import AccessCheck from '../access-check';

type Props = {
	appName: AppName;
	children: ReactNode;
};

const ServiceDeskAppBase = ({ appName, children }: Props) => (
	<AuthenticationCheck>
		<AccessCheck>
			<SubProductUpdater subProduct="serviceDesk" />
			<ServiceDeskAnalyticsContext>
				<ContextualAnalyticsData sourceName={toPackageName(appName)} sourceType={SCREEN}>
					{children}
				</ContextualAnalyticsData>
			</ServiceDeskAnalyticsContext>
		</AccessCheck>
	</AuthenticationCheck>
);

export default ServiceDeskAppBase;
