/** @jsx jsx */
import React from 'react';
import { styled, css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../common/ui';
import objectViewAttributesSVG from './assets/insight-object-view-attributes-skeleton.tpl.svg';
import objectViewHeaderSVG from './assets/insight-object-view-header-skeleton.tpl.svg';
import objectViewLinkedObjectsSVG from './assets/insight-object-view-linked-objects-skeleton.tpl.svg';
import objectViewPanelSVG from './assets/insight-object-view-panel-skeleton.tpl.svg';
import objectViewSVG from './assets/insight-object-view-skeleton.tpl.svg';

const skeletonCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
	},
});

export const InsightObjectViewSkeleton = () => (
	<ObjectViewWrapper>
		<AppSkeletonImage src={objectViewSVG} css={skeletonCssStyles} />
	</ObjectViewWrapper>
);

export const InsightObjectViewHeaderSkeleton = () => (
	<HeaderWrapper>
		<AppSkeletonImage src={objectViewHeaderSVG} css={skeletonCssStyles} />
	</HeaderWrapper>
);

export const InsightObjectViewAttributesSkeleton = () => (
	<AttributesWrapper>
		<AppSkeletonImage src={objectViewAttributesSVG} css={skeletonCssStyles} />
	</AttributesWrapper>
);

export const InsightObjectViewLinkedObjectsSkeleton = () => (
	<LinkedObjectsWrapper>
		<AppSkeletonImage src={objectViewLinkedObjectsSVG} css={skeletonCssStyles} />
	</LinkedObjectsWrapper>
);

export const InsightObjectViewPanelSkeleton = () => (
	<PanelWrapper>
		<AppSkeletonImage src={objectViewPanelSVG} css={skeletonCssStyles} />
	</PanelWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ObjectViewWrapper = styled.div({
	maxHeight: '613px',
	overflowY: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div({
	maxHeight: '113px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AttributesWrapper = styled.div({
	maxHeight: '500px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkedObjectsWrapper = styled.div({
	maxHeight: '135px',
	overflowY: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelWrapper = styled.div({
	maxHeight: '75px',
	overflowY: 'hidden',
});
