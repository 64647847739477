import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	previous: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-details.pagination.previous',
		defaultMessage: 'Previous page',
		description: 'Label for a button used to go to the previous objects list page',
	},
	next: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-details.pagination.next',
		defaultMessage: 'Next page',
		description: 'Label for a button used to go to the next objects list page',
	},
	pagination: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-details.pagination.pagination',
		defaultMessage: '{startIndex}-{toIndex} of {numObjects}',
		description: 'Label indicating the pagination current range of objects and their total numbers',
	},
	objectsCount: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-details.pagination.objects-count',
		defaultMessage: '{numObjects, plural, one {# object} other {# objects}}',
		description: 'Label to use in place of the pagination when there is only a single page',
	},
	paginationErrorTitle: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-details.pagination.pagination-error-title',
		defaultMessage: 'We were unable to load your objects',
		description: 'Error title when failing to load list of groups',
	},
	paginationErrorDescription: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-content.object-details.pagination.pagination-error-description',
		defaultMessage: 'Please check your network and try again.',
		description: 'Error message when failing to load list of groups',
	},
});
