import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fireJsonOnlyErrorAnalytics } from '@atlassian/jira-servicedesk-common/src/utils/analytics';
import {
	OPERATION_TYPE_BULK_EXPORT,
	STATUS_QUEUED,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import { ACTION_BULK_EXPORT } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task';
import { mergeTasks } from '../../../common/utils/merge-tasks';
import { createAsyncBulkExportTask } from '../../services/create-async-bulk-export-task';
import type { AsyncExportObjectParams } from '../../services/create-async-bulk-export-task/types';
import type { CmdbAsyncActionStoreAction } from '../../types';
import { showErrorFlag } from '../show-error-flag';
import { showSuccessFlag } from '../show-success-flag';
import { startTaskPolling } from '../start-task-polling';

type Props = {
	objectCount: string;
} & AsyncExportObjectParams;

export const submitAsyncObjectExportTask =
	({ objectCount, ...body }: Props): CmdbAsyncActionStoreAction =>
	async ({ getState, setState, dispatch }, { workspaceId, createAnalyticsEvent }) => {
		const {
			tasks,
			bulkExport: { isSubmitting },
		} = getState();

		if (isSubmitting) {
			return;
		}

		setState({ bulkExport: { isSubmitting: true } });

		try {
			const { taskId } = await createAsyncBulkExportTask(workspaceId, { ...body });

			setState({
				tasks: mergeTasks(tasks, [
					{
						identifier: taskId,
						operationType: OPERATION_TYPE_BULK_EXPORT,
						status: STATUS_QUEUED,
					},
				]),
				bulkExport: { isSubmitting: false },
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'submitAsyncBulkExportTask succeeded');
			dispatch(
				showSuccessFlag({
					id: taskId,
					objectCount,
					action: ACTION_BULK_EXPORT,
				}),
			);

			dispatch(startTaskPolling());
		} catch (error) {
			fireJsonOnlyErrorAnalytics({
				error,
				meta: {
					id: 'submitAsyncBulkExportTask',
					packageName: 'jiraServicedeskCmdbAsyncActions',
					teamName: 'falcons',
				},
			});

			setState({ bulkExport: { isSubmitting: false } });

			dispatch(showErrorFlag({ action: ACTION_BULK_EXPORT }));

			throw error;
		}
	};
