import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	objects: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-tabs.objects',
		defaultMessage: 'Objects',
		description: 'Label for a tab that shows objects when clicked',
	},
	attributes: {
		id: 'servicedesk-insight-object-schema-page.page-content.loaded.page-tabs.attributes',
		defaultMessage: 'Attributes',
		description: 'Label for a tab that shows attributes when clicked',
	},
});
