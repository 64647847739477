import React, { type ReactNode } from 'react';
import { EMPTY_VALIDATED_IQL } from '@atlassian/jira-servicedesk-insight-iql-validation';
import {
	createContainer,
	createStore,
	createHook,
	createStateHook,
} from '@atlassian/react-sweet-state';
import type { State, ContainerProps, ActionApi, SearchRequest } from '../../common/types';
import { checkIsSupportedSearchScope } from '../../common/utils';
import { getLocalLastSearchQuery, getLocalSearchMode } from '../../common/utils/storage';
import { getLastSearch, getPagination } from '../../services/selectors';
import { actions, type Actions } from './actions';

const ASYNC_LOADING_STATE = { type: 'loading' } as const;

export const initialState: State = {
	lastSearch: ASYNC_LOADING_STATE,
	pendingSearch: null,
	searchMode: 'basic',
	currentSearchScope: null,
	selectedColumns: [],
	filtersUpdated: false,
};

const Store = createStore({
	initialState,
	actions,
});

export const useObjectSearchStoreState = createHook(Store, {
	selector: (state) => state,
});

const onInit = (): ActionApi => async (api, containerProps) => {
	const { setState, dispatch } = api;
	const { initialSelectedObjectId, searchScope } = containerProps;
	const { objectTypeId } = checkIsSupportedSearchScope(searchScope);
	const localLastSearchQuery = getLocalLastSearchQuery(objectTypeId);
	const newSearchQuery = localLastSearchQuery ?? EMPTY_VALIDATED_IQL;

	const localSearchMode = getLocalSearchMode(objectTypeId);
	if (localSearchMode !== null && localSearchMode !== undefined) {
		setState({ searchMode: localSearchMode });
	}

	const INITIAL_SEARCH_REQUEST: SearchRequest = {
		query: {
			type: 'qlQuery',
			qlQuery: newSearchQuery,
		},
		page: {
			type: 'page-number',
			pageNumber: 1,
		},
	};
	const initialSearchRequest: SearchRequest =
		initialSelectedObjectId !== undefined && initialSelectedObjectId !== null
			? {
					...INITIAL_SEARCH_REQUEST,
					page: {
						type: 'page-containing-object',
						objectId: initialSelectedObjectId,
					},
				}
			: INITIAL_SEARCH_REQUEST;
	setState({ currentSearchScope: searchScope });

	await dispatch(
		actions.executeSearch({ request: initialSearchRequest, resetUncappedNumObjects: true }),
	);
};

export const InsightObjectSearchStoreImpl = createContainer<State, Actions, ContainerProps>(Store, {
	onInit,
});
export const InsightObjectSearchStore = ({
	children,
	...props
}: {
	children: ReactNode;
} & ContainerProps) => {
	const { workspaceId, searchScope } = props;
	let storeScope;
	if (searchScope.type === 'schema-object-type') {
		storeScope = `${workspaceId}/${searchScope.objectSchemaId}/${searchScope.objectTypeId}`;
	} else {
		storeScope = workspaceId;
	}

	return (
		<InsightObjectSearchStoreImpl {...props} scope={storeScope}>
			{children}
		</InsightObjectSearchStoreImpl>
	);
};

export const useObjectSearchPagination = createHook(Store, {
	selector: getPagination,
});

export const useLastSearch = createStateHook(Store, {
	selector: getLastSearch,
});
