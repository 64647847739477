import { LOCAL_STORAGE, DISMISSED_FOR_THRESHOLD_TYPE_KEY } from '../../../constants';
import { determineThreshold } from '../../../index';
import type { ActionApi } from '../../../types';

export const dismissMessage =
	(): ActionApi =>
	async ({ setState, getState }, { objectCount, objectLimit }) => {
		const threshold = determineThreshold({ objectCount, objectLimit });
		const isDismissed = LOCAL_STORAGE.get(DISMISSED_FOR_THRESHOLD_TYPE_KEY) === threshold;
		if (!isDismissed) {
			LOCAL_STORAGE.set(DISMISSED_FOR_THRESHOLD_TYPE_KEY, threshold);
			setState({
				...getState(),
				isDismissed: true,
			});
		}
	};
