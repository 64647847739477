import { stringify as qsStringify } from 'query-string';
import type {
	SchemaId,
	WorkspaceId,
	CmdbObjectTypeId,
	ImportSourceId,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import type {
	ImportSourceObjectTypeAttributeByIdRequest,
	ImportSourceRequest,
} from '../types/import-source';
import { getCmdbRestBaseUrl } from './index';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource
 */
export const getCmdbImportSourceUrl = (
	workspaceId: WorkspaceId,
	queryParams?: ImportSourceRequest,
) =>
	`${getCmdbRestBaseUrl(workspaceId)}/importsource${
		queryParams ? `?${qsStringify(queryParams)}` : ''
	}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/${importSourceId}
 */
export const getCmdbImportSourceByIdUrl = (workspaceId: WorkspaceId, importSourceId: string) =>
	`${getCmdbImportSourceUrl(workspaceId)}/${importSourceId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/${importSourceId}/token
 */
export const getCmdbImportSourceByIdTokenUrl = (workspaceId: WorkspaceId, importSourceId: string) =>
	`${getCmdbImportSourceByIdUrl(workspaceId, importSourceId)}/token`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/async/create/progress/${importSourceId}
 */
export const getCmdbImportSourceAsyncCreateProgressByIdUrl = (
	workspaceId: WorkspaceId,
	importSourceId: string,
) => `${getCmdbImportSourceUrl(workspaceId)}/async/create/progress/${importSourceId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/ot
 */
export const getCmdbImportSourceObjectTypeUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbImportSourceUrl(workspaceId)}/ot`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/ot/${importSourceOTId}
 */
export const getCmdbImportSourceObjectTypeByIdUrl = (
	workspaceId: WorkspaceId,
	importSourceOTId: CmdbObjectTypeId,
) => `${getCmdbImportSourceObjectTypeUrl(workspaceId)}/${importSourceOTId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/ot/${importSourceOTId}/disable
 */
export const getCmdbImportSourceObjectTypeByIdDisableUrl = (
	workspaceId: WorkspaceId,
	importSourceOTId: CmdbObjectTypeId,
) => `${getCmdbImportSourceObjectTypeByIdUrl(workspaceId, importSourceOTId)}/disable`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/ot/${importSourceOTId}/enable
 */
export const getCmdbImportSourceObjectTypeByIdEnableUrl = (
	workspaceId: WorkspaceId,
	importSourceOTId: CmdbObjectTypeId,
) => `${getCmdbImportSourceObjectTypeByIdUrl(workspaceId, importSourceOTId)}/enable`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/ot/${importSourceOTId}/imports
 */
export const getCmdbImportHistoryObjectTypeByIdUrl = (
	workspaceId: WorkspaceId,
	importSourceOTId: CmdbObjectTypeId,
) => `${getCmdbImportSourceObjectTypeByIdUrl(workspaceId, importSourceOTId)}/imports`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/import/inflightorlatestresults/ot/${objectTypeId}
 */
export const getCmdbImportHistoryDataObjectTypeByIdUrl = (
	workspaceId: WorkspaceId,
	objectTypeId: CmdbObjectTypeId,
	importIds: string[],
) =>
	`${getCmdbRestBaseUrl(
		workspaceId,
	)}/import/inflightorlatestresults/ot/${objectTypeId}?${qsStringify({ isids: importIds })}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/otattr
 */
export const getCmdbImportSourceObjectTypeAttributeUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbImportSourceUrl(workspaceId)}/otattr`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/otattr/${otAttrId}
 */
export const getCmdbImportSourceObjectTypeAttributeByIdUrl = (
	workspaceId: WorkspaceId,
	otAttrId: string,
	queryParams?: ImportSourceObjectTypeAttributeByIdRequest,
) =>
	`${getCmdbImportSourceObjectTypeAttributeUrl(workspaceId)}/${otAttrId}${
		queryParams ? `?${qsStringify(queryParams)}` : ''
	}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/objectschema/${objectSchemaId}
 */
export const getCmdbImportSourceObjectSchemaByIdUrl = (
	workspaceId: WorkspaceId,
	objectSchemaId: SchemaId,
) => `${getCmdbImportSourceUrl(workspaceId)}/objectschema/${objectSchemaId}`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/objectschema/${objectSchemaId}/streaming
 */
export const getCmdbImportSourceObjectSchemaByIdStreamingUrl = (
	workspaceId: WorkspaceId,
	objectSchemaId: SchemaId,
) => `${getCmdbImportSourceObjectSchemaByIdUrl(workspaceId, objectSchemaId)}/streaming`;
/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/importsource/${importSourceId}/status
 */
export const getCmdbImportStatusByImportSourceIdUrl = (
	workspaceId: WorkspaceId,
	importSourceId: ImportSourceId,
) => `${getCmdbImportSourceUrl(workspaceId)}/${importSourceId}/status`;
