import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	submitSuccessFlagTitle: {
		id: 'servicedesk-cmdb-async-actions.common.submit-success-flag-title',
		defaultMessage:
			'Bulk action started: {action} {objectCount} {objectCount, plural, one {object} other {objects}}',
		description: 'Success flag title after creating bulk async task successfully',
	},
	submitSuccessFlagDescription: {
		id: 'servicedesk-cmdb-async-actions.common.submit-success-flag-description',
		defaultMessage:
			'This bulk action may take a while. You will receive a notification once the work is complete.',
		description: 'Success flag description after creating bulk async task successfully',
	},
	submitErrorFlagTitle: {
		id: 'servicedesk-cmdb-async-actions.common.submit-error-flag-title',
		defaultMessage: 'Bulk {action} failed',
		description: 'Error flag title after creating bulk async task failure',
	},
	submitErrorFlagDescription: {
		id: 'servicedesk-cmdb-async-actions.common.submit-error-flag-description',
		defaultMessage: 'Please check your network and try again',
		description: 'Error flag description after creating bulk async task failure',
	},
});
