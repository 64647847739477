/** @jsx jsx */
import React from 'react';
import { styled, css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AppSkeletonImage } from '../../common/ui';
import modalFieldsSVG from './assets/insight-object-modal-fields-skeleton.tpl.svg';
import attributesSVG from './assets/insight-object-schema-attributes-skeleton.tpl.svg';
import sideBarListSVG from './assets/insight-object-schema-details-sidebar-list-skeleton.tpl.svg';
import detailsSVG from './assets/insight-object-schema-details-skeleton.tpl.svg';
import headerSVG from './assets/insight-object-schema-header-skeleton.tpl.svg';
import listBodySVG from './assets/insight-object-schema-list-body-skeleton.tpl.svg';
import listSVG from './assets/insight-object-schema-list-skeleton.tpl.svg';

const skeletonCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
	},
});
const headerWrapperCssStyles = css({
	maxHeight: '130px',
});
const modalFieldWrapperCssStyles = css({
	maxHeight: token('space.800', '64px'),
	marginTop: token('space.100', '8px'),
	overflow: 'hidden',
});

export const InsightObjectSchemaHeaderSkeleton = () => (
	<div css={headerWrapperCssStyles}>
		<AppSkeletonImage src={headerSVG} css={skeletonCssStyles} />
	</div>
);
export const InsightObjectSchemaDetailsSkeleton = () => (
	<AppSkeletonImage src={detailsSVG} css={skeletonCssStyles} />
);

export const InsightObjectSchemaListSkeleton = () => (
	<AppSkeletonImage src={listSVG} css={skeletonCssStyles} />
);

export const InsightObjectSchemaAttributesSkeleton = () => (
	<AppSkeletonImage src={attributesSVG} css={skeletonCssStyles} />
);
export const InsightObjectModalFieldSkeleton = () => (
	<div css={modalFieldWrapperCssStyles}>
		<AppSkeletonImage src={modalFieldsSVG} css={skeletonCssStyles} />
	</div>
);

export const InsightObjectSchemaListBodyOnlySkeleton = () => (
	<OuterTransformWrapper>
		<AppSkeletonImage src={listBodySVG} css={skeletonCssStyles} />
	</OuterTransformWrapper>
);

export const InsightObjectSchemaCardSkeleton = () => (
	<SideBarOnlyCss>
		<AppSkeletonImage src={sideBarListSVG} css={skeletonCssStyles} />
	</SideBarOnlyCss>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OuterTransformWrapper = styled.div({
	height: '100%',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SideBarOnlyCss = styled(OuterTransformWrapper)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		width: '3200px',
	},
});
