import type { ActionApi } from '../../../../common/types';
import { executeSearch } from '../execute-search';

export const changePagination =
	(pageNumber: number): ActionApi =>
	async ({ getState, dispatch }) => {
		const { lastSearch } = getState();
		if (lastSearch.type !== 'success') {
			return;
		}

		await dispatch(
			executeSearch({
				request: {
					query: lastSearch.value.request.query,
					page: {
						type: 'page-number',
						pageNumber,
					},
				},
				isRefresh: true,
			}),
		);
	};
