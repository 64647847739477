import {
	createContainer,
	createHook,
	createStateHook,
	createStore,
} from '@atlassian/react-sweet-state';
import { actions, type Actions } from '../actions';
import type {
	ActionApi,
	ContainerProps,
	State,
	UiState,
	UiActions,
	UrlParams,
} from '../common/types';
import { matchPageDataState, getSchemaPageDataOrThrowError } from './selectors';
import { useUrlParamContext } from './url-params';
import { validateSelectedObjectTypeId } from './utils';

export { matchPageDataState };
export {
	canCreateObjectType,
	canCloneObjectType,
	canDeleteObjectType,
	canUpdateObjectType,
	isAbstractObjectType,
	isObjectTypeInherited,
	isParentObjectTypeInherited,
	hasParentObjectType,
	canViewObjectTypeSettings,
	canViewSchemaSettings,
	getOrderedChildObjectTypes,
	objectTypesToArray,
	canCreateObject,
	canDeleteObject,
	canEditObject,
	canAccessAttributes,
	getCmdbAnalyticAttributes,
} from './selectors';

export { useInsightObjectSchemaQueryParams, UrlParamContextProvider } from './url-params';

export const initialState: State = {
	pageDataState: { type: 'loading' },
	hasInitialized: false,
	objectTypeModalState: { type: 'idle' },
	isCreateObjectOpen: false,
	isSchemaGraphOpen: false,
	isCreateAnotherChecked: false,
	lastCreatedObjectTypeId: null,
	currentOTMeatballMenu: null,
	bulkEditModalsState: [],
};

const Store = createStore({
	initialState,
	actions,
});

/**
 * Selector for the UI state with pull-through validation of the selected object type ID, which has the side effect of
 * updating the object type ID query param if it is invalid.
 * Ideally this would happen eagerly on each store state change (rather than as a side effect of a selector), but there
 * isn't a way to hook into state changes in sweet state for a single store.
 */
const useUiStateWithQueryParamValidation = createHook(Store, {
	selector: (state, urlParams: UrlParams) => {
		const { pageDataState } = state;
		const {
			objectSchemaId,
			objectTypeId,
			objectId,
			setObjectTypeId,
			schemaViewMode,
			objectViewMode,
		} = urlParams;
		const validObjectTypeId = matchPageDataState(pageDataState, {
			error: () => null,
			loading: () => null,
			success: ({ objectTypesById }) => {
				const selectionValidation = validateSelectedObjectTypeId(objectTypeId, objectTypesById);
				if (selectionValidation.type === 'valid') {
					return objectTypeId;
				}
				// Uses 'replace' because the current selection is invalid and shouldn't be left in browser history.
				setObjectTypeId(selectionValidation.newSelectedId, 'replace');
				return selectionValidation.newSelectedId;
			},
		});

		return {
			objectId,
			objectSchemaId,
			selectedObjectTypeId: validObjectTypeId,
			schemaViewMode,
			objectViewMode,
		};
	},
});
export const useSchemaPageUiState = (): [UiState, UiActions] => {
	const queryParams = useUrlParamContext();
	const [uiState] = useUiStateWithQueryParamValidation(queryParams);
	const { setSchemaViewMode, setObjectViewMode, setObjectId } = queryParams;
	return [uiState, { setSchemaViewMode, setObjectViewMode, setObjectId }];
};

export const useInsightObjectSchemaPageData = createHook(Store, {
	selector: (state) => state,
});

export const useIsRestrictedSchema = createStateHook(Store, {
	selector: (state) =>
		state.pageDataState.type === 'success' && state.pageDataState.objectSchema.isRestricted,
});

// Should only be called from under the success branch of matchPageDataState
export const useLoadedSchemaPageData = createHook(Store, {
	selector: getSchemaPageDataOrThrowError,
});

export const useOTMeatballsMenuState = createHook(Store, {
	selector: (state) => state.currentOTMeatballMenu,
});

export const autoFetchSchemaPageData =
	(): ActionApi =>
	async ({ dispatch, getState, setState }) => {
		// hasInitialized is required so that multiple global copies of this container don't duplicate data fetching.
		const { hasInitialized } = getState();
		if (!hasInitialized) {
			setState({ hasInitialized: true });

			await dispatch(actions.fetchSchemaPageData());
		}
	};

export const StoreContainer = createContainer<State, Actions, ContainerProps>(Store, {
	onInit: autoFetchSchemaPageData,
});
