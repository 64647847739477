import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ValidatedIql } from '@atlassian/jira-servicedesk-insight-iql-validation';
import type {
	AttributeType,
	DefaultAttributeType,
	InsightObjectAttribute,
	ObjectFilterEntry,
} from '@atlassian/jira-servicedesk-insight-object-types';
import type {
	CmdbObjectId,
	CmdbObjectTypeId,
	ObjectTypeAttributeId,
	SchemaId,
	SortDirection,
	WorkspaceId,
	CmdbAvatar,
} from '@atlassian/jira-servicedesk-insight-shared-types';
import type { Action } from '@atlassian/react-sweet-state';
import type { AttributeFilter, ValueMatch } from './filters';

export type { AttributeFilter };
export type SearchMode = 'basic' | 'advanced';

export type SearchResultObject = {
	id: CmdbObjectId;
	label: string;
	objectKey: string;
	avatar: CmdbAvatar;
	attributes: InsightObjectAttribute[];
	// Due to inheritance this objectTypeID might be different to the one passed in to the object-search-store
	objectTypeId: CmdbObjectTypeId;
};
export type SearchResultObjectTypeAttribute = {
	id: ObjectTypeAttributeId;
	attributeType: AttributeType;
	// Defined if attributeType is 'default'
	defaultType?: DefaultAttributeType['defaultType'];
	name: string;
	isLabel: boolean;
	isSystem: boolean;
	isSummable: boolean;
	maximumCardinality?: number;
};

export type AttributeFiltersByName = {
	[attributeName: string]: {
		attributeFilter: AttributeFilter;
		// Note: this should be dropped when the back-end supports querying by attribute name rather than ID, to support
		// querying across attributes from different object types.
		objectTypeAttributeId: ObjectTypeAttributeId;
	};
};

export type OrderBy = {
	attributeName: string;
	// Ideally we would only use attributeName but the back-end currently requires this for ordering
	objectTypeAttributeId: ObjectTypeAttributeId;
	direction: SortDirection;
};

export type TransformedSearchResponse = Omit<SearchResult, 'uncappedNumObjects'>;

export type SearchResult = {
	objects: SearchResultObject[];
	attributes: SearchResultObjectTypeAttribute[];
	qlQuery: ValidatedIql;
	// Indicates if the qlQuery can be represented as a filter. False if the qlQuery is too complex. Should not be
	// relevant if a search was done using filters (since all filters can be represented as AQL).
	// NB: the response has a `conversionPossible` property which kind of does what we want, but we don't use that
	// because it is always `false` if the list of result objects is empty.
	canConvertQlQueryToFilter: boolean;
	// Will be empty if canConvertQlQueryToFilter is false.
	filters: AttributeFiltersByName;
	pageNumber: number;
	numObjects: number;
	numPages: number;
	uncappedNumObjects: UncappedNumObjects | undefined;
	hasMoreResults: boolean;
	orderBy: OrderBy | null;
	startIndex: number;
	toIndex: number;
	objectTypeIsInherited: boolean;
	objectTypeId: string;
	objectTypes: SearchResultObjectType | null;
};

export type UncappedNumObjects = {
	totalCount: number | undefined;
	loading: boolean;
	error: Error | undefined;
};

export type SearchResultObjectType = {
	entries: ObjectFilterEntry[];
	total: number;
	showing: number;
	filterByObjectType: boolean;
	selectedValues: ValueMatch<string>[];
};

export type AsyncSuccess<T> = {
	type: 'success';
	value: T;
};
type Async<T> =
	| {
			type: 'loading';
	  }
	| {
			type: 'error';
			error: Error;
	  }
	| AsyncSuccess<T>;

type AsyncHandlers<T, Result> = {
	loading: () => Result;
	error: (error: Error) => Result;
	success: (value: T) => Result;
};
export const matchAsync = <T, Result>(
	asyncState: Async<T>,
	handlers: AsyncHandlers<T, Result>,
): Result => {
	switch (asyncState.type) {
		case 'loading':
			return handlers.loading();
		case 'error':
			return handlers.error(asyncState.error);
		case 'success':
			return handlers.success(asyncState.value);
		default:
			// This can never happen...
			// @ts-expect-error Property 'type' does not exist on type 'never'.
			throw new Error(`Unexpected async type: ${asyncState.type}`);
	}
};

export type QlQuerySearch = {
	type: 'qlQuery';
	qlQuery: ValidatedIql;
};
export type FilterSearchQuery = {
	type: 'filter';
	filters: AttributeFiltersByName;
	objectTypes: ValueMatch<string>[];
	orderBy: OrderBy | null;
};

export type SearchQuery = FilterSearchQuery | QlQuerySearch;
export type SearchPage =
	| {
			type: 'page-number';
			pageNumber: number;
	  }
	| {
			type: 'page-containing-object';
			objectId: CmdbObjectId;
	  };
export type SearchRequest = {
	query: SearchQuery;
	page: SearchPage;
};

export type SearchStateValue = {
	// The request that produced the given result. Used for both searching, and then for making edits to the iql / filters / page etc.
	request: SearchRequest;
	result: SearchResult;
};
export type SearchState = Async<SearchStateValue>;
export const matchSearchState = <Result,>(
	searchState: SearchState,
	handlers: AsyncHandlers<SearchStateValue, Result>,
): Result => matchAsync(searchState, handlers);

/**
 * The scope of the IQL query to execute - 'global' to search across all objects, 'object-type' to search for objects
 * within a specific object type. This controls things like:
 * - Which columns (attributes) are available in the column picker.
 * - The attributes available for filtering.
 * - The IQL queries that get generated (e.g. by prefixing with object type).
 */
export type SearchScope =
	| {
			type: 'global';
	  }
	| {
			type: 'schema-object-type';
			objectSchemaId: SchemaId;
			objectTypeId: CmdbObjectTypeId;
			isObjectTypeInherited: boolean;
	  };

export type SearchScopeSchemaObject = Omit<
	Extract<SearchScope, { type: 'schema-object-type' }>,
	'type'
>;

export const matchSearchScope = <T,>(
	searchScope: SearchScope,
	handlers: {
		global: () => T;
		objectType: (objectSchemaId: SchemaId, objectTypeId: CmdbObjectTypeId) => T;
	},
): T => {
	switch (searchScope.type) {
		case 'global':
			return handlers.global();
		case 'schema-object-type': {
			const { objectSchemaId, objectTypeId } = searchScope;
			return handlers.objectType(objectSchemaId, objectTypeId);
		}
		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(searchScope as never);
			// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'.
			throw new Error(`Unexpected searchScope.type: ${searchScope.type}`);
	}
};

export type Column = {
	isSelected: boolean;
	target: ColumnTarget;
};

export type ColumnTarget = AvatarColumnTarget | AttributesTarget;

export type AvatarColumnTarget = { type: 'avatar' };
export type AttributesTarget = {
	id: ObjectTypeAttributeId;
	type: 'attribute';
	name: string;
	isLabel: boolean;
	isSummable: boolean;
	attributeType: AttributeType;
	// Defined if attributeType is 'default'
	defaultType?: DefaultAttributeType['defaultType'];
	maximumCardinality?: number;
};

export type SelectedColumns = ColumnTarget[];

export type State = {
	/**
	 * The initial request state, and following *successful* requests. I.e. the async type is used to indicate the
	 * result of the *initial* fetch and after that, this should only ever be updated with successful searches. This
	 * allows the UI to revert back to the previous state (with an error flag show) if a search fails, rather than
	 * completely discarding everything visible and showing an error instead of search results.
	 */
	lastSearch: SearchState;
	pendingSearch: {
		request: SearchRequest;
		// Indicates if the search is a "refresh", meaning that a more subtle loading indicator should be shown
		isRefresh: boolean;
	} | null;
	searchMode: SearchMode;
	currentSearchScope: SearchScope | null;
	selectedColumns: SelectedColumns;
	// used for determining whether to fire the updateObjectFilterExperience UFO experience
	filtersUpdated: boolean;
};

export type ContainerProps = {
	initialSelectedObjectId?: CmdbObjectId | null;
	workspaceId: WorkspaceId;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	searchScope: SearchScope;
};

export type ActionApi = Action<State, ContainerProps, Promise<undefined>>;

export type Pagination = {
	hasNextPage: boolean;
	hasPrevPage: boolean;
	numObjects: number;
	numPages: number;
	hasMoreResults: boolean;
	uncappedNumObjects: UncappedNumObjects | undefined;
	pageNumber: number;
	startIndex: number;
	toIndex: number;
};

export type ExecuteSearchProps = {
	request: SearchRequest;
	isRefresh?: boolean;
	resetUncappedNumObjects?: boolean;
};
